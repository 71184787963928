<template>
    <div class="flex items-start justify-between py-6">
        <div class="w-7 h-7 rounded-2xl overflow-hidden">
            <img src="@/assets/images/icon-profile.png" alt="profile" title="profile" class="w-full h-full"/>
        </div>
        <div>
            <h2 class="font-bold mb-1">{{ name }}</h2>
            <p class="text-sm text-neutral-500 flex mb-3">
                <img src="@/assets/images/icon-kakaologo.png" alt="kakao" title="kakao" class="mr-0.5"/>
                {{ tel }}
            </p>
            <p class="text-sm mb-1">
                [카카오 알림톡]
                <b>{{ alarm }}</b>
            </p>
            <p class="font-bold text-sm mb-3" :class="getStatusClass">
                {{ getStatusText }}
            </p>
            <ul class="flex space-x-2">
                <li class="p-2 rounded-lg bg-gray-200 cursor-pointer">
                    <img src="@/assets/images/icon-message.svg" alt="message" title="message" />
                </li>
                <li class="p-2 rounded-lg bg-gray-200 cursor-pointer">
                    <img src="@/assets/images/icon-docs.svg" alt="docs" title="docs" />
                </li>
            </ul>
        </div>
        <p class="text-xs text-gray-500">{{ time }}</p>
    </div>
</template>

<script>
export default {
    name    : "TalkAlert",
    props   : {
        name  : String,
        tel   : String,
        alarm : String,
        status: String,
        time  : String,
    },
    computed: {
        getStatusText() {
            if (this.status === 'completed') {
                return '발송 완료';
            } else if (this.status === 'pending') {
                return '발송대기';
            } else if (this.status === 'canceled') {
                return '발송취소';
            }
            return '';
        },
        getStatusClass() {
            return {
                'text-yellow-400': this.status === 'completed',
                'text-green-500' : this.status === 'pending',
                'text-red-500'   : this.status === 'canceled',
            }
        }
    },
}
</script>

<style scoped>

</style>