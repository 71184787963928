<template>
    <button class="fixed bottom-6 right-3.5 bg-blue-400 text-white rounded text-center z-20 py-3 px-5 flex items-center shadow-md">
        <img src="@/assets/images/btn-plus.svg" :alt="buttonText" :title="buttonText" class="mr-3" />
        <span class="mt-0.5">{{ buttonText }}</span>
    </button>
</template>

<script>
export default {
    name: "FixedButton",
    props: {
        buttonText: String,
    }
}
</script>

<style scoped>

</style>