import { getAxiosData, getConvertDate } from '@/assets/js/function';
/**
 * @description 회계 관련 vuex 모듈
 */
const accModule = {
    namespaced : true, 
    state : {
        year    : getConvertDate(new Date(), 'yyyy'),
        all     : {
            choice  : 'C', /** 첫화면 탭 구분 =>  C : 계약 / P : 결제 */
            start   : 0,
            amt     : getAmtInfo(),
            cnt     : {
                conCnt  : 0,
                payCnt  : 0
            },
            list    : {
                conDtList   : [],
                conList     : [],
                payDtList   : [],
                payList     : []
            }
        },
        month   : {
            amt     : getAmtInfo(),
            list    : []
        },
        week    : {
            amt         : getAmtInfo(),
            weekList    : [],
            list        : []
        },
        day     : {
            amt     : getAmtInfo(),
            list    : []
        }
    },
    mutations : {
        setChoice(state, val)
        {
            state.all.choice = val;
        },
        setAccAll(state, data)
        {
            state.all.amt = data['amt'];

            state.all.cnt['conCnt'] = Number(data['conList'].length);
            state.all.cnt['payCnt'] = Number(data['payList'].length);
            
            state.all.list.conDtList = data['conDtList'];
            state.all.list.conList   = data['conList'];
            state.all.list.payDtList = data['payDtList'];
            state.all.list.payList   = data['payList'];
        },
        setYear(state, year)
        {
            state.year = year;
        },
        setAccMonth(state, list)
        {
            state.month.list = list;

            const saleAmt = list.reduce((acc, val) => acc + Number(val.saleAmt), 0);
            const purcAmt = list.reduce((acc, val) => acc + Number(val.purcAmt), 0);

            const margin  = saleAmt === 0 ? 100 : Math.round(((saleAmt - purcAmt) / saleAmt) * 100);

            const amt = {
                saleAmt : saleAmt,
                purcAmt : purcAmt,
                margin  : margin
            };

            state.month.amt = amt;
        },
        setAccWeek(state, list)
        {
            state.week.weekList = list['week'];
            state.week.list     = list['list'];

            const saleAmt = list['list'].reduce((acc, val) => acc + Number(val.saleAmt), 0);
            const purcAmt = list['list'].reduce((acc, val) => acc + Number(val.purcAmt), 0);

            const margin  = saleAmt === 0 ? 100 : Math.round(((saleAmt - purcAmt) / saleAmt) * 100);

            const amt = {
                saleAmt : saleAmt,
                purcAmt : purcAmt,
                margin  : margin
            };

            state.week.amt = amt;
        },
        setAccDay(state, list)
        {
            state.day.list = list;

            const saleAmt = list.reduce((acc, val) => acc + Number(val.saleAmt), 0);
            const purcAmt = list.reduce((acc, val) => acc + Number(val.purcAmt), 0);

            const margin  = saleAmt === 0 ? 100 : Math.round(((saleAmt - purcAmt) / saleAmt) * 100);

            const amt = {
                saleAmt : saleAmt,
                purcAmt : purcAmt,
                margin  : margin
            };

            state.day.amt = amt;
        }
    },
    actions : {
        async getAccAll(context, params)
        {
            try
            {
                const res  = await getAxiosData('https://data.planorder.kr/acc/getAccAll', params);

                context.commit('setAccAll', res.data);
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.log(e);
                return { result: false, msg: 'error' };
            }
        },
        async getAccMonth(context, params)
        {
            try
            {
                const res  = await getAxiosData('https://data.planorder.kr/acc/getAccMonth', params);

                context.commit('setAccMonth', res.data);
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.log(e);
                return { result: false, msg: 'error' };
            }
        },
        async getAccWeek(context, params)
        {
            try
            {
                const res  = await getAxiosData('https://data.planorder.kr/acc/getAccWeek', params);

                console.log(res);

                context.commit('setAccWeek', res.data);
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.log(e);
                return { result: false, msg: 'error' };
            }
        },
        async getAccDay(context, params)
        {
            try
            {
                const res  = await getAxiosData('https://data.planorder.kr/acc/getAccDay', params);
                
                context.commit('setAccDay', res.data);
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.log(e);
                return { result: false, msg: 'error' };
            }
        }
    }
};

/**
 * @description 회계 전체 정보 가져오기
 */
function getAmtInfo()
{
    return {
        saleAmt : 0,
        purcAmt : 0,
        margin  : 0
    }
}

export default accModule;