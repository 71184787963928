<template>
    <div class="popup fixed overflow-hidden flex items-center bg-black/60 w-full h-screen top-0 left-0 right-0 mx-auto z-30" :class="{ active : popupActive }">
    </div>
    <div class="popup-box fixed duration-300 z-40 bottom-0 left-0 right-0 mx-auto rounded-t bg-white w-[96%] h-[98svh] pt-5" :class="{ active : popupActive }">
        <div class="flex justify-between mb-4 px-4">
            <h3 class="font-bold">{{ title }}</h3>
            <FontAwesomeIcon :icon="['fas', 'x']" class="text-zinc-500 cursor-pointer" @click="getClose"/>
        </div>
        <div class="section scroll-none pb-32 px-4" :class="{'height' : showFixedButton}">
            <slot></slot>
        </div>
        <div class="px-4 w-full pt-2 pb-3.5 shadow-custom flex space-x-2" v-if="showFixedButton">
            <BaseButton :buttonText="buttonGreyText" greyButton v-if="showGreyButton" />
            <BaseButton :buttonText="blueButtonText" blueButton v-if="showBlueButton" @click="getBlueBtnClick"/>
            <BaseButton :buttonText="cyanButtonText" cyanButton v-if="showCyanButton" @click="getCyanBtnClick"/>
        </div>
    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import BaseButton from "@/components/button/BaseButton.vue";

export default {
    name      : "ToggleBox",
    components: {BaseButton, FontAwesomeIcon},
    props     : {
        title           : String,
        blueButtonText  : String,
        cyanButtonText  : String,
        buttonGreyText  : String,
        showBlueButton  : Boolean,
        showGreyButton  : Boolean,
        showCyanButton  : Boolean,
        showFixedButton : Boolean,
        popupActive     : Boolean
    },
    methods   : {
        getClose() {
            this.$emit("getClose");
        },
        getBlueBtnClick() {
            this.$emit("getBlueBtnClick");
        },
        getCyanBtnClick() {
            this.$emit("getCyanBtnClick");
        }
    }
}
</script>

<style lang="scss" scoped>
.section {
    height: calc(100vh - 50px);
    overflow: scroll;

    &.height {
        height: calc(100vh - 136px);
    }
}

.popup {
    display: none;

    &.active {
        display: flex;
    }
}

.popup-box {
    transform: translateY(100%);

    &.active {
        transform: translateY(0);
    }
}
</style>