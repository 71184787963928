<template>
    <button class="text-xs py-1.5 px-2 rounded-sm font-bold text-xs" :class="[{'bg-brand text-white': blueButton}, {'bg-gray-200': grayButton}, {'bg-pink-300 text-white': pinkButton}]">
        {{ buttonText }}
    </button>
</template>

<script>
export default {
    name: "InlineButton",
    props: {
        buttonText: String,
        blueButton: Boolean,
        grayButton: Boolean,
        pinkButton: Boolean,
    }
}
</script>

<style scoped>

</style>