<template>
    <ModalBox title="제품관리" showFixedButton showButton buttonText="저장" @close="router.go(-1)" @blueBtnClick="getSysItemCheck">
        <div class="space-y-5">
            <div>
                <h4 class="font-bold text-sm mb-4">제품명<span class="text-red-500">*</span></h4>
                <InlineInput type="text" :value="info['itemNm']" label="제품 이름" disabled/>
            </div>
            <div>
                <h4 class="font-bold text-sm mb-4">색상<span class="text-red-500">*</span></h4>
                <div class="space-y-2.5">
                    <template v-for="(item, index) in info['icList']" :key="index">
                        <div class="flex justify-between">
                            <label for="" class="w-1/3 text-sm text-neutral-500">{{ index === 0 ? '색상이름' : '' }}</label>
                            <div class="flex justify-between w-2/3">
                                <BasicInput :value="item['icNm']" class="w-2/3" disabled/>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div>
                <h4 class="font-bold text-sm mb-4">옵션 설정<span class="text-red-500">*</span></h4>
                <div class="space-y-2.5">
                    <InlineDual label="기본 단위">
                        <BasicInput type="text" :value="info['size']" disabled class="w-1/2" />
                        <BasicInput type="text" :value="info['unit']" disabled class="w-1/2" />
                    </InlineDual>
                    <InlineUnit type="number" :value="info['baseHeight']" unit="cm" label="기본 높이" disabled />
                    <InlineUnit type="text" unit="cm" :value="info['pok']" label="원단 폭" v-if="info['unit'] === '폭'" disabled />
                    <InlineUnit type="text" unit="원" :value="info['purcAmt']" label="매입 가격" disabled />
                    <InlineUnit type="text" unit="원" id="saleAmt" :value="info['saleAmt']" label="판매 가격" @input=getInputValSet />
                </div>
            </div>
        </div>
    </ModalBox>
    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp" @getCancle="getCancle"/>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox.vue";
import InlineInput from "@/components/input/InlineInput.vue";
import InlineDual from "@/components/input/InlineDual.vue";
import BasicInput from "@/components/input/BasicInput.vue";
import InlineUnit from "@/components/input/InlineUnit.vue";
import AlertBox from "@/components/modal/AlertBox.vue";

import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import { getAxiosData } from "@/assets/js/function";

const store  = useStore();
const route  = useRoute();
const router = useRouter();

const info   = computed(() => store.state.factory.sys.itemInfo);

const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const resultYn = ref('N');

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
}

const getComp = () => {
    alertData.value['view'] = false;
    if(resultYn.value === 'Y')
    {
        getSysItemResult();
    }
    else
    {
        document.getElementById('saleAmt').focus();
    }
}

const getCancle = () => {
    alertData.value['view'] = false;
}

const getInputValSet = () => {
    info.value['saleAmt'] = event.target.value;
}

const getSysItemCheck = () => {
    if(info.value['saleAmt'] === '')
    {
        getAlertDataSet(true, '확인', '판매 가격을 입력해주세요.', false);
        return;
    }

    getAlertDataSet(true, '저장', '저장하시겠습니까?', true);
    resultYn.value = 'Y';
}

const getSysItemResult = async () => {
    try
    {
        const params = {
            ceCd    : store.state.login.ceCd,
            fcCd    : route.query.cd,
            itemCd  : route.query.itemCd,
            saleAmt : info.value['saleAmt'],
            userCd  : store.state.login.userCd
        };

        const res = await getAxiosData('https://data.planorder.kr/factory/getSysItemResult', params);

        if(res.status === 200)
        {
            router.go(-1);
        }

        resultYn.value = 'N';
        return { result: true, msg: 'success' };
    }
    catch(e)
    {
        console.log(e);
        getAlertDataSet(true, '에러', '제품 저장 중 에러가 발생하였습니다. 지속될 경우 관리자에게 문의하세요.', false);
        resultYn.value = 'N';
        return { result: false, msg: 'error' };
    }
}

onMounted(() => {
    const params = {
        ceCd   : store.state.login.ceCd,
        fcCd   : route.query.cd,
        itemCd : route.query.itemCd
    };

    store.dispatch('factory/getSysItemInfo', params);
})

</script>

<style scoped>

</style>