<template>
    <div class="input flex py-1">
        <input type="checkbox" :id="id" :name="name" :checked="checked" />
        <label :for="id">
            <span></span>
            <p>
                {{ label }}
                <span class="block -mt-1 text-zinc-400" v-if="showContent">{{ content }}</span>
            </p>
        </label>
        <img v-if="showDetail" src="@/assets/images/arrow-down.png" alt="자세히 보기" title="자세히 보기"/>
    </div>
</template>

<script>
export default {
    name : "CheckBox",
    props: {
        id         : String,
        name       : String,
        checked    : Boolean,
        label      : String,
        showDetail : Boolean,
        showContent: Boolean,
        content    : String,
    }
}
</script>

<style lang="scss" scoped>
.input {
    align-items: center;
    position: relative;
    cursor: pointer;

    input {
        display: none;

        & + label {
            & > span {
                width: 20px;
                height: 20px;
                border: 1px solid #bdbdbd;
                border-radius: 50%;
                display: block;
                margin-right: 10px;
            }
        }

        &:checked {
            & + label {
                span {
                    border: 5px solid #0099FF;
                }
            }
        }
    }

    label {
        cursor: pointer;
        font-size: 12px;
        font-weight: 400;
        color: #616161;
        display: flex;
        align-items: center;
    }

    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotateZ(270deg);
        right: 0;
        width: 10px;
    }
}
</style>