<template>
    <ModalBox title="외주 공장 이름 제품관리" showFixedButton showButton buttonText="저장" @close="router.go(-1)" @blueBtnClick="getOutItemCheck">
        <div class="space-y-5">
            <div>
                <div class="flex justify-between items-center mb-4">
                    <h4 class="font-bold text-sm">제품명<span class="text-red-500">*</span></h4>
                    <DeleteButton v-if="route.query.type === 'M'" @getDelete="getItemDeleteCheck"/>
                </div>
                <InlineInput type="text" id="itemNm" :value="info['itemNm']" label="제품 이름" @input="getInputValSet"/>
            </div>
            <div>
                <h4 class="font-bold text-sm mb-4">색상 추가<span class="text-red-500">*</span></h4>
                <div class="space-y-2.5">
                    <template v-for="(item, index) in info['icList']" :key="index">
                        <div class="flex justify-between" v-if="item['del'] === 'N'">
                            <label for="" class="w-1/3 text-sm text-neutral-500">{{ index === 0 ? '색상이름' : '' }}</label>
                            <div class="flex justify-between w-2/3">
                                <BasicInput :id="'icNm'+index" :value="item['icNm']" class="w-2/3" @input=getIcValSet(index) />
                                <InlineButton :buttonText="index === 0 ? '컬러추가' : '삭제'" :blueButton="index === 0" :grayButton="index !== 0" class="w-1/3 ml-2.5" @click="getIcBtn(index)"/>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div>
                <h4 class="font-bold text-sm mb-4">옵션 설정<span class="text-red-500">*</span></h4>
                <div class="space-y-2.5">
                    <InlineDual label="기본 단위">
                        <BasicInput type="number" id="size" :value="info['size']" class="w-1/2" @input="getInputValSet"/>
                        <BasicSelect id="unit" :value="info['unit']" :options="unit" class="w-1/2" @change="getSelect"/>
                    </InlineDual>
                    <InlineUnit type="number" id="baseHeight" :value="info['baseHeight']" unit="cm" label="기본 높이" @input="getInputValSet"/>
                    <InlineUnit type="number" id="pok" :value="info['pok']" unit="cm" label="원단 폭" v-if="info['unit'] === '003'" @input="getInputValSet"/>
                    <InlineUnit type="number" id="purcAmt" :value="info['purcAmt']" unit="원" label="매입 가격" @input="getInputValSet"/>
                    <InlineUnit type="number" id="saleAmt" :value="info['saleAmt']" unit="원" label="판매 가격" @input="getInputValSet"/>
                </div>
            </div>
        </div>
    </ModalBox>
    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp" @getCancle="getCancle"/>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox.vue";
import BasicInput from "@/components/input/BasicInput.vue";
import InlineUnit from "@/components/input/InlineUnit.vue";
import BasicSelect from "@/components/input/BasicSelect.vue";
import InlineInput from "@/components/input/InlineInput.vue";
import InlineDual from "@/components/input/InlineDual.vue";
import DeleteButton from "@/components/button/DeleteButton.vue";
import InlineButton from "@/components/button/InlineButton.vue";
import AlertBox from "@/components/modal/AlertBox.vue";

import { onMounted, computed, ref, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import { getAxiosData } from "@/assets/js/function";

const store  = useStore();
const router = useRouter();
const route  = useRoute();

const info   = computed(() => store.state.factory.out.itemInfo);
const unit   = computed(() => {
    if(route.query.instCd === 'IC001')
    {
        return [
            { label: '회배', value: '001' }
        ]
    }
    else
    {
        return [
            { label: '야드', value: '002' },
            { label: '폭', value: '003' }
        ]
    }
});

const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const focusId  = ref('');
const resultYn = ref('N');
const icIndex  = ref(0);

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
}

const getComp = () => {
    alertData.value['view'] = false;

    switch(resultYn.value)
    {
        case 'Y':
            getOutItemResult();
        break;
        case 'MD':
            getOutItemDelete();
        break;
        case 'SD':
            getOutItemSubDelete();
        break;
        case 'N':
            if(focusId.value === '')
            {
                focusId.value = 'itemNm';
            }

            document.getElementById(focusId.value).focus();
        break;
    }
}

const getCancle = () => {
    alertData.value['view'] = false;
}

const getInputValSet = () => {
    info.value[event.target.id] = event.target.value;
}

const getIcValSet = (index) => {
    info.value['icList'][index]['icNm'] = event.target.value;
}

const getSelect = () => {
    info.value['unit'] = event.target.value;
}

const getItemDeleteCheck = () => {
    getAlertDataSet(true, '삭제', '제품을 삭제하시겠습니까?', true);
    resultYn.value = 'MD';
}

const getIcBtn = (index) => {
    if(index === 0)
    {
        info.value['icList'].push({ icNm : '', gb : 'I', del : 'N' });

        nextTick(() => {
            const index   = info.value['icList'].length - 1;
            const element = document.getElementById(`icNm${index}`);
            if (element) 
            {
                element.focus();
            }
        });
    }
    else
    {
        if(info.value['icList'][index]['gb'] === 'I')
        {
            info.value['icList'][index]['del'] = 'Y';
        }
        else
        {
            getAlertDataSet(true, '삭제', '해당 제품 색상을 삭제하시겠습니까?', true);
            resultYn.value = 'SD';
            icIndex.value  = index;
        }
    }
}

const getOutItemDelete = () => {
    resultYn.value = 'N';

    const params = {
        itemCd : info.value['itemCd']
    };

    const data = store.dispatch('factory/getOutFactoryItemDelete', params);

    data.then(() => {
        router.go(-1);
    }).catch(() => {
        getAlertDataSet(true, '에러', '제품 삭제 중 에러가 발생하였습니다. 지속될 경우 관리자에게 문의하세요.', false);
    });
}

const getOutItemSubDelete = () => {
    resultYn.value = 'N';

    const params = {
        icCd : info.value['icList'][icIndex.value]['icCd']
    };

    const data = store.dispatch('factory/getOutFactoryItemSubDelete', params);

    data.then(() => {
        info.value['icList'].splice(icIndex.value, 1);
    }).catch(() => {
        getAlertDataSet(true, '에러', '외주 제품 색상 삭제 중 에러가 발생하였습니다. 지속될 경우 관리자에게 문의하세요.', false);
    });
}

const getValCheck = () => {
    if(info.value['itemNm'] === '')
    {
        getAlertDataSet(true, '확인', '제품이름을 입력해주세요.', false);
        focusId.value = 'itemNm';
        return false;
    }

    let icTarget   = 0;
    let icStat     = true;

    info.value['icList'].forEach((item, index) => {
        if(item['icNm'] === '' && item['del'] === 'N')
        {
            icTarget = index;
            icStat   = false;
        }
    });

    if(!icStat)
    {
        getAlertDataSet(true, '확인', '색상이름을 입력하세요.', false);
        focusId.value = 'icNm'+icTarget;
        return false;
    }

    if(Number(info.value['size']) === 0)
    {
        getAlertDataSet(true, '확인', '기본 단위를 입력하세요.', false);
        focusId.value = 'size';
        return false;
    }

    if(Number(info.value['baseHeight']) === 0)
    {
        getAlertDataSet(true, '확인', '기본 높이를 입력하세요.', false);
        focusId.value = 'baseHeight';
        return false;
    }

    if(info.value['unit'] === '003')
    {
        if(Number(info.value['pok']) === 0)
        {
            getAlertDataSet(true, '확인', '원단 폭을 입력하세요.', false);
            focusId.value = 'pok';
            return false;
        }
    }

    if(Number(info.value['purcAmt']) === 0)
    {
        getAlertDataSet(true, '확인', '매입 가격을 입력하세요.', false);
        focusId.value = 'purcAmt';
        return false;
    }

    if(Number(info.value['saleAmt']) === 0)
    {
        getAlertDataSet(true, '확인', '판매 가격을 입력하세요.', false);
        focusId.value = 'saleAmt';
        return false;
    }

    return true;
}

const getOutItemResult = async () => {
    try
    {
        const unit   = info.value['unit'] === '' ? (route.query.instCd === 'IC001' ? '001' : '002') : info.value['unit'];
        const params = {
            type      : route.query.type,
            fcCd      : route.query.fcCd,
            instCd    : route.query.instCd,
            itemCd    : info.value['itemCd'],
            itemNm    : info.value['itemNm'],
            icList    : info.value['icList'],
            size      : info.value['size'],
            unit      : unit,
            baseHeight: info.value['baseHeight'],
            pok       : info.value['pok'],
            purcAmt   : info.value['purcAmt'],
            saleAmt   : info.value['saleAmt']
        };

        const res = await getAxiosData('https://data.planorder.kr/factory/getOutItemResult', params);

        if(res.status === 200)
        {
            router.go(-1);
        }

        resultYn.value = 'N';
        return { result: true, msg: 'success' };
    }
    catch(e)
    {
        console.log(e);
        if(e.response.data.status === 409)
        {
            getAlertDataSet(true, '에러', e.response.data.data, false);
        }
        else
        {
            getAlertDataSet(true, '에러', '외주 제품 저장 중 에러가 발생하였습니다. 지속될 경우 관리자에게 문의하세요.', false);
        }

        resultYn.value = 'N';
        return { result: false, msg: 'error' };
    }
}

const getOutItemCheck = () => {
    if(getValCheck())
    {
        getAlertDataSet(true, '저장', '저장하시겠습니까?', true);
        resultYn.value = 'Y';
    }
}

const getInfo = () => {
    store.commit('factory/setReset', { gb : 'out', data : 'itemInfo' });


    nextTick(() => {
        if(route.query.type === 'M')
        {
            store.dispatch('factory/getOutFactoryItemInfo', { itemCd : route.query.itemCd });
        }
    });

    console.log(info.value);
}

onMounted(() => {
    getInfo();
});

</script>

<style scoped>

</style>