<template>
    <ModalBox title="약관동의" showFixedButton showButton buttonText="동의하고 계속하기" @blueBtnClick="getAgreeNext">
        <h2 class="font-bold text-lg">
            <span class="text-brand block">플랜오더</span>
            약관에 동의해주세요.
        </h2>
        <p class="mt-3 mb-6 text-xm font-bold text-gray-300">모든 이용자는 플랜오더의 이용약관 및 개인정보 처리 방침에 동의해야합니다.</p>
        <div class="space-y-2">
            <CheckBox label="모두 동의합니다." id="all" name="agree" class="border-b pb-3" @change="getAllCheck"/>
            <CheckBox label="[필수] 만 14세 이상입니다." id="age" name="agree" showDetail @change="getCheckbox"/>
            <CheckBox label="[필수] 이용약관 동의" id="use" name="agree" showDetail @change="getCheckbox"/>
            <CheckBox label="[필수] 개인정보 수집 - 이용 동의" id="info" name="agree" showDetail @change="getCheckbox"/>
            <CheckBox label="[선택사항] 마케팅 정보 수신 동의" id="choice" name="agree" showDetail @change="getCheckbox"/>
        </div>
    </ModalBox>
    <AlertBox v-if="alertData['view']"
              :type="alertData['type']" :title="alertData['title']" :description="alertData['description']"
              :showGreyButton="alertData['state']"
              @getComp="getComp"/>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox";
import CheckBox from "@/components/input/CheckBox";
import AlertBox from "@/components/modal/AlertBox.vue";

import {computed, ref} from 'vue';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';

import {getCertified} from "@/assets/js/certified";

const store     = useStore();
const router    = useRouter();

const certified = computed(() => store.state.join.info.certified);
const agree     = computed(() => store.state.join.info.agree);

const btnClass  = ref('grey');
const alertData = ref({
    view       : false,
    title      : '',
    description: '',
    state      : false
});

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']         = view;
    alertData.value['title']        = title;
    alertData.value['description']  = dec;
    alertData.value['state']        = state;
};

const getComp = () => {
    alertData.value['view'] = false;

    if (certified.value === 'Y') 
    {
        router.push('/join/joinFirst');
    }
}

const getAllCheck = (event) => {
    const checkStat = event.target.checked;
    const list      = document.getElementsByName('agree');

    list.forEach((item) => {
        item.checked = checkStat;

        store.commit('join/setAgree', {gb: 'all', value: checkStat ? 'Y' : 'N'});
    });

    getClassChange();
};

const getCheckbox = (event) => {
    const id        = event.target.id;
    const checkStat = event.target.checked;

    store.commit('join/setAgree', {gb: id, value: checkStat ? 'Y' : 'N'});
    getClassChange();
};

const getClassChange = () => {
    if (getAgreeCheck()) 
    {
        btnClass.value = 'blue';
    } 
    else 
    {
        btnClass.value = 'grey';
    }
};

const getAgreeCheck = () => {
    return !(agree.value['age']['yn'] === 'N' || agree.value['use']['yn'] === 'N' || agree.value['info']['yn'] === 'N');
};

const getAgreeNext = () => {
    if (getAgreeCheck()) 
    {
        getCertified().then(result => {
            if (result.status) 
            {
                getAlertDataSet(true, '성공', result.msg, false);
                store.commit('join/setInfo', {gb: 'certified', value: 'Y'});
            } 
            else 
            {
                getAlertDataSet(true, '실패', result.msg, false);
            }
        }).catch(error => {
            getAlertDataSet(true, '에러', error.msg, false);
        });
    } 
    else 
    {
        getAlertDataSet(true, '확인', '필수 약관을 모두 동의해주세요.', false);
    }
}

</script>

<style scoped>

</style>