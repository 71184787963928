<template>
    <ModalBox :title="info['kdNm']" noPadding>
        <div class="px-4">
            <h3 class="font-bold text-base mb-2">{{ info['kdNm'] }}</h3>
            <p class="text-xm text-zinc-400 mb-5">{{ info['descrip'] }}</p>
        </div>
        <div class="flex justify-between items-center bg-neutral-50 py-3.5 px-4">
            <p class="text-sm">자동 알림 보내기</p>
            <SliderCheckbox :id="'useYn'" :checked="info['useYn'] === 'Y' ? true : false" @change="getCheckbox"/>
        </div>
        <div class="px-4">
            <div class="flex justify-between items-center mt-7 mb-3.5">
                <p class="text-sm">알림 내용 미리보기</p>
                <InlineButton buttonText="테스트 발송" blueButton />
            </div>
            <KakaoBox :info="info"/>
            <ul class="mt-8">
                <li class="flex justify-between items-center py-2 border-b">
                    <label for="" class="text-sm">고객이름</label>
                    <BasicSelect id="client" :options="client" :value="info['client']" noBorder @change="getSelect"/>
                </li>
                <li class="flex justify-between items-center py-4 border-b">
                    <label for="" class="text-sm">담당 멤버</label>
                    <SliderCheckbox id="person" :checked="info['person'] === 'Y' ? true : false" @change="getCheckbox"/>
                </li>
                <li class="flex justify-between items-center py-4 border-b">
                    <label for="" class="text-sm">샵 주소</label>
                    <SliderCheckbox id="shopAddr" :checked="info['shopAddr'] === 'Y' ? true : false" @change="getCheckbox"/>
                </li>
                <li class="flex justify-between items-center py-2 border-b">
                    <label for="" class="text-sm">주차시간 설정</label>
                    <BasicSelect id="park" :options="park" :value="info['park']" noBorder @change="getSelect"/>
                </li>
                <li class="flex justify-between items-center py-4 border-b">
                    <label for="" class="text-sm">홈페이지 링크</label>
                    <SliderCheckbox id="url" :checked="info['url'] === 'Y' ? true : false" @change="getCheckbox"/>
                </li>
            </ul>
            <p class="text-center mt-12 text-sm mb-2.5">카카오톡 발신 계정 연결</p>
            <p class="text-center mb-4 text-zinc-400 text-xm">연동 설정 전에는 플랜오더 대표 계정으로 발송이 되며,<br>카카오톡 계정 연결 시, 샵 해당 계정으로 고객에게 자동 고객 알림이 발송됩니다.</p>
            <BaseButton buttonText="카카오톡 비즈니스 계정 등록" lightButton />
        </div>
    </ModalBox>
    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp"/>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox.vue";
import SliderCheckbox from "@/components/input/SliderCheckbox.vue";
import InlineButton from "@/components/button/InlineButton.vue";
import KakaoBox from "@/components/modal/KakaoBox.vue";
import BasicSelect from "@/components/input/BasicSelect.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import AlertBox from "@/components/modal/AlertBox.vue";

import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const store  = useStore();
const route  = useRoute();
const info   = computed(() => store.state.kakao.info);
const client = computed(() => {
    return [
        { value : 'Y', label : '표기' },
        { value : 'N', label : '숨김' }
    ];
});

const park   = computed(() => {
    return [
        { value : 'Y', label : '무료' },
        { value : 'C', label : '유료' },
        { value : 'N', label : '불가' },
        { value : '1', label : '1시간' },
        { value : '2', label : '2시간' },
        { value : '3', label : '3시간' },
        { value : '4', label : '4시간' }
    ];
});

const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
};

const getComp = () => {
    alertData.value['view'] = false;
}

const getCheckbox = () => {
    const id  = event.target.id;
    const val = event.target.checked ? 'Y' : 'N';

    const params = {
        kdCd    : route.query.cd,
        id      : id,
        val     : val
    };

    getResult(params);
}

const getSelect = () => {
    const id  = event.target.id;
    const val = event.target.value;

    const params = {
        kdCd    : route.query.cd,
        id      : id,
        val     : val
    };

    getResult(params);
}

const getResult = (params) => {
    const result = store.dispatch('kakao/getKakaoResult', params);

    result.then((res) => {
        if(res['result']) 
        {
            getAlertDataSet(true, '알림', '알림이 성공적으로 변경되었습니다.', false);
            info.value[params['id']] = params['val'];
        } 
        else 
        {
            getAlertDataSet(true, '알림', '알림이 변경에 실패하였습니다. 지속될 경우 관리자에게 문의하세요.', false);
        }
    });
}

onMounted(() => {
    const params = {
        kdCd : route.query.cd
    };

    store.dispatch('kakao/getKakaoInfo', params);
});
</script>

<style scoped>

</style>