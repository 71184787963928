<template>
    <select class="pt-3 pb-2 px-2 bg-neutral-100 rounded-sm text-xm">
        <option v-for="(option, index) in options" :key="index" :value="option.value" :selected="option.value === value">
            {{ option.label }}
        </option>
    </select>
</template>

<script>
export default {
    name: "SelectBox",
    props: {
        options: Array,
        value  : String
    }
}
</script>

<style lang="scss" scoped>
select option{
    width: 100%;
}
</style>