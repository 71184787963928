import { getAxiosData } from '@/assets/js/function';
/**
 * @description 시공시간 설정 관련 vuex 모듈
 */
const timeSettingModule = {
    namespaced : true, 
    state : {
        curtainTime : '',
        blindTime   : ''
    },
    mutations : {
        setTime(state, { name, time })
        {
            state[name] = time;
        }
    },
    actions : {
        async getTime(context, data)
        {
            try
            {
                const res = await getAxiosData('https://data.planorder.kr/timeSetting/getTime', data);
                console.log(res);

                context.commit('setTime', { name: 'curtainTime', time: res.data['curtainTime'] });
                context.commit('setTime', { name: 'blindTime', time: res.data['blindTime'] });
            }
            catch(err)
            {
                console.error(err);
            }
        },
        async saveTime(context, data)
        {
            try
            {
                await getAxiosData('https://data.planorder.kr/timeSetting/saveTime', data);
            }
            catch(err)
            {
                console.error(err);
            }
        }
    }
};
  
export default timeSettingModule;