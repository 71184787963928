<template>
    <ModalBox title="외주공장 저장" showFixedButton showButton buttonText="저장" @close="getClose" @blueBtnClick="getFactoryCheck">
        <div class="space-y-6">
            <LabelInput type="text" id="faNm" :value="info['faNm']" label="공장 이름" @input="getInputValSet"/>
            <LabelInput type="text" id="tel" :value="info['tel']" label="전화번호" @input="getInputValSet"/>
            <LabelAddress id="addr" type="text" label="주소" :value="info['addr']" readonly @click="getOpenDaumPost"/>
            <LabelInput type="text" id="addrDetail" :value="info['addrDetail']" label="상세주소" @input="getInputValSet"/>
            <LabelInput type="text" id="accInfo" :value="info['accInfo']" label="입금 계좌 정보" @input="getInputValSet"/>
            <LabelInput type="text" id="kakaoTel" :value="info['kakaoTel']" label="카카오톡 연동 전화번호" @input="getInputValSet"/>
            <LabelTextarea id="memo" :value="info['memo']" label="메모" rowCount="5" @input="getInputValSet"/>
            <div id="layer" style="display:none;position:fixed;overflow:hidden;z-index:1;-webkit-overflow-scrolling:touch;">
                <img src="//t1.daumcdn.net/postcode/resource/images/close.png" id="btnCloseLayer" style="cursor:pointer;position:absolute;right:-3px;top:-3px;z-index:1" @click="getCloseDaumPost()" alt="닫기 버튼">
            </div>
        </div>
    </ModalBox>
    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp" @getCancle="getCancle"/>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox.vue";
import LabelInput from "@/components/input/LabelInput.vue";
import LabelAddress from "@/components/input/LabelAddress.vue";
import LabelTextarea from "@/components/input/LabelTextarea.vue";
import AlertBox from "@/components/modal/AlertBox.vue";

import { getAxiosData, getDaumPopupPosition } from "@/assets/js/function";

import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store  = useStore();
// const route  = useRoute();
const router = useRouter();

const info = computed(() => store.state.factory.out.info);

const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const focusId  = ref('');
const resultYn = ref('N');

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
}

const getComp = () => {
    alertData.value['view'] = false;

    if(resultYn.value === 'Y')
    {
        getFactoryResult();
    }
    else
    {
        if(focusId.value === '')
        {
            focusId.value = 'faNm';
        }

        document.getElementById(focusId.value).focus();
    }
}

const getCancle = () => {
    alertData.value['view'] = false;
}

const getInputValSet = (event) => {
    const id    = event.target.id;
    const value = event.target.value;

    info.value[id] = value;
}

const getOpenDaumPost = () => {
    const layer = document.getElementById('layer');

    new window.daum.Postcode({
        oncomplete          : function(data) {
            let addr = '';

            if (data.userSelectedType === 'R') 
            { 
                // 사용자가 도로명 주소를 선택했을 경우
                addr = data.roadAddress;
            } 
            else 
            { 
                // 사용자가 지번 주소를 선택했을 경우(J)
                addr = data.jibunAddress;
            }

            info.value['zip']  = data.zonecode;
            info.value['addr'] = addr;
            document.getElementById("addrDetail").focus();

            // iframe을 넣은 element를 안보이게 한다.
            // (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
            layer.style.display = 'none';
        },
        width               : '100%',
        height              : '100%',
        maxSuggestItems     : 5
    }).embed(layer);

    getDaumPopupPosition(layer);
}

const getCloseDaumPost = () => {
    document.getElementById('layer').style.display = 'none';
}

const getValCheck = () => {
    if(info.value['faNm'] === '')
    {
        getAlertDataSet(true, '확인', '공장 이름을 입력해주세요.', false);
        focusId.value = 'faNm';
        return false;
    }

    if(info.value['tel'] === '')
    {
        getAlertDataSet(true, '확인', '전화번호를 입력해주세요.', false);
        focusId.value = 'tel';
        return false;
    }

    if(info.value['addr'] === '')
    {
        getAlertDataSet(true, '확인', '주소를 검색하세요.', false);
        return false;
    }

    // if(info.value['accInfo'] === '')
    // {
    //     getAlertDataSet(true, '확인', '입금 계좌 정보를 입력하세요..', false);
    //     focusId.value = 'accInfo';
    //     return false;
    // }

    return true;
}

const getClose = () => {
    router.go(-1);
}

const getFactoryCheck = () => {
    if(getValCheck())
    {
        getAlertDataSet(true, '저장', '저장하시겠습니까?', true);
        resultYn.value = 'Y';
    }
}

const getFactoryResult = async () => {
    try
    {
        const params = {
            'type'          : 'I',
            'ceCd'          : store.state.login.ceCd,
            'fcGb'          : 'O',
            'faNm'          : info.value['faNm'],
            'appGb'         : 'Y',
            'tel'           : info.value['tel'],
            'zip'           : info.value['zip'],
            'addr'          : info.value['addr'],
            'addrDetail'    : info.value['addrDetail'],
            'accInfo'       : info.value['accInfo'],
            'kakaoTel'      : info.value['kakaoTel'],
            'memo'          : info.value['memo']
        };

        const res    = await getAxiosData('https://data.planorder.kr/factory/getResult', params);

        console.log(res);

        if(res.status === 200)
        {
            router.go(-1);
        }

        resultYn.value = 'N';
        return { result: true, msg: 'success' };
    }
    catch(e)
    {
        console.log(e);
        
        if(e.response.data.status === 409)
        {
            getAlertDataSet(true, '에러', e.response.data.data, false);
        }
        else
        {
            getAlertDataSet(true, '에러', '외주공장 저장 중 에러가 발생했습니다. 지속될 경우 관리자에게 문의하세요.', false);
        }

        resultYn.value = 'N';
        return { result: false, msg: 'error' };
    }
};

onMounted(() => {
    store.commit('factory/setReset', { gb : 'out', data : 'info' });
});

</script>

<style scoped>

</style>