<template>
    <ModalBox title="주간 분석">
        <div class="flex justify-between items-center mb-3.5">
            <h3 class="text-lg font-bold">{{ year }}년 {{ month }}월달</h3>
        </div>
        <AccInfo :saleAmt="week['amt']['saleAmt']" :purcAmt="week['amt']['purcAmt']" :margin="week['amt']['margin']"/>
        <GrayBar class="my-2.5"/>
        <div>
            <template v-for="(w, i) in week['weekList']" :key="i">
                <h2 class="py-2.5 text-sm border-b text-brand">
                    {{ month }}월 {{ w['week'] }}주차
                </h2>
                <template v-for="(item, index) in week['list']" :key="index">
                    <StatisticsList v-if="w['week'] === item['week']" :day="getDate(item['stDt'])" 
                        :margin="item['margin']" :rev="item['rev']" 
                        :waitAmt="item['waitAmt']" :totalAmt="item['saleAmt']" @getClick="getClick(item['stDt'])"/>
                </template>
            </template>
        </div>
    </ModalBox>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox.vue";
import GrayBar from "@/components/common/GrayBar.vue";
import StatisticsList from "@/views/acc/StatisticsList.vue";
import AccInfo from "@/views/acc/AccInfo.vue";

import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { getConvertDate } from '@/assets/js/function';

const store  = useStore();
const route  = useRoute();
const router = useRouter();

const week  = computed(() => store.state.acc.week);
const year  = route.query.year;
const month = route.query.mon;

const getAccWeek = () => {
    const params = {
        ceCd    : store.state.login.ceCd,
        year    : year,
        month   : month
    };

    store.dispatch('acc/getAccWeek', params);
}

const getDate = (dt) => {
    return getConvertDate(new Date(dt), 'mm%dd%w%2');
}

const getClick = (dt) => {
    router.push({ path : '/acc/day', query : { dt : dt } });
}

onMounted(() => {
    getAccWeek();
});

</script>

<style scoped>

</style>