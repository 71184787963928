<template>
    <div v-if="isModalOpen" class="flex w-full h-screen fixed top-0 left-0 right-0 mx-auto items-center z-30" @click="closeModal">
        <div class="mx-auto rounded-xl border shadow-md py-4 px-3 bg-white w-60" @click.stop>
            <div class="flex justify-between items-center border-b pb-4">
                <img src="@/assets/images/icon-profile.png" alt="profile" title="profile" class="w-8" />
                <p class="w-1/2 font-bold text-base -mt-1">
                    <span class="text-brand block text-[0.5rem]">일정</span>
                    <span class="flex items-center -mt-2">
                        하현재
                        <FontAwesomeIcon :icon="['fas', 'caret-right']" class="ml-4 text-zinc-500" />
                    </span>
                </p>
                <h3 class="text-brand font-bold text-base">시공</h3>
            </div>
            <p class="flex w-fit items-center my-2.5 rounded-xl border pt-1 pb-0.5 px-1.5 font-bold text-xm">
                시공대기
                <FontAwesomeIcon :icon="['fas', 'caret-down']" class="text-zinc-500 -mt-1 mx-1" />
            </p>
            <div class="space-y-1.5">
                <p class="text-xm text-neutral-500">6월 30일 (목) 16:45</p>
                <p class="bg-brand text-white w-fit py-0.5 px-2 rounded-xl text-xm">010-3445-2105</p>
                <p class="bg-brand text-white w-fit py-0.5 px-2 rounded-xl text-xm">부산시 수영구 수영로 411-1</p>
                <p class="text-xm text-neutral-500">상세주소: 광안자이 아파트 102동 1004호</p>
                <p class="text-xm text-neutral-500">설치 예상시간: 40분</p>
                <p class="text-xm text-neutral-500">설치 수량: 블라인드 5개, 커튼 4개</p>
            </div>
            <div class="pt-2.5 pb-4 ml-4 flex">
                <p class="bg-neutral-300 w-0.5 rounded-xl"></p>
                <p class="ml-2.5 text-xm text-neutral-300">
                    가나다라<br>
                    마바사
                </p>
            </div>
            <div class="text-right mt-2.5">
                <FontAwesomeIcon class="cursor-pointer text-zinc-500" :icon="['fas', 'pen']" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    components: {FontAwesomeIcon},
    setup() {
        const isModalOpen = ref(false);

        const openModal = () => {
            isModalOpen.value = true;
        };

        const closeModal = () => {
            isModalOpen.value = false;
        };

        return {
            isModalOpen,
            openModal,
            closeModal
        };
    }
};
</script>

<style scoped>

</style>