<template>
    <ModalBox title="예약된 알림">
        <div class="flex flex-row-reverse mb-2.5">
            <SelectBox class="w-2/5" />
        </div>
        <LineTitle title="2022.12.24 (오늘)" />
        <TalkAlert status="completed" alarm="계약 완료 알림" tel="010-3445-2105" name="하현재" time="오후 06:21" />
    </ModalBox>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox.vue";
import SelectBox from "@/components/input/SelectBox.vue";
import LineTitle from "@/components/common/LineTitle.vue";
import TalkAlert from "@/components/list/TalkAlert.vue";

import { onMounted } from 'vue';
import { useStore } from 'vuex';

const store  = useStore();

onMounted(() => {
    store.dispatch('kakao/getKakaoResList', { ceCd : store.state.login.ceCd });
});
</script>

<style scoped>

</style>