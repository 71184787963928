<template>
    <TabModalBox title="공장" :tabs="tabList" >
        <template v-slot:0>
            <ImageList v-for="(list, index) in sysList" :key="index" 
                :showImage="list.imgUrl !== '' ? true : false" :imgUrl="list.imgUrl" :url="'/factory/detail'" :cd="list.fcCd"
                :title="list.faNm" :process="getProcess(list.appGb)" :tel="list.tel" :address="list.addr+' '+list.addrDetail" 
                class="border-b" :isBlue="list.appGb === 'E' ? true : false" :isYellow="list.appGb === 'N' ? true : false"/>
            <FixedButton buttonText="공장코드 추가" @click="getFactoryAddPop"/>
            <FactoryInput v-if="sysPop" @getClose="sysPop=false"/>
        </template>
        <template v-slot:1>
            <ImageList v-for="(list, index) in outList" 
                :title="list.faNm" :process="''" :tel="list.tel" :address="list.addr+' '+list.addrDetail" :url="'/factory/outDetail'" :cd="list.fcCd" class="border-b" :key="index" />
            <FixedButton buttonText="외주공장 추가" @click="router.push({ path : '/factory/outInput', query : { type : 'I' } });"/>
        </template>
    </TabModalBox>
</template>

<script setup>
import TabModalBox from "@/components/modal/TabModalBox.vue";
import ImageList from "@/components/list/ImageList.vue";
import FixedButton from "@/components/button/FixedButton.vue";
import FactoryInput from "@/views/factory/FactoryInput.vue";

import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store   = useStore();
const router  = useRouter();

const sysList       = computed(() => store.state.factory.sys.list);
const outList       = computed(() => store.state.factory.out.list);
const getProcess    = computed(() => {
    return (appGb) => {
        switch(appGb)
        {
            case 'E' : return '승인대기';
            case 'Y' : return '';
            case 'N' : return '승인반려';
        }
    }
});
const sysPop   = ref(false);

const tabList = [
    { title: "플랜오더 공장" },
    { title: "외주 공장" }
];

// const lists = [
//     { title: "디자인윈도우", tel: "010-3445-2105", address: "부산시 수영구 감포로 242", tags: [{title: "블라인드"}] },
//     { title: "디자인윈도우", process: "승인대기", tel: "010-3445-2105", address: "부산시 수영구 감포로 242", isBlue: true, tags: [{title: "블라인드"}] },
//     { title: "디자인윈도우", process: "반려", tel: "010-3445-2105", address: "부산시 수영구 감포로 242", isYellow: true, tags: [{title: "블라인드"}, {title: "커튼"}] },
// ]

const getFactoryAddPop = () => {
    store.commit('factory/setReset', { gb : 'sys', data : 'info' });
    sysPop.value = true;
}

onMounted(() => {
    store.dispatch('factory/getList', { ceCd : store.state.login.ceCd });
});
</script>

<style scoped>

</style>