<template>
    <div class="popup w-full h-screen items-end fixed top-0 left-0 right-0 mx-auto z-30 bg-black/60" :class="{ active : isActive }" @click="getClose">
        <div class="popup-box duration-200 mx-auto rounded-t w-11/12 h-4/5 bg-white" :class="{ active : isActive }" @click.stop>
            <div class="flex pt-3.5 pb-3 px-4 items-center justify-between relative">
                <img src="@/assets/images/btn-prev.svg" alt="이전" title="이전" @click="getClose"/>
                <h3 class="text-center w-full font-bold text-sm mt-0.5">고객 상세</h3>
            </div>
            <div class="mx-4 h-full pb-32 overflow-y-scroll scroll-none">
                <div class="flex justify-between items-center border-b border-zinc-50 pt-2.5 pb-5">
                    <img src="@/assets/images/icon-profile.png" alt="profile" title="profile" class="w-8" />
                    <p class="w-2/3 font-bold text-base -mt-1">
                        <span class="text-brand block text-[0.5rem]">일정</span>
                        <span class="-mt-2">
                        하현재
                    </span>
                    </p>
                    <FontAwesomeIcon :icon="['fas', 'caret-right']" class="text-zinc-500" />
                </div>
                <div class="my-4 space-y-5">
                    <div class="flex items-center justify-between">
                        <div class="font-bold">
                            <p class="mb-2 text-xs text-zinc-300">견적 담당</p>
                            <p class="text-sm">하현재</p>
                        </div>
                        <button class="text-zinc-400 font-bold text-xm cursor-pointer">수정</button>
                    </div>
                    <div class="flex items-center justify-between">
                        <div class="font-bold">
                            <p class="mb-2 text-xs text-zinc-300">견적일</p>
                            <p class="text-sm">22.02.22 (금) 오전 10:32</p>
                        </div>
                        <button class="text-zinc-400 font-bold text-xm cursor-pointer">수정</button>
                    </div>
                    <div class="flex items-center justify-between">
                        <div class="font-bold">
                            <p class="mb-2 text-xs text-zinc-300">시공 담당</p>
                            <p class="text-sm">하현재</p>
                        </div>
                        <button class="text-zinc-400 font-bold text-xm cursor-pointer">수정</button>
                    </div>
                    <div class="flex items-center justify-between">
                        <div class="font-bold">
                            <p class="mb-2 text-xs text-zinc-300">시공일</p>
                            <p class="text-sm">22.02.22 (금) 오전 10:32</p>
                        </div>
                        <button class="text-zinc-400 font-bold text-xm cursor-pointer">수정</button>
                    </div>
                </div>
                <GrayBar class="my-3.5" />
                <h2 class="py-2.5 font-bold text-zinc-400 text-xs">수정 기록</h2>
                <div class="flex items-center py-1.5">
                    <img src="@/assets/images/icon-profile.png" alt="profile" title="profile" class="w-7" />
                    <div class="text-xm ml-2.5">
                        <p><b class="font-bold">김원명</b> 멤버가 시공 완료</p>
                        <p class="text-zinc-400">오후 12:18</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import GrayBar from "@/components/common/GrayBar.vue";

export default {
    name: "DetailModal",
    components: {GrayBar, FontAwesomeIcon},
    data() {
        return {
            isActive: true
        }
    },
    methods: {
        getClose() {
            this.isActive = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.popup {
    display: none;

    &.active {
        display: flex;
    }
}

.popup-box {
    transform: translateY(100%);

    &.active {
        transform: translateY(0);
    }
}
</style>