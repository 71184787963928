import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

const ceCd = store.state.login.ceCd;

if(ceCd === null)
{
    store.dispatch('login/initializeState');
}

createApp(App).use(router).use(store).mount('#app');