<template>
    <div class="container mx-auto">
        <div class="w-11/12 mx-auto">
            <InfoCard :title="detailInfo['clientNm']" :info="detailInfo['client']" />
            <CountList :lists="detailInfo['cnt']" class="py-5 rounded border border-zinc-200 my-5" @getCntClick="getTabClick"/>
            <template v-if="cntGb === 'I'">
                <div v-for="(item, index) in detailInfo['list']" :key="index">
                    <template v-if="item['useYn'] !== 'N' && item['stCd'] !== '012'">
                        <ProcessCard :info="item" @getMore="getMore"/>
                    </template>
                </div>
            </template>
            <template v-if="cntGb === 'S'">
                <div v-for="(item, index) in detailInfo['list']" :key="index">
                    <template v-if="item['stCd'] === '012'">
                        <ProcessCard :info="item" @getMore="getMore"/>
                    </template>
                </div>
            </template>
            <template v-if="cntGb === 'C'">
                <div v-for="(item, index) in detailInfo['list']" :key="index">
                    <template v-if="item['useYn'] === 'N' && item['stCd'] !== '012'">
                        <ProcessCard :info="item"/>
                    </template>
                </div>
            </template>
        </div>
    </div>
    <FixedButton buttonText="신규 명세표" @click="getNewEsti" />
    <CustomerDetailMore v-if="morePop" 
        @getClose="morePop=false" @getEstiSend="getEstiSend" @getConSend="getConSend" @getCancle="getEstiCancleCheck"/>
    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp" @getCancle="getCancle"/>
</template>

<script setup>
import InfoCard from "@/components/card/InfoCard.vue";
import CountList from "@/components/list/CountList.vue";
import ProcessCard from "@/components/card/ProcessCard.vue";
import FixedButton from "@/components/button/FixedButton.vue";
import CustomerDetailMore from "@/views/customer/CustomerDetailMore.vue";
import AlertBox from "@/components/modal/AlertBox.vue";

import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { getAxiosData } from "@/assets/js/function";

const store  = useStore();
const router = useRouter();
const route  = useRoute();

const detailInfo = computed(() => store.state.client.detailInfo);
const cntGb      = ref('I'); /** I(판매중) / S(판매완료) / C(판매취소) */
const morePop    = ref(false);
const emCd       = ref('');
const resultYn   = ref('N');

const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
}

const getComp = () => {
    alertData.value['view'] = false;

    if(resultYn.value === 'Y')
    {
        getEstiCancleResult();
    }
}

const getCancle = () => {
    alertData.value['view'] = false;
    resultYn.value = 'N';
};

const getTabClick = (gubun) => {
    cntGb.value = gubun;
};

const getMore = (val) => {
    morePop.value = true;
    emCd.value    = val
}

const getNewEsti = () => {
    router.push({ path : '/customer/estiIng', query : { type : 'N', clientCd :route.query.clientCd } })
}

const getEstiSend = () => {
    router.push({ path : '/customer/estiMate', query : { emCd : emCd.value } });
}

const getConSend = () => {
    const info = detailInfo.value['list'].find(item => item.emCd === emCd.value);

    if(info['stCd'] === '001' || info['stCd'] === '002')
    {
        getAlertDataSet(true, '확인', '계약 이후 확인 가능합니다.', false);
        return;
    }

    router.push({ path : '/customer/conMate', query : { emCd : emCd.value } });
}

const getEstiCancleCheck = () => {
    const info = detailInfo.value['list'].find(item => item.emCd === emCd.value);

    if(info['stCd'] === '012')
    {
        getAlertDataSet(true, '불가', '완료된 명세표는 취소 불가능합니다.', false);
    }
    else
    {
        getAlertDataSet(true, '명세표 취소', '해당 명세표를 취소상태로 변경하시겠습니까?', true);
        resultYn.value = 'Y';
    }
}

const getEstiCancleResult = async () => {
    const params = {
        ceCd   : store.state.login.ceCd,
        userCd : store.state.login.userCd,
        emCd   : emCd.value
    };

    await getAxiosData('https://data.planorder.kr/esti/getCancel', params).then((res) => {
        console.log(res);
        resultYn.value = 'N';
        if(res['status'] === 200)
        {
            getAlertDataSet(true, '완료', '해당 명세표가 취소 처리되었습니다.', false);
            getDetail();
        }
        else
        {
            getAlertDataSet(true, '에러', '명세표 취소 처리에 실패하였습니다. 지속될 경우 관리자에게 문의하세요.', false);
        }
    });
}

const getDetail = () => {
    const params = {
        ceCd     : store.state.login.ceCd,
        clientCd : route.query.clientCd
    };

    store.dispatch('client/getDetail', params);
}

onMounted(() => {
    getDetail();
});

</script>

<style scoped>

</style>