<template>
    <ModalBox title="명세서" showBlueButton showButton blueButtonText="제품 추가등록" @anotherBtnClick="getAddItem"
        showFixedButton buttonText="계약서 이동" @blueBtnClick="getConPop"
        showGreyButton buttonGreyText="견적서 저장" @greyBtnClick="getEstiSaveCheck">
        <div class="space-y-5">
            <TableCard v-for="(table, index) in list" :key="index" :title="table.title" :cards="table.cardLists"
                :columns="table.columns" :rows="table.rows" :tags="table.tags" :showTag="table.showTag" :showButton="table.showButton"
                @getDelete="getDeleteCheck" @getListClick="getListClick"/>
        </div>
        <GrayBar class="my-5"/>
        <CalculateCard title="제품 결제 내역" borderTop :calcs="payList" totalTitle="총 결제 금액" :totalAmt="totalAmt" showMore>
            <div class="space-y-3 my-6">
                <div class="flex items-center justify-between text-xs">
                    <label for="saleCnt" class=" text-zinc-400 font-normal">할인 금액</label>
                    <div class="relative w-2/5 border rounded-sm p-2">
                        <input type="number" id="saleCnt" name="saleCnt" :value="getAmtInfo('dcAmt')" class="w-full" readonly @click="disCount = true"/>
                        <span class="absolute vertical-h right-2 text-blue-500">원</span>
                    </div>
                </div>
                <div class="flex items-center justify-between text-xs">
                    <label for="plusCnt" class=" text-zinc-400 font-normal">추가 금액</label>
                    <div class="relative w-2/5 border rounded-sm p-2">
                        <input type="number" id="plusCnt" name="plusCnt" :value="getAmtInfo('addAmt')" class="w-full" readonly @click="addCount = true"/>
                        <span class="absolute vertical-h right-2 text-red-500">원</span>
                    </div>
                </div>
            </div>
            <div class="flex justify-between items-center mb-4">
                <label>천원단위 절삭</label>
                <ToggleCheckbox id="cut" name="cut" :value="cutInfo['gubun']" @getCutResult="getCutResult"/>
            </div>
        </CalculateCard>
    </ModalBox>
    <ContractModal />

    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp" @getCancle="getCancle"/>
    <SaleBox v-if="disCount" :gubun="'F'" @getResult="getDisResult" @getClose="disCount=false"/>
    <AddBox v-if="addCount" :gubun="'F'" @getResult="getAddResult" @getClose="addCount=false"/>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox.vue";
import TableCard from "@/components/card/TableCard.vue";
import GrayBar from "@/components/common/GrayBar.vue";
import CalculateCard from "@/components/card/CalculateCard.vue";
import ToggleCheckbox from "@/components/input/ToggleCheckbox.vue";
import SaleBox from "@/components/modal/SaleBox.vue";
import AddBox from "@/components/modal/AddBox.vue";
import ContractModal from "@/views/customer/ContractModal.vue";
import AlertBox from "@/components/modal/AlertBox.vue";

import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import { getAmt } from '@/assets/js/function';

const store  = useStore();
const route  = useRoute();
const router = useRouter();

const list     = computed(() => store.state.esti.list);
const payList  = computed(() => store.state.esti.payList);
const dcInfo   = computed(() => store.state.esti.dcInfo);
const addInfo  = computed(() => store.state.esti.addInfo);
const cutInfo  = computed(() => store.state.esti.cutInfo);
const totalAmt = computed(() => getAmt(payList.value, 'total'));

const gubun     = ref('N');
const edCd      = ref('');
const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const disCount  = ref(false);
const addCount  = ref(false);

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
}

const getComp = () => {
    switch(gubun.value)
    {
        case 'D':
            getDelete();
        break;
        case 'S':
            getEstiSave();
        break;
    }

    alertData.value['view'] = false;
}

const getCancle = () => {
    alertData.value['view'] = false;
};

const getAddItem = () => {
    router.push({ path: '/customer/estiIng', query: { clientCd : route.query.clientCd, emCd : route.query.emCd, type : 'I' }, replace : true});
}

const getAmtInfo = (name) => {
    const info = payList.value.find(item => item.name === name);

    return info['amt'];
}

const getDisResult = () => {
    disCount.value = false;

    if(dcInfo.value['unit'] === 'F')
    {
        dcInfo.value['amt'] = dcInfo.value['val'];

        store.commit('esti/setPayAmt', { name : 'dcAmt', amt : -Number(dcInfo.value['val']) });
    }
    else
    {
        const amt    = getAmt(payList.value, 'firstDis');
        const dcAmt  = Math.round(amt/100 * dcInfo.value['val']);
        
        dcInfo.value['amt'] = -dcAmt;

        store.commit('esti/setPayAmt', { name : 'dcAmt', amt : -dcAmt });
    }

    /** 절삭단위 체크된 경우 재적용 */
    if(cutInfo.value['gubun'] === 'Y')
    {
        const amt       = getAmt(payList.value, 'cutDis');
        const cutAmt    = amt % 10000;

        cutInfo.value['amt'] = -cutAmt;
        cutInfo.value['val'] = -cutAmt;
        store.commit('esti/setPayAmt', { name : 'cutAmt', amt : -cutAmt });
    }
}

const getAddResult = () => {
    addCount.value = false;

    addInfo.value['amt'] = addInfo.value['val'];
    store.commit('esti/setPayAmt', { name : 'addAmt', amt : Number(addInfo.value['val']) });

    /** 할인 단위가 %일 시 할인 금액 재적용 */
    if(dcInfo.value['unit'] === 'P')
    {
        const amt    = getAmt(payList.value, 'firstDis');
        const dcAmt  = Math.round(amt/100 * dcInfo.value['val']);

        dcInfo.value['amt'] = -dcAmt;
        store.commit('esti/setPayAmt', { name : 'dcAmt', amt : -dcAmt });
    }

    /** 절삭단위 체크된 경우 재적용 */
    if(cutInfo.value['gubun'] === 'Y')
    {
        const amt       = getAmt(payList.value, 'cutDis');
        const cutAmt    = amt % 10000;

        cutInfo.value['amt'] = -cutAmt;
        cutInfo.value['val'] = -cutAmt;
        store.commit('esti/setPayAmt', { name : 'cutAmt', amt : -cutAmt });
    }
}

const getCutResult = (value) => {
    cutInfo.value['gubun'] = value;

    let cutAmt = 0;

    if(value === 'Y')
    {
        const amt   = getAmt(payList.value, 'cutDis');
        cutAmt      = amt % 10000;
    }

    cutInfo.value['amt'] = -cutAmt;
    cutInfo.value['val'] = -cutAmt;
    store.commit('esti/setPayAmt', { name : 'cutAmt', amt : -cutAmt });
}

const getListClick = (edCd) => {
    router.push({ path: '/customer/estiIng', query: { clientCd : route.query.clientCd, emCd : route.query.emCd, edCd : edCd, type : 'M' }})
}

const getDeleteCheck = (value) => {
    edCd.value  = value;
    gubun.value = 'D';

    getAlertDataSet(true, '삭제', '삭제하시겠습니까?', true);
}

const getDelete = () => {
    const params = {
        clientCd : route.query.clientCd,
        edCd     : edCd.value
    }

    const del = store.dispatch('esti/getDelete', params);

    del.then((res) => {
        if(res.cnt === 0)
        {
            router.go(-1);
        }
        else
        {
            getList();
        }
    }).catch(() => {
        getAlertDataSet(true, '삭제', '삭제에 실패하였습니다. 지속될 경우 관리자에게 문의하세요.', false);
    });
}

const getEstiSaveCheck = () => {
    gubun.value = 'S';
    getAlertDataSet(true, '견적서 저장', '견적서를 저장하시겠습니까?', true);
}

const getEstiSave = () => {
    const params = {
        emCd    : route.query.emCd,
        addInfo : addInfo.value,
        dcInfo  : dcInfo.value,
        cutInfo : cutInfo.value
    }

    const save = store.dispatch('esti/getSave', params);

    save.then(() => {
        router.go(-1);
    }).catch(() => {
        getAlertDataSet(true, '저장실패', '견적서 저장에 실패하였습니다. 지속될 경우 관리자에게 문의하세요.', false);
    });
}

const getConPop = () => {
    store.commit('con/setStat');
}

const getList = () => {
    const params = {
        emCd : route.query.emCd
    };

    store.dispatch('esti/getList', params);
}

onMounted(() => {
    getList();
});

</script>

<style scoped>

</style>