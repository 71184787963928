<template>
    <footer class="fixed xl:hidden bottom-0 left-0 z-10 w-full bg-white flex items-center py-3 shadow-footer">
        <router-link to="/" class="w-1/5 center" :class="{ 'active': isActive('/') }">
            <IconHome class="mx-auto fill-gray-300" />
            <p class="text-center text-xs font-bold text-gray-300 mt-1">홈</p>
        </router-link>
        <router-link to="/calendar" class="w-1/5 center" :class="{ 'active': isActive('/calendar') }">
            <IconCalender fill="black" class="mx-auto fill-gray-300" />
            <p class="text-center text-xs font-bold text-gray-300 mt-1">캘린더</p>
        </router-link>
        <router-link to="/acc" class="w-1/5 center" :class="{ 'active': isActive('/acc') }">
            <IconAcc fill="black" class="mx-auto fill-gray-300" />
            <p class="text-center text-xs font-bold text-gray-300 mt-1">회계</p>
        </router-link>
        <router-link to="/average" class="w-1/5 center" :class="{ 'active': isActive('/average') }">
            <IconAverage fill="black" class="mx-auto fill-gray-300" />
            <p class="text-center text-xs font-bold text-gray-300 mt-1">통계</p>
        </router-link>
        <router-link to="/setting" class="w-1/5 center" :class="{ 'active': isActive('/setting') }">
            <IconSetting fill="black" class="mx-auto fill-gray-300" />
            <p class="text-center text-xs font-bold text-gray-300 mt-1">설정</p>
        </router-link>
    </footer>
</template>

<script>
import IconHome from "@/assets/svgs/IconHome.vue"
import IconCalender from "@/assets/svgs/IconCalender.vue";
import IconAcc from "@/assets/svgs/IconAcc.vue";
import IconAverage from "@/assets/svgs/IconAverage.vue";
import IconSetting from "@/assets/svgs/IconSetting.vue";

export default {
    name: "FooterVue",
    components: {
        IconSetting,
        IconAverage,
        IconAcc,
        IconCalender,
        IconHome,
    },
    methods: {
        isActive(route) {
            return this.$route.path === route;
        },
    },
}
</script>

<style lang="scss" scoped>
a{
    &.active{
        svg{
            fill: #292D77;
        }

        p{
            color: #292D77;
        }
    }
}
</style>