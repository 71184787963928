<template>
    <div class="container mx-auto">
        <div class="w-11/12 mx-auto py-2.5">
            <div class="space-y-2.5">
                <div>
                    <h3 class="font-bold">
                        플랜톡
                        <span :class="{ 'text-gray-400' : kakao['useYn'] === 'N' ? true : false, 'text-brand' : kakao['useYn'] === 'Y' ? true : false }">{{ kakao['useYn'] === 'Y' ? 'ON' : 'OFF' }}</span>
                    </h3>
                    <p class="text-xm text-zinc-400">알림 <span class="font-bold text-brand">{{ kakao['useCnt'] }}</span>종을 사용중입니다.</p>
                </div>
                <p class="bg-yellow-100 rounded p-2.5 pb-2 text-xm">
                    플랜톡 잔여 포인트 {{ kakao['point'] }}P<br>
                    <router-link to="" class="text-brand">충전하기</router-link>
                </p>
                <div class="flex justify-between">
                    <router-link to="/plantalk/res" class="w-9/10 text-xm">
                        <GrayBox>
                            <h5 class="font-bold">예약된 알림</h5>
                            <div class="flex justify-between items-center">
                                <p class="text-zinc-400">상세보기</p>
                                <IconNext class="fill-neutral-300" />
                            </div>
                        </GrayBox>
                    </router-link>
                    <router-link to="/plantalk/send" class="w-9/10 text-xm">
                        <GrayBox>
                            <h5 class="font-bold">발송 메시지보기</h5>
                            <div class="flex justify-between items-center">
                                <p class="text-zinc-400">
                                    오늘
                                    <span class="text-brand">{{ kakao['sendCnt'] }}건</span>
                                </p>
                                <IconNext class="fill-neutral-300" />
                            </div>
                        </GrayBox>
                    </router-link>
                </div>
            </div>

            <h5 class="font-bold text-sm text-zinc-700 mt-6">알림</h5>
            <template v-for="(item, index) in kakao['list']" :key="index">
                <TalkList :title="item['kdNm']" :message="item['descrip']" :useYn="item['useYn']" @getDetail="router.push({ path : '/plantalk/detail', query : { cd : item['kdCd'] } })"/>
            </template>
        </div>
    </div>
    <FooterVue />
</template>

<script setup>
import GrayBox from "@/components/box/GrayBox.vue";
import IconNext from "@/assets/svgs/IconNext.vue";
import FooterVue from "@/views/common/FooterVue.vue";
import TalkList from "@/components/list/TalkList.vue";

import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store  = useStore();
const router = useRouter();
const kakao  = computed(() => store.state.kakao);

console.log(kakao.value);

// const getResult = () => {
//     store.dispatch('kakao/getKakao');
// }

onMounted(() => {
    store.dispatch('kakao/getKakao', { ceCd : store.state.login.ceCd });
});
</script>

<style scoped>

</style>