import axios from "axios";
import store from '@/store';
import { getAxiosData, getCommas } from '@/assets/js/function';
/**
 * @description 공장 관련 vuex 모듈
 */
const factoryModule = {
    namespaced : true, 
    state : {
        sys : {
            list   : [],
            info   : getSysInfo(),
            detail : getSysDetail(),
            itemSearch : {
                itemNm : ''
            },
            itemList   : [],
            itemInfo   : getSysItemInfo()
        },
        out : {
            list       : [],
            info       : getOutInfo(),
            itemCnt    : 0,
            itemSearch : {
                instCd : '',
                itemNm : '',
                start  : 0
            },
            itemList   : [],
            itemInfo   : getOutItemInfo()
        }
    },
    mutations : {
        setList(state, { gb, list })
        {
            state[gb].list = list;
        },
        setInfo(state, { gb, info })
        {
            state[gb].info = info;
        },
        setDetail(state, detail)
        {
            state.sys.detail = detail;
        },
        setItemCnt(state, { gb, itemCnt })
        {
            state[gb].itemCnt = itemCnt;
        },
        setItemList(state, { gb, itemList })
        {
            state[gb].itemList = itemList;
        },
        setItemInfo(state, { gb, itemInfo })
        {
            state[gb].itemInfo = itemInfo;
        },
        setReset(state, { gb, data })
        {
            switch(data)
            {
                case 'info':
                    state[gb][data] = gb === 'out' ? getOutInfo() : getSysInfo();
                break;
                case 'itemInfo':
                    state[gb][data] = gb === 'out' ? getOutItemInfo() : getSysItemInfo();
                break;
            }
        }
    },
    actions : {
        async getList(context, params)
        {
            try
            {
                const res = await getAxiosData('https://data.planorder.kr/factory/getList', params);

                context.commit('setList', { gb: 'sys', list: res.data['sys'] });
                context.commit('setList', { gb: 'out', list: res.data['out'] });

                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        },
        async getSysFactorySearch(context, params)
        {
            try
            {
                const token     = store.state.login.token;
                const instance  = axios.create({
                    withCredentials : true,
                    headers         : {
                        'Authorization' : 'Bearer ' + token
                    }
                });

                const res = await instance.post('https://po-data.plansys.kr/v1/center/biz/purcBizInfo/getFacInfo', params);

                const info = {
                    compCd      : params['faCd'],
                    faCd        : params['faCd'],
                    faNm        : res.data['data']['faNm'],
                    item        : res.data['data']['item'],
                    imgUrl      : res.data['data']['imgUrl'],
                    person      : res.data['data']['person'],
                    tel         : res.data['data']['tel'],
                    zip         : res.data['data']['zip'],
                    addr        : res.data['data']['addr'],
                    addrDetail  : res.data['data']['addrDetail']
                }

                context.commit('setInfo', { gb: 'sys', info: info });
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        },
        async getSysFactoryRequest(context, params)
        {
            try
            {
                const res = await getAxiosData('https://data.planorder.kr/factory/getSysFactoryRequest', params);

                if(res['status'] === 200)
                {
                    const token     = store.state.login.token;
                    const instance  = axios.create({
                        headers         : {
                            'Content-Type'  : 'application/json',
                            'Authorization' : 'Bearer ' + token
                        }
                    });

                    const apsParams = {
                        'ceCd'      :  res.data['ceCd'],
                        'faCd'      :  res.data['faCd'],
                        'userCd'    :  res.data['userCd']
                    };

                    await instance.post('https://po-data.plansys.kr/v1/center/biz/purcBizInfo/insertData', JSON.stringify(apsParams));
                }
                
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.error(e);
                if(e.response.data.status === 409)
                {
                    return { result: false, msg: e.response.data.data };
                }
                else
                {
                    return { result: false, msg: 'fail' };
                }
            }
        },
        async getSysFactoryDetail(context, params)
        {
            try
            {
                const res = await getAxiosData('https://data.planorder.kr/factory/getSysFactoryDetail', params);

                if(res.status === 200)
                {
                    const token     = store.state.login.token;
                    const instance  = axios.create({
                        headers         : {
                            'Content-Type'  : 'application/json',
                            'Authorization' : 'Bearer ' + token
                        }
                    });

                    const apsParams = {
                        'ceCd'      :  res.data['ceCd'],
                        'faCd'      :  res.data['faCd']
                    };

                    const result = await instance.post('https://po-data.plansys.kr/v1/center/biz/onlineFac/getFacList', JSON.stringify(apsParams));
                    const data   = result['data']['data'];

                    const detail = {
                        faNm     : data['faNm'],
                        imgUrl   : data['filePath'],
                        infoList : [
                            { title: "혜택", content: "" },
                            { title: "전화번호", content: data['tel'], background: true },
                            { title: "영업시간", content: "" },
                            { title: "주소", content: data['addr']+' '+data['addrDetail'] },
                            { title: "계좌", content: data['bankNm']+' '+data['account'] }
                        ],
                        countList : [
                            { label: "주문", count: data['orderCnt'] },
                            { label: "생산", count: data['prodCnt'] },
                            { label: "생산완료", count: data['prodCompCnt'] },
                            { label: "출고", count: data['outCnt'] }
                        ],
                        amtList : [
                            {name : '1', title: '이월 잔액', amt: data['preAmt']},
                            {name : '2', title: '이달 매입금', amt: data['purcThisAmt']},
                            {name : '3', title: '이달 수정 / 반품', amt: data['reAmt']},
                            {name : '4', title: '이달 결제', amt: data['payAmt']}
                        ],
                        totalAmt   : data['totalPayAmt'],
                        noticeList : [],
                        itemCnt    : data['totalProdCnt']
                    }

                    context.commit('setDetail', detail);
                }
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.log(e)
                return { result: false, msg: 'fail' };
            }
        },
        async getSysItemList(context, params)
        {
            try
            {
                const res = await getAxiosData('https://data.planorder.kr/factory/getSysItemList', params);

                if(res.status === 200)
                {
                    const token     = store.state.login.token;
                    const instance  = axios.create({
                        headers         : {
                            'Content-Type'  : 'application/json',
                            'Authorization' : 'Bearer ' + token
                        }
                    });

                    const apsParams = {
                        'faCd'      :  res.data['faCd'],
                        'ceCd'      :  res.data['ceCd'],
                        'itemNm'    :  params['itemNm']
                    };

                    const result = await instance.post('https://po-data.plansys.kr/v1/center/biz/onlineFac/getFacItemList', JSON.stringify(apsParams));

                    const data = result.data['data'].map(item => {
                        const amt = res.data['amtList'].find(amt => amt.itemCd === item.itemCd);
                        
                        return {
                            itemCd  : item.itemCd,
                            itemNm  : item.itemNm,
                            list    : [
                                { label: "단위", content: item.size+item.unitNm },
                                { label: "판매", content: amt ? getCommas(Number(amt['saleAmt'])) : getCommas(Number(item.clientAmt)) },
                                { label: "매입", content: getCommas(Number(item.saleAmt)) }
                            ]
                        }
                    });

                    context.commit('setItemList', { gb: 'sys', itemList: data });
                }
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        },
        async getSysItemInfo(context, params)
        {
            try
            {
                const res = await getAxiosData('https://data.planorder.kr/factory/getSysItemInfo', params);

                if(res.status === 200)
                {
                    const token     = store.state.login.token;
                    const instance  = axios.create({
                        headers         : {
                            'Content-Type'  : 'application/json',
                            'Authorization' : 'Bearer ' + token
                        }
                    });

                    const apsParams = {
                        'faCd'      :  res.data['faCd'],
                        'ceCd'      :  res.data['ceCd'],
                        'itemCd'    :  params['itemCd']
                    };

                    const result = await instance.post('https://po-data.plansys.kr/v1/center/biz/onlineFac/getFacItemInfo', JSON.stringify(apsParams));
                    const data   = result.data['data'];

                    const info   = {
                        itemCd      : data['itemCd'],
                        itemNm      : data['itemNm'],
                        icList      : data['icList'],
                        size        : data['size'],
                        unit        : data['unit'],
                        baseHeight  : data['baseHeight'],
                        pok         : data['pok'],
                        purcAmt     : data['saleAmt'],
                        saleAmt     : Number(res.data['amt']) === 0 ? data['clientAmt'] : Number(res.data['amt'])
                    };

                    context.commit('setItemInfo', { gb: 'sys', itemInfo: info });
                }
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        },
        async getOutFactoryInfo(context, params)
        {
            context.commit('factory/setReset', { gb : 'out', data : 'info' });

            try
            {
                const res = await getAxiosData('https://data.planorder.kr/factory/getOutFactoryInfo', params);

                context.commit('setInfo', { gb: 'out', info: res.data['info'] });
                context.commit('setItemCnt', { gb: 'out', itemCnt : res.data['itemCnt'] });

                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        },
        async getOutFactoryItemList(context, params)
        {
            try
            {
                const res = await getAxiosData('https://data.planorder.kr/factory/getOutFactoryItemList', params);

                const data = res.data.map(item => ({
                    instCd  : item.instCd,
                    itemCd  : item.itemCd,
                    itemNm  : item.itemNm,
                    list    : [
                        { label: "단위", content: item.size+item.codeNm },
                        { label: "판매", content: getCommas(Number(item.saleAmt)) },
                        { label: "매입", content: getCommas(Number(item.purcAmt)) }
                    ]
                }));

                context.commit('setItemList', { gb: 'out', itemList: data });

                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        },
        async getOutFactoryItemInfo(context, params)
        {
            try
            {
                const res = await getAxiosData('https://data.planorder.kr/factory/getOutFactoryItemInfo', params);

                context.commit('setItemInfo', { gb: 'out', itemInfo: res.data });
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        },
        async getOutFactoryItemDelete(content, params)
        {
            try
            {
                await getAxiosData('https://data.planorder.kr/factory/getOutFactoryItemDelete', params);
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        },
        async getOutFactoryItemSubDelete(content, params)
        {
            try
            {
                await getAxiosData('https://data.planorder.kr/factory/getOutFactoryItemSubDelete', params);
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        }
    }
};

/**
 * @description 시스템 공장 연동 정보
 */
function getSysInfo()
{
    return {
        compCd      : '',
        faCd        : '',
        faNm        : '',
        item        : '',
        imgUrl      : '',
        person      : '',
        tel         : '',
        zip         : '',
        addr        : '',
        addrDetail  : ''
    }
}

/**
 * @description 시스템 공장 연동 상세 정보
 */
function getSysDetail()
{
    return {
        faNm     : '',
        imgUrl   : '',
        infoList : [
            { title: "혜택", content: "" },
            { title: "전화번호", content: "", background: true },
            { title: "영업시간", content: "" },
            { title: "주소", content: "" },
            { title: "계좌", content: "" }
        ],
        countList : [
            { label: "주문", count: 0  },
            { label: "생산", count: 0 },
            { label: "생산완료", count: 0 },
            { label: "출고", count: 0 }
        ],
        amtList : [
            {name : '1', title: '이월 잔액', amt: 0},
            {name : '2', title: '이달 매입금', amt: 0},
            {name : '3', title: '이달 수정 / 반품', amt: 0},
            {name : '4', title: '이달 결제', amt: 0}
        ],
        totalAmt   : 0,
        noticeList : [],
        itemCnt    : 0
    }
}

/**
 * @description 시스템 공장 제품 정보
 */
function getSysItemInfo()
{
    return {
        itemCd      : '',
        itemNm      : '',
        icList      : [
            { icNm : '' }
        ],
        size        : 0,
        unit        : '',
        baseHeight  : 0,
        pok         : 0,
        purcAmt     : 0,
        saleAmt     : 0
    }
}

/**
 * @description 외주공장 정보
 */
function getOutInfo()
{
    return {
        faNm        : '',
        tel         : '',
        zip         : '',
        addr        : '',
        addrDetail  : '',
        accInfo     : '',
        kakaoTel    : '',
        memo        : ''
    }
}

/**
 * @description 외주공장 제품 정보
 */
function getOutItemInfo()
{
    return {
        itemCd      : '',
        itemNm      : '',
        icList      : [
            { icNm : '', gb : 'I', del : 'N' }
        ],
        size        : 0,
        unit        : '',
        baseHeight  : 0,
        pok         : 0,
        purcAmt     : 0,
        saleAmt     : 0
    }
}

export default factoryModule;