<template>
    <ModalBox title="일별 분석">
        <div class="flex items-baseline space-y-3.5 mt-3.5 mb-5 justify-between">
            <AccInfo :saleAmt="day['amt']['saleAmt']" :purcAmt="day['amt']['purcAmt']" :margin="day['amt']['margin']"/>
        </div>
        <GrayBar class="my-2.5"/>
        <div>
            <LineLeftTitle :title="getDate" />
            <AccountList v-for="(item, index) in day['list']" :key="index" 
                :name="item['clientNm']" :process="item['stNm']" :address="item['addr']+' '+item['addrDetail']"
                :saleAmt="item['saleAmt']" :purcAmt="item['purcAmt']" :rev="item['rev']" />
        </div>
    </ModalBox>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox.vue";
import AccInfo from "@/views/acc/AccInfo.vue";
import LineLeftTitle from "@/components/common/LineLeftTitle.vue";
import AccountList from "@/views/acc/AccountList.vue";
import GrayBar from "@/components/common/GrayBar.vue";

import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { getConvertDate } from '@/assets/js/function';

const store  = useStore();
const route  = useRoute();

const day = computed(() => store.state.acc.day);
const dt  = route.query.dt;

const getDate = computed(() => {
    return getConvertDate(new Date(dt), 'mm%dd%w%2')
});

const getAccDay = () => {
    const params = {
        ceCd    : store.state.login.ceCd,
        dt      : dt
    };

    store.dispatch('acc/getAccDay', params);
}

onMounted(() => {
    getAccDay();
});
</script>

<style scoped>

</style>