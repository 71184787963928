<template>
    <header class="fixed xl:hidden top-0 left-0 z-10 w-full bg-white flex items-center px-4 py-3">
        <img v-if="isHomePage" src="@/assets/images/icon-profile.png" alt="프로필" title="프로필" class="w-7" />
        <img v-else @click="goToPreviousPage" src="@/assets/images/btn-prev.svg" alt="뒤로가기" title="뒤로가기" />
        <h2 class="text-center font-bold text-sm w-full leading-7">{{ pageTitle }}</h2>
        <img src="" alt="" title=""/>
    </header>

    <header class="hidden xl:block w-64 min-h-screen bg-header text-white">
        <h2 class="text-center my-6 font-bold text-lg">
            <router-link to="/">디자인윈도우</router-link>
        </h2>
        <ul class="py-4 px-5 w-full space-y-4">
            <li>
                <router-link class="flex items-center" to="/calendar">
                    <FontAwesomeIcon :icon="['far','calendar']" class="mr-2 w-5" />
                    <p>캘린더</p>
                </router-link>
            </li>
            <li>
                <router-link class="flex items-center" to="/customer/list">
                    <FontAwesomeIcon :icon="['fas','user-group']" class="mr-2 w-5" />
                    <p>고객관리</p>
                </router-link>
            </li>
            <li>
                <router-link class="flex items-center" to="/factory/list">
                    <FontAwesomeIcon :icon="['fas','wifi']" class="mr-2 w-5" />
                    <p>공장</p>
                </router-link>
            </li>
            <li>
                <router-link class="flex items-center" to="/acc">
                    <FontAwesomeIcon :icon="['fas','chart-simple']" class="mr-2 w-5" />
                    <p>회계</p>
                </router-link>
            </li>
            <li>
                <router-link class="flex items-center" to="/average">
                    <FontAwesomeIcon :icon="['fas','chart-line']" class="mr-2 w-5" />
                    <p>통계</p>
                </router-link>
            </li>
            <li>
                <router-link class="flex items-center" to="/plantalk">
                    <FontAwesomeIcon :icon="['far','message']" class="mr-2 w-5" />
                    <p>플랜톡</p>
                </router-link>
            </li>
            <li>
                <router-link class="flex items-center" to="">
                    <FontAwesomeIcon :icon="['far','envelope']" class="mr-2 w-5" />
                    <p>단체 메시지</p>
                </router-link>
            </li>
            <li>
                <router-link class="flex items-center" to="/setting">
                    <FontAwesomeIcon :icon="['fas','gear']" class="mr-2 w-5" />
                    <p>샵 설정</p>
                </router-link>
            </li>
        </ul>
    </header>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "HeaderVue",
    components: {FontAwesomeIcon},
    data() {
        return {
            pageTitle : "디자인윈도우",
            isHomePage: true,
        };
    },
    methods: {
        goToPreviousPage() {
            window.history.back();
        },
        updatePageTitle(title) {
            this.pageTitle = title;
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.isHomePage = to.path === "/";
            vm.updatePageTitle(to.meta.title || "디자인윈도우");
        });
    },
    watch: {
        $route(to) {
            this.isHomePage = to.path === "/";
            this.updatePageTitle(to.meta.title || "디자인윈도우");
        },
    },
};
</script>

<style scoped>

</style>