<template>
    <div class="w-full">
        <div class="border-b border-neutral-200 py-6 space-y-2" @click="toggleColorBox">
            <h2 class="font-bold">
                {{ title }}
                <span v-if="noUsed" class="text-red-500">(주문불가)</span>
            </h2>
            <p class="text-sm text-gray-400">{{ product }}</p>
            <p class="text-sm">
                <span class="mr-3">{{ unit }}</span>
                <span>{{ price }}원</span>
            </p>
        </div>
        <div v-if="showColorBox" @click="getModal">
            <div v-for="(color, index) in colors" :key="index">
                <input type="radio" :id="color.id" :name="color.name" class="peer/draft hidden"
                       :disabled="color.disabled"/>
                <label :for="color.id"
                       class="text-center border-b border-neutral-300 bg-zinc-50 py-4 text-sm block peer-checked/draft:text-sky-500 peer-checked/draft:bg-zinc-100"
                       :class="{'text-red-500': color.disabled === true}" @click="getChoice(color.cd)">
                    {{ color.label }}
                    <span v-if="color.disabled"> (주문불가)</span>
                </label>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';

defineProps({
    title       : String,
    product     : String,
    unit        : String,
    price       : String,
    colors      : Array,
    noUsed      : Boolean,
});

const emit = defineEmits(['show']);

const showColorBox = ref(false);

const toggleColorBox = () => {
    showColorBox.value = !showColorBox.value;
}

const getModal = () => {
    emit('show');
}

const getChoice = (cd) => {
    emit('choice', cd);
}
</script>

<style scoped>
</style>