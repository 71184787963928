<template>
    <ModalBox :title="info['faNm']">
        <InfoCard title="공장 정보" :info="infoList" />
        <CalculateCard title="매입 거래원장" totalTitle="총 결제 금액" class="mt-6" borderTop :calcs="calcList" />
        <div class="absolute bg-white w-full bottom-0 left-0 right-0 mx-auto shadow-custom p-4 pb-3.5">
            <div class="flex justify-between items-center font-bold mb-4">
                <h2>총 제품</h2>
                <h2 class="text-brand">{{ itemCnt }}개</h2>
            </div>
            <BaseButton buttonText="제품 설정하기" blueButton @click="router.push({ path : '/factory/outProduct' , query : { cd : route.query.cd }})"/>
        </div>
    </ModalBox>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox.vue";
import InfoCard from "@/components/card/InfoCard.vue";
import CalculateCard from "@/components/card/CalculateCard.vue";
import BaseButton from "@/components/button/BaseButton.vue";

import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

const store  = useStore();
const route  = useRoute();
const router = useRouter();

const info      = computed(() => store.state.factory.out.info);
const itemCnt   = computed(() => store.state.factory.out.itemCnt);
const infoList  = computed(() => {
    return [
        { title: "공장명", content: info.value['faNm'] },
        { title: "전화번호", content: info.value['tel'], background: true },
        { title: "주소", content: info.value['addr']+' '+info.value['addrDetail'] },
        { title: "계좌", content: info.value['accInfo'] },
        { title: "메모", content: info.value['memo'] }
    ]
});

const calcList = [
    { title: "이달 매입금" }
];

onMounted(() => {
    store.dispatch('factory/getOutFactoryInfo', { fcCd : route.query.cd });
});

</script>

<style scoped>

</style>