import { getAxiosData } from '@/assets/js/function';
/**
 * @description 문자 메세지 관련 vuex 모듈
 */
const msgModule = {
    namespaced : true, 
    state : {
        info        : getMsgInfo(),
        clientList  : []
    },
    mutations : {
        setList(state, list)
        {
            state.list = list;
        },
        setInfo(state, info)
        {
            state.info = info;
        },
        setReset(state)
        {
            state.info          = getMsgInfo();
            state.clientList    = [];
        }
    },
    actions : {
        async getInfo(context, data)
        {
            try
            {
                const res  = await getAxiosData('https://data.planorder.kr/msg/getInfo', data);
    
                context.commit('setInfo', res.data);
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.log(e);
                return { result: false, msg: 'error' };
            }
        }
    }
};

function getMsgInfo()
{
    return {
        title     : '',
        contents  : ''
    }
}
  
export default msgModule;