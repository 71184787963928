<template>
    <div class="relative">
        <input :type="type" :id="id" :name="name" :value="value" :placeholder="placeholder" :autocomplete="'off'"
               class="pt-3 pb-2.5 pl-3.5 border w-full rounded-sm text-xs duration-200 disabled:bg-zinc-100"
               :class="[{'border-zinc-200': !error, 'border-red-600': error}, {'pr-8': iconSearch, 'pr-3.5': !iconSearch}]" :readonly="readonly" :disabled="disabled"/>
        <FontAwesomeIcon :icon="['fas', 'magnifying-glass']" class="absolute right-3 vertical-h text-neutral-500" v-if="iconSearch" @click="getClickIcon"/>
        <label :for="id" class="absolute -top-2 left-1.5 text-xm text-gray-400 bg-white px-1">{{ label }}<span class="text-red-500" v-if="important">*</span></label>
        <p class="text-xm text-red-700 mt-1" v-if="error">{{ helperText }}</p>
    </div>
</template>

<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { defineProps, defineEmits } from "vue";

const emit = defineEmits(['getClickIcon']);

defineProps({
    label      : String,
    type       : String,
    id         : String,
    name       : String,
    value      : String,
    placeholder: String,
    helperText : String,
    readonly   : Boolean,
    disabled   : Boolean,
    iconSearch : {
        type   : Boolean,
        default: false
    },
    error      : {
        type   : Boolean,
        default: false,
    },
    important: Boolean
});

const getClickIcon = () => {
    emit('getClickIcon');
}
</script>

<style lang="scss">
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>