<template>
    <div class="container mx-auto">
        <div class="w-11/12 mx-auto space-y-5">
            <div class="flex p-3.5 rounded border space-x-2.5 mt-2.5">
                <img src="@/assets/images/icon-profile.png" class="w-8 h-8" alt="profile" title="profile" />
                <div>
                    <h2 class="font-bold text-base">디자인윈도우</h2>
                    <p class="text-brand text-[0.5rem] font-bold -mt-1">대표</p>
                </div>
            </div>
            <ul class="bg-zinc-50 rounded border">
                <li class="flex justify-between items-center py-4 px-3">
                    <p class="text-xs">요금제<span class="rounded-xl py-1 px-2 bg-brand text-[0.5rem] text-white ml-3">스탠다드</span></p>
                    <p class="text-brand text-xm">사용 가능 기간 2022년 02월 30일까지</p>
                </li>
                <li class="flex justify-between items-center py-4 px-3 border-y">
                    <p class="text-xs">플랜 포인트</p>
                    <p class="text-brand text-xm">2400p</p>
                </li>
                <li class="flex justify-between items-center py-4 px-3">
                    <p class="text-xs">샵 멤버 인원</p>
                    <p class="text-brand text-xm">1/3 사용중</p>
                </li>
            </ul>
            <div>
                <h2 class="bg-zinc-50 rounded border font-bold text-sm py-4 px-3">정보설정</h2>
                <SettingMoreList title="매장 설정" />
                <SettingMoreList title="플랜톡" />
                <SettingMoreList title="멤버관리" />
                <SettingMoreList title="할인 설정" />
                <SettingMoreList title="시공시간 설정" @click="router.push({ path : '/setting/timeSetting' })"/>
                <SettingMoreList title="고정비용 등록" @click="router.push({ path : '/setting/staticPay' })"/>
                <SettingMoreList title="푸쉬 알림" />
                <SettingMoreList title="회원정보 변경" />
                <SettingMoreList title="사업자 인증" />
                <SettingMoreList title="계약서 관리" />
                <h2 class="bg-zinc-50 rounded border font-bold text-sm py-4 px-3">플랜오더 약관</h2>
                <SettingMoreList title="사용약관" />
                <SettingMoreList title="개인정보 처리방침" />
                <SettingMoreList title="개인정보 수집 이용 동의서" />
            </div>
            <p class="text-center text-xs text-neutral-300">version 1.1.0.8c</p>
            <BaseButton buttonText="로그아웃" greyButton @click="getLogout"/>
        </div>
        <ul class="text-center space-x-8 py-5 mt-12 border-y w-11/12 mx-auto">
            <li class="inline-block text-xm">
                <router-link to="">회사소개</router-link>
            </li>
            <li class="inline-block text-xm">
                <router-link to="">고객센터</router-link>
            </li>
            <li class="inline-block text-xm">
                <router-link to="">공지사항</router-link>
            </li>
            <li class="inline-block text-xm">
                <router-link to="">사용문의</router-link>
            </li>
        </ul>
    </div>
    <FooterVue />
</template>

<script setup>
import FooterVue from "@/views/common/FooterVue.vue";
import SettingMoreList from "@/views/setting/SettingMoreList.vue";
import BaseButton from "@/components/button/BaseButton.vue";


// import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store   = useStore();
const router  = useRouter();

const getLogout = () => {
    store.dispatch('login/logout');
    router.push({ path : '/login' });
}
</script>

<style scoped>

</style>