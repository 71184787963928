<template>
    <CalendarBox />
    <ScheduleModal />
    <BottomBox v-if="false">
        <div class="space-y-6">
            <CheckedRadio :radios="radioLists" name="checked" />
        </div>
    </BottomBox>
    <BottomBox v-if="false">
        <div class="space-y-6">
            <CheckedRadio :radios="radioLists2" name="checked" />
        </div>
    </BottomBox>
    <DetailModal v-if="false" />
    <PersonEdit v-if="false" />
</template>

<script>
import ScheduleModal from "@/views/calendar/ScheduleModal.vue";
import BottomBox from "@/components/modal/BottomBox.vue";
import CheckedRadio from "@/components/input/CheckedRadio.vue";
import DetailModal from "@/views/calendar/DetailModal.vue";
import PersonEdit from "@/views/calendar/PersonEdit.vue";
import CalendarBox from "@/views/calendar/CalendarBox.vue";

export default {
    name: "CalendarView",
    components: {
        CalendarBox,
        PersonEdit,
        DetailModal,
        CheckedRadio,
        BottomBox,
        ScheduleModal,
    },
    data() {
        return {
            /*calendarOptions: {
                plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin ],
                initialView: 'timeGridDay',
                headerToolbar: {
                    left: 'prev,next',
                    center: 'title',
                    right: 'timeGridDay'
                },
                locale: "ko"
            },*/
            radioLists: [
                { id: 'test1', label: '견적' },
                { id: 'test2', label: '견적 취소' },
            ],
            radioLists2: [
                { id: 'test3', label: '시공대기' },
                { id: 'test4', label: '시공완료' },
            ]
        }
    }
}
</script>

<style lang="scss">
.fc{
    height: calc(100svh - 52px);
    .fc-timegrid-slot-label-cushion{
        font-size: .56rem;
        color: #949494;
    }

    tbody{
        .fc-scrollgrid-section{
            display: none;
            &.fc-scrollgrid-section-liquid{
                display: table-row;
            }
        }
    }
}

.fc-theme-standard td, .fc-theme-standard th{
    border: 0;
}
</style>