<template>
    <div class="p-4 rounded bg-white border border-gray-200">
        <div class="flex items-center mb-3">
            <h4 class="text-sm text-neutral-700 w-2/3" :class="{'font-bold': showUnit, 'font-normal': !showUnit}">{{ title }}</h4>
            <p v-if="showUnit" class="text-right font-bold w-1/3 text-blue-500 text-sm">{{ unit }}</p>
            <div v-if="showMore" class="text-right w-1/3 cursor-pointer text-slate-300 flex flex-row-reverse items-center">
                <FontAwesomeIcon :icon="['fas', 'caret-down']" class="ml-2.5 text-slate-400 text-sm" />
                <p class="text-xs" @click="ToggleMore">더보기</p>
            </div>
        </div>
        <div v-if="showMoreToggle">
            <slot></slot>
        </div>
        <div class="space-y-2.5 mb-3 pb-3 border-b border-dotted border-gray-200"
             :class="{'border-t pt-3' : borderTop}">
            <template v-for="(calc, index) in calcs" :key="index">
                <dl v-if="Number(calc.amt) !== 0 || calc.title === '제품 금액'" class="flex items-center">
                    <dt class="text-neutral-500 text-sm">{{ calc.title }}</dt>
                    <dd class="text-right font-bold text-base" :class="{
                        'text-red-500' : calc.red,
                        'text-blue-500': calc.blue,
                        'text-neutral-700': !calc.red && !calc.blue
                    }">{{ getAmt(calc.amt) }}<span class="font-normal text-xs">원</span>
                    </dd>
                </dl>
            </template>
        </div>
        <div class="flex text-neutral-700 font-bold items-center">
            <h3 class="w-1/2 text-lg">{{ totalTitle }}</h3>
            <p class="text-right font-bold w-1/2 text-blue-500 text-xl">
                {{ getAmt(totalAmt) }}<span class="font-normal text-xs">원</span>
            </p>
        </div>
    </div>
</template>

<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ref } from 'vue';
import { defineProps } from 'vue';
import { getCommas } from "@/assets/js/function";

defineProps({
    title       : String,
    unit        : String,
    showUnit    : Boolean,
    showMore    : Boolean,
    calcs       : Array,
    totalTitle  : String,
    totalAmt    : Number,
    borderTop   : Boolean
});

const showMoreToggle = ref(false);

const ToggleMore = () => {
    showMoreToggle.value = !showMoreToggle.value;
}

const getAmt = (amt) => {
    return getCommas(Number(amt));
};
</script>

<style lang="scss" scoped>
dl {
    dt {
        width: 70%;
    }

    dd {
        width: 30%;
    }
}
</style>