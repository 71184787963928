<template>
    <div class="flex justify-between items-baseline py-5">
        <div class="w-7/12 space-y-1.5">
            <div class="flex items-center">
                <h3 class="font-bold text-sm w-16 text-ellipsis overflow-hidden whitespace-nowrap">{{ name }}</h3>
                <SpanTag :spanText="process" isSky />
            </div>
            <p class="text-xm text-zinc-400 text-ellipsis overflow-hidden whitespace-nowrap">{{ address }}</p>
        </div>
        <div class="w-4/12 space-y-1.5">
            <dl class="flex justify-between items-center">
                <dt class="text-neutral-500 text-xs">매출</dt>
                <dd class="text-neutral-700 text-sm">{{ getAmt(saleAmt) }}원</dd>
            </dl>
            <dl class="flex justify-between items-center">
                <dt class="text-neutral-500 text-xs">매입</dt>
                <dd class="text-neutral-700 text-sm">{{ getAmt(purcAmt) }}원</dd>
            </dl>
            <dl class="flex justify-between items-center">
                <dt class="text-neutral-500 text-xs">수익</dt>
                <dd class="text-green-500 text-sm">{{ getAmt(rev) }}원</dd>
                <!-- text-neutral-700 / text-green-500 / text-red-500 -->
            </dl>
        </div>
    </div>
</template>

<script setup>
import SpanTag from "@/components/common/SpanTag.vue";

import { defineProps } from 'vue';
import { getCommas } from "@/assets/js/function";

defineProps({
    name        : String,
    process     : String,
    address     : String,
    saleAmt     : String,
    purcAmt     : String,
    rev         : String
});

const getAmt = (amt) => {
    return getCommas(Number(amt));
}
</script>

<style scoped>

</style>