<template>
    <div class="w-full bg-neutral-50 relative flex items-center xl:h-calc">
        <div class="block container mx-auto bg-white xl:rounded max-w-[60rem] xl:shadow-custom">
            <ul class="flex items-center px-4 py-1.5 space-x-6">
                <li v-for="(tab, index) in tabs" :key="index"
                    class="text-gray-400 text-sm py-1 duration-200 border-b-2 border-white cursor-pointer" @click="selectTab(index)">
                    {{ tab.title }}
                </li>
            </ul>
            <div class="section overflow-y-scroll scroll-none px-4 pb-16" :class="{'height' : showFixedButton}">
                <slot :name="selectedTab"></slot>
            </div>
            <div class="px-4 pt-2 pb-3.5 shadow-custom flex space-x-2" v-if="showFixedButton">
                <BaseButton :buttonText="buttonGreyText" greyButton v-if="showGreyButton"/>
                <BaseButton :buttonText="buttonText" v-if="showButton" blueButton @click="getButtonClick"/>
            </div>
        </div>
    </div>
</template>

<!--<template v-slot:0>테스트</template>
<template v-slot:1>테스트2</template>-->

<script>
import BaseButton from "@/components/button/BaseButton.vue";

export default {
    name      : "TabModalBox",
    components: {BaseButton},
    props     : {
        title          : String,
        buttonText     : String,
        blueButtonText : String,
        buttonGreyText : String,
        showBlueButton : Boolean,
        showGreyButton : Boolean,
        showFixedButton: Boolean,
        showButton     : Boolean,
        tabs           : Array,
    },
    methods   : {
        getAnotherBtnClick() {
            this.$emit("anotherBtnClick");
        },
        getClose() {
            this.$emit("close");
        },
        getButtonClick() {
            this.$emit("btnClick");
        },
        selectTab(index) {
            this.selectedTab = index;
        }
    },
    data() {
        return {
            selectedTab: 0,
        }
    }
}
</script>

<style lang="scss" scoped>
ul {
    li {
        &.active, &:hover {
            color: #404040;
            font-weight: 700;
            border-color: $brand;
        }
    }
}

.section {
    height: 73vh;
}

@media screen and (max-width: 1280px){
.section {
    height: calc(100vh - 90px);
    overflow: scroll;

    &.height {
        height: calc(100vh - 154px);
    }
}
}
</style>