<template>
    <ModalBox title="고객 선택" noPadding @close="getClose">
        <div>
            <div class="flex py-2.5 px-4">
                <SelectBox :options="clientStat" class="w-1/3 mr-3" @change="getStChange"/>
                <SearchBox :id="'clientNm'" placeholder="이름, 주소, 전화번호 검색" :class="'w-2/3'" @keyup.enter="getSearch"/>
            </div>
            <div>
                <ColorChoiceList :clients="client.list" />
            </div>
        </div>
        <div class="absolute bottom-0 left-0 right-0 mx-auto shadow-custom pt-2 pb-3.5 px-4 bg-white">
            <h2 class="text-brand font-bold text-base mt-2 mb-4">{{ clientList.length }}명 선택</h2>
            <BaseButton buttonText="선택" blueButton @click="getClose"/>
        </div>
    </ModalBox>
</template>

<script setup>
import { onMounted, computed, defineEmits } from 'vue';
import { useStore } from 'vuex';

import SelectBox from "@/components/input/SelectBox.vue";
import SearchBox from "@/components/input/SearchBox.vue";
import ColorChoiceList from "@/components/list/ColorChoiceList.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import ModalBox from "@/components/modal/ModalBox.vue";

const store  = useStore();

const clientStat = computed(() => store.state.data.clientStat);
const client     = computed(() => store.state.client);
const clientList = computed(() => store.state.msg.clientList);

const emit = defineEmits(['getClose']);

const getStChange = (event) => {
    const value = event.target.value;

    client.value['search']['stCd'] = value;

    getList();
}

const getSearch = (event) => {
    const value = event.target.value;

    client.value['search']['text'] = value;

    getList();
}

const getList = () => {
    const params = {
        ceCd        : store.state.login.ceCd,
        stCd        : client.value['search']['stCd'],
        search      : client.value['search']['text'],
        start       : client.value['search']['start']
    };

    store.dispatch('client/getList', params);
};

const getClose = () => {
    emit('getClose');
}

onMounted(() => {
    getList();
});
</script>

<style scoped>

</style>