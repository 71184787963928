<template>
    <div class="popup w-full h-screen items-end fixed top-0 left-0 right-0 mx-auto z-30 bg-black/60" :class="{ active : isActive }" @click="getClose">
        <div class="popup-box duration-200 mx-auto rounded-t w-11/12 h-4/5 bg-white" :class="{ active : isActive }" @click.stop>
            <div class="flex pt-3.5 pb-3 px-4 items-center justify-between relative">
                <img src="@/assets/images/btn-prev.svg" alt="이전" title="이전" @click="getClose"/>
                <h3 class="text-center w-full font-bold text-sm mt-0.5">담당자 수정</h3>
            </div>
            <div class="mx-4 h-full pb-32 overflow-y-scroll scroll-none">
                <div class="flex py-3.5 items-center justify-between">
                    <div class="flex space-x-2.5">
                        <img src="@/assets/images/icon-profile.png" class="w-8 h-8" alt="profile" title="profile" />
                        <div>
                            <h2 class="font-bold text-base">하현재</h2>
                            <p class="text-zinc-300 text-[0.5rem] font-bold -mt-1">대표</p>
                        </div>
                    </div>
                    <!-- v-if 혹은 class hidden으로 check 관리 -->
                    <FontAwesomeIcon class="text-brand text-lg" :icon="['fas', 'check']" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "PersonEdit",
    components: {FontAwesomeIcon},
    data() {
        return {
            isActive: true
        }
    },
    methods: {
        getClose() {
            this.isActive = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.popup {
    display: none;

    &.active {
        display: flex;
    }
}

.popup-box {
    transform: translateY(100%);

    &.active {
        transform: translateY(0);
    }
}
</style>