<template>
    <span class="py-1 px-2 rounded-xl font-bold text-xm"
          :class="{'text-teal-600 bg-teal-50': isTeal, 'text-orange-300 bg-orange-50': isYellow, 'text-brand bg-sky-50': isSky}">
        {{ spanText }}
    </span>
</template>

<script>
export default {
    name : "SpanTag",
    props: {
        spanText: String,
        isTeal  : Boolean,
        isYellow: Boolean,
        isSky   : Boolean,
    }
}
</script>

<style lang="scss" scoped>
span {
    &.blue {
        background-color: #E5F7FB;
        color: #6AB2BE;
    }

    &.orange {
        background-color: #FCF2E6;
        color: #DBB071;
    }
}
</style>