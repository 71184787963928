<template>
    <div class="w-full bg-neutral-100 border-t border-neutral-300 h-2"></div>
</template>

<script>
export default {
    name: "GrayBar"
}
</script>

<style scoped>

</style>