<template>
    <ModalBox title="시공시간 설정" showFixedButton showButton buttonText="시공시간 저장" @blueBtnClick="getTimeSettingCheck">
        <h3 class="font-bold text-sm text-neutral-700">기본시공 시간</h3>
        <p class="mt-4 pb-5 border-b text-xm text-neutral-400">견적서 작성 진행시 중복되는 기본서비스 (인테리어 자재) 시간을 차감하여 정확한 전체 시공시간 계산을
            도와주는 기능입니다.</p>
        <div class="flex justify-between items-center py-4">
            <label for="" class="text-sm text-zinc-500">커튼 (1개당)</label>
            <div class="border-b w-2/5 relative">
                <input type="text" id="curtainTime" :value="curtainTime" placeholder="0"
                    class="w-full text-right py-2 font-bold text-base pr-4" @input="getInputValSet"/>
                <span class="font-bold text-base absolute right-0 vertical-h">분</span>
            </div>
        </div>
        <div class="flex justify-between items-center py-4">
            <label for="" class="text-sm text-zinc-500">블라인드 (1개당)</label>
            <div class="border-b w-2/5 relative">
                <input type="number" id="blindTime" :value="blindTime" placeholder="0"
                    class="w-full text-right py-2 font-bold text-base pr-4" @input="getInputValSet"/>
                <span class="font-bold text-base absolute right-0 vertical-h">분</span>
            </div>
        </div>
    </ModalBox>
    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp" @getCancle="getCancle"/>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox.vue";
import AlertBox from "@/components/modal/AlertBox.vue";

import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { getAxiosData } from "@/assets/js/function";

const store  = useStore();
const router = useRouter();

const curtainTime = computed(() => store.state.timeSetting.curtainTime);
const blindTime   = computed(() => store.state.timeSetting.blindTime);

const resultYn  = ref('N');
const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
};

const getComp = () => {
    alertData.value['view'] = false;

    if(resultYn.value === 'Y')
    {
        getTimeSettingResult();
    }
};

const getCancle = () => {
    alertData.value['view'] = false;
};

const getInputValSet = () => {
    store.commit('timeSetting/setTime', { name : event.target.id, time : event.target.value });
}

const getTimeSettingCheck = () => {
    getAlertDataSet(true, '저장하기', '시공시간을 저장하시겠습니까?', true);
    resultYn.value = 'Y';
}

const getTimeSettingResult = () => {
    const params = {
        ceCd        : store.state.login.ceCd,
        userCd      : store.state.login.userCd,
        curtainTime : curtainTime.value,
        blindTime   : blindTime.value
    };

    getAxiosData('https://data.planorder.kr/timeSetting/getResult', params).then((res) => {
        if(res.status === 200)
        {
            router.go(-1);
        }
        else
        {
            resultYn.value = 'N';
            getAlertDataSet(true, '에러', '시공시간 저장에 실패하였습니다. 지속될 경우 관리자에게 문의하세요.', false);
        }
    });
}

onMounted(() => {
    const params = {
        ceCd : store.state.login.ceCd
    };

    store.dispatch('timeSetting/getTime', params);
});
</script>

<style scoped>

</style>