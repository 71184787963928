import { getAxiosData, getCardColumns, getConvertDate } from '@/assets/js/function';
/**
 * @description 견적서 vuex 모듈
 */
const estiMateModule = {
    namespaced : true, 
    state : {
        ceNm    : '',
        imgUrl  : '',
        headers : [],
        list    : [],
        payList : getPayList(),
        sizeYn  : 'N'
    },
    mutations : {
        setCeNm(state, ceNm)
        {
            state.ceNm = ceNm;
        },
        setHeaders(state, headers)
        {
            state.headers = headers;
        },
        setList(state, list)
        {
            state.list = list;
        },
        setItemAmt(state, { name, amt })
        {
            const item = state.payList.find(item => item.name === name);
            
            if(item)
            {
                item.amt = Number(amt);
            }
        },
        setPayAmt(state, { amtGb, amt })
        {
            const item = state.payList.find(item => item.amtGb === amtGb);
            
            if(item)
            {
                item.amt = Number(amt);
            }
        },
        setSizeYn(state, yn)
        {
            state.sizeYn = yn;
        }
    },
    actions : {
        async getInfo(context, params)
        {
            try
            {
                const res = await getAxiosData('https://data.planorder.kr/esti/getEstiMateInfo', params);

                console.log(res);

                context.commit('setCeNm', res.data['info']['ceNm']);

                const headers = [
                    {title: "전화번호", content: res.data['info']['tel']},
                    {title: "주소", content: res.data['info']['addr']},
                    {title: "상세주소", content: res.data['info']['addrDetail']},
                    {title: "견적일", content: getConvertDate(new Date(res.data['info']['stDt']), 'yy.mm.dd.w')}
                ];

                context.commit('setHeaders', headers);

                const list = [];
                
                res.data['location'].map(location => {
                    list.push({
                        title     : location.title,
                        cardLists : res.data['estiList'].map(esti => {
                            if(location.title === esti.title)
                            {
                                const rows = [];
                                const tags = [];

                                switch(esti.unit)
                                {
                                    case '001':
                                        if(esti.division === 'D')
                                        {
                                            rows.push(...esti.detail.map(dItem => ({
                                                width   : dItem.width,
                                                height  : dItem.height,
                                                leftQty : dItem.handle === '좌' ? 1 : 0,
                                                rightQty: dItem.handle === '우' ? 1 : 0,
                                                size    : dItem.size + esti.unitNm
                                            })));
                                        }
                                        else
                                        {
                                            rows.push({
                                                width   : esti.width,
                                                height  : esti.height,
                                                leftQty : esti.leftCnt,
                                                rightQty: esti.rightCnt,
                                                size    : esti.totalUnit + esti.unitNm
                                            });
                                        }
                                    break;
                                    case '002': case '003':
                                        rows.push({
                                            width   : esti.width,
                                            height  : esti.height,
                                            proc    : esti.proc === '001' ? '나비주름' : '평주름',
                                            split   : esti.split === '001' ? '양개' : '편개',
                                            size    : esti.totalUnit + esti.unitNm
                                        });
                                    break;
                                }

                                if(esti.shape === 'Y')
                                {
                                    tags.push({ spanText: "형상옵션" });
                                }

                                return {
                                    edCd         : esti.edCd,
                                    productTitle : esti.productTitle,
                                    colorTitle   : esti.colorTitle,
                                    amt          : Number(esti.totalSaleAmt) + Number(esti.totalSaleTax),
                                    isRed        : esti.productTitle === '' ? true : false,
                                    columns      : getCardColumns(esti.unit),
                                    rows         : rows,
                                    showTag      : tags.length > 0 ? true : false,
                                    tags         : tags,
                                    spanText     : esti.memo
                                }
                            }
                        }).filter(Boolean)
                    })
                });

                context.commit('setList', list);
                context.commit('setItemAmt', { name : 'itemAmt',  amt : Number(res.data['itemAmt']) });
                context.commit('setItemAmt', { name : 'itemTax',  amt : Number(res.data['itemTax']) });

                res.data['amtList'].map((amt) => {
                    context.commit('setPayAmt', { amtGb : amt['amtGb'], amt : Number(amt['amt']) });
                });

                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        }
    }
};

/**
 * @description 견적서 금액 내역
 */
function getPayList()
{
    return [
        { name : 'itemAmt', title: '상품 금액', amt: 0 },
        { name : 'itemTax', title: '부가세', amt: 0 },
        { name : 'shapeAmt', title: '형상 금액', amt: 0 },
        { name : 'heightAmt', title: '세로길이 추가금액', amt: 0 },
        { name : 'addAmt', amtGb : '001', title: '추가', amt: 0, red: true },
        { name : 'dcAmt', amtGb : '002', title: '할인', amt: 0, red: true },
        { name : 'cutAmt', amtGb : '003', title: '절삭 할인', amt: 0, red: true },
        { name : 'conAmt', amtGb : '004', title: '계약 선금', amt: 0, blue: true },
    ]
}

export default estiMateModule;