<template>
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.3333 14.9479L21.3125 12.9688C22.125 12.1563 22.125 10.8333 21.3125 10.0208L19.3333 8.05208V5.25C19.3333 4.10417 18.3958 3.16667 17.25 3.16667H14.4479L12.4688 1.1875C11.6563 0.375 10.3333 0.375 9.52083 1.1875L7.55208 3.16667H4.75C3.60417 3.16667 2.66667 4.10417 2.66667 5.25V8.05208L0.6875 10.0313C-0.125 10.8438 -0.125 12.1667 0.6875 12.9792L2.66667 14.9583V17.75C2.66667 18.8958 3.60417 19.8333 4.75 19.8333H7.55208L9.53125 21.8125C10.3438 22.625 11.6667 22.625 12.4792 21.8125L14.4583 19.8333H17.25C18.3958 19.8333 19.3333 18.8958 19.3333 17.75V14.9479ZM11 17.75C7.55208 17.75 4.75 14.9479 4.75 11.5C4.75 8.05208 7.55208 5.25 11 5.25C14.4479 5.25 17.25 8.05208 17.25 11.5C17.25 14.9479 14.4479 17.75 11 17.75Z"/>
    </svg>

</template>

<script>
export default {
    name: "IconSetting"
}
</script>

<style scoped>

</style>