import { getTodayTimeDate } from "@/assets/js/function";

/**
 * @description 회원가입 시 정보
 */
const joinModule = {
    namespaced : true, 
    state : {
        info : {
            certified   : 'N', /** 본인인증 완료 여부 (Y/N) */
            agree       : getAgreeInfo('N'),
            auth        : getAuthInfo(),
            login       : getLoginInfo(),
            center      : getCenterInfo()
        }
    },
    mutations : {
        setAgree(state, { gb, value })
        {
            if(gb === 'all')
            {
                state.info.agree = getAgreeInfo(value);
            }
            else
            {
                const date = value === 'Y' ? getTodayTimeDate() : '';

                state.info.agree[gb]['yn']   = value;
                state.info.agree[gb]['date'] = date;
            }
        },
        setInfo(state, { gb, value })
        {
            state.info[gb] = value;
        },
        setFile(state, { gb, file })
        {
            state.info[gb].img.file.push(file);
        },
        setReset(state)
        {
            state.info = {
                certified   : 'N',
                agree       : getAgreeInfo('N'),
                auth        : getAuthInfo(),
                login       : getLoginInfo(),
                center      : getCenterInfo()
            }
        }
    },
    actions : {
        setFile({ commit }, { gb, file })
        {
            commit('setFile', { gb, file });
        }
    }
};

function getAgreeInfo(gb)
{
    const date = gb === 'Y' ? getTodayTimeDate() : '';

    return {
        age    : { yn : gb, date : date },
        use    : { yn : gb, date : date },
        info   : { yn : gb, date : date },
        choice : { yn : gb, date : date }
    }
}

function getLoginInfo()
{
    return {
        id      : '',
        pw      : '',
        pwChk   : '',
        einFile : {
            file : null,
            name : ''
        }
    }
}

function getAuthInfo()
{
    return {
        name    : '',
        tel     : ''
    }
}

function getCenterInfo()
{
    return {
        ceNm        : '',
        area        : '',
        gb          : 'P',
        einNum      : '',
        ceoNm       : '',
        zip         : '',
        addr        : '',
        addrDetail  : '',
        ceoTel      : ''
    }
}
  
export default joinModule;