<template>
    <ModalBox title="제품정보">
        <div class="flex space-x-3">
            <SelectBox :options="options" class="w-1/3" />
            <SearchBox :value="search['itemNm']" placeholder="제품명 검색" class="w-2/3" @input="getInputValSet"/>
        </div>
        <div class="mt-2.5">
            <template v-for="(item, index) in list" :key="index">
                <MoreList :lists="item.list" :title="item['itemNm']" @getListClick="router.push({ path : '/factory/sysMng' , query : { cd : route.query.cd, itemCd : item.itemCd }})" />
            </template>
        </div>
    </ModalBox>
</template>

<script setup>
import SelectBox from "@/components/input/SelectBox.vue";
import SearchBox from "@/components/input/SearchBox.vue";
import ModalBox from "@/components/modal/ModalBox.vue";
import MoreList from "@/components/list/MoreList.vue";

import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

const store   = useStore();
const route   = useRoute();
const router  = useRouter();

const search = computed(() => store.state.factory.sys.itemSearch);
const list   = computed(() => store.state.factory.sys.itemList);

const options  = [{ value : '', label : '전체' }];

const getInputValSet = () => {
    search.value['itemNm'] = event.target.value;
    getList();
}

const getList = () => {
    const params = {
        ceCd   : store.state.login.ceCd,
        fcCd   : route.query.cd,
        itemNm : search.value['itemNm']
    };

    store.dispatch('factory/getSysItemList', params);
}

onMounted(() => {
    getList();
})

</script>

<style scoped>

</style>