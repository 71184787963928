<template>
    <div class="w-11/12 mx-auto bg-kakaoBg pt-6 pb-7 rounded-xl">
        <p class="text-zinc-700 mb-3.5 text-center text-xm">아래 on/off 버튼을 클릭해 플랜톡 내용을 편집해보세요.</p>
        <div class="rounded-t-xl bg-[#FAE101] text-center py-2.5 w-56 mx-auto">
            <p class="text-sm">알림톡 도착</p>
        </div>
        <div class="rounded-b-xl bg-white p-2.5 mx-auto w-56 relative text-xs h-96">
            <div class="space-y-4 mb-6">
                <p>{{ getStNm }} 알림</p>
                <p>
                    <span v-if="info['client'] === 'Y'" class="rounded-sm block w-fit bg-zinc-100 py-0.5 px-2.5 text-xm">고객이름</span>고객님<br>
                    {{ getMsg }}
                </p>
                <p>
                    {{ getStNm }}일: 일자<br>
                    대표번호: 매장 대표번호
                </p>
            </div>
            <div class="space-y-2">
                <span v-if="info['person'] === 'Y'" class="rounded-sm block w-fit bg-zinc-100 py-0.5 px-2.5 text-xm">담당자: {{ getStNm }} 담당 멤버</span>
                <span v-if="info['shopAddr'] === 'Y'" class="rounded-sm block w-fit bg-zinc-100 py-0.5 px-2.5 text-xm">주소: 매장주소 + 상세주소</span>
                <span v-if="info['url'] === 'Y'" class="rounded-sm block w-fit bg-zinc-100 py-0.5 px-2.5 text-xm">홈페이지: URL</span>
                <span class="rounded-sm block w-fit bg-zinc-100 py-0.5 px-2.5 text-xm">주차: {{ getParkMsg }}<br></span>
            </div>

            <BaseButton v-if="info['kdGb'] === '002'" lightButton buttonText="계약서 상세" class="mt-[84px]" />
        </div>
    </div>
</template>

<script setup>
import BaseButton from "@/components/button/BaseButton.vue";

import { computed, defineProps } from 'vue';

const props = defineProps({
    info : Object
});

const getMsgInfo = computed(() => {
    let name = '';
    let msg  = '';

    switch(props.info['kdGb'])
    {
        case '001':
            name  = '견적';
            msg   = '견적이 진행되었습니다.';
        break;
        case '002':
            name  = '계약';
            msg   = '계약이 완료되었습니다.';
        break;
        case '003':
            name  = '시공';
            msg   = '금일 시공일입니다. 감사합니다 :)';
        break;
        case '004':
            name  = '결제';
            msg   = '입금 부탁드리겠습니다 :)';
        break;
        case '005':
            name  = 'A/S';
            msg   = 'A/S가 접수되었습니다.';
        break;
    }

    return { name, msg };
});

const getStNm       = computed(() => getMsgInfo.value.name);
const getMsg        = computed(() => getMsgInfo.value.msg);
const getParkMsg    = computed(() => {
    let parkMsg = '';

    switch(props.info['park'])
    {
        case 'Y':
            parkMsg = '무료';
        break;
        case 'N':
            parkMsg = '불가';
        break;
        case 'C':
            parkMsg = '유료';
        break;
        case '1': case '2': case '3': case '4':
            parkMsg = `무료 ${props.info['park']}시간`;
        break;
    }

    return parkMsg;
});

</script>

<style scoped>

</style>