/**
 * @description 공통 데이터 vuex store module
 * @author 김원명, @date 2024-02-28
 */
const dataModule = {
    namespaced: true,
    state: {
        yearMonth : Array.from({ length: 252 }, (_, index) => {
            const year  = Math.floor(index / 12) + 2020;
            const month = index % 12 + 1;

            const value = `${year}${month < 10 ? '0' : ''}${month}`;
            const label = `${year}년 ${month < 10 ? '0' : ''}${month}월`;
            
            return { value, label };
        }),
        year  : Array.from({ length: 7 }, (_, index) => {
            const yearValue = 2024 + index;
            return { value: `${yearValue}`, label: `${yearValue}년` };
        }),
        mon   : Array.from({ length: 12 }, (_, index) => {
            const monthValue = (index + 1).toString().padStart(2, '0');
            return { value: monthValue, label: `${monthValue}월` };
        }),
        area  : [
            { label : '서울', value : 'SE' },
            { label : '부산', value : 'BS' },
            { label : '대구', value : 'DG' },
            { label : '대전', value : 'DJ' },
            { label : '광주', value : 'GJ' },
            { label : '인천', value : 'IC' },
            { label : '세종', value : 'SJ' },
            { label : '울산', value : 'US' },
            { label : '경기', value : 'KG' },
            { label : '강원', value : 'GW' },
            { label : '충북', value : 'CN' },
            { label : '충남', value : 'CS' },
            { label : '전북', value : 'JN' },
            { label : '전남', value : 'JS' },
            { label : '경북', value : 'KN' },
            { label : '경남', value : 'KS' },
            { label : '제주', value : 'JJ' }
        ],
        clientStat : [
            { value : '',    label : '전체'}, /** 견적대기 */
            { value : '001', label : '대기'}, /** 견적대기 */
            { value : '002', label : '견적'}, /** 견적진행 */
            { value : '003', label : '발주'}, /** 계약완료 */
            { value : '006', label : '시공'}, /** 발주완료 */
            { value : '011', label : '결제'}, /** 시공완료 */
            { value : 'N',   label : '취소'}  /** 견적취소 */
        ],
        division : [
            { value : 1, label : '단창'},
            { value : 2, label : '2분할'},
            { value : 3, label : '3분할'},
            { value : 4, label : '4분할'},
            { value : 5, label : '5분할'},
            { value : 6, label : '6분할'},
            { value : 7, label : '7분할'},
            { value : 8, label : '8분할'},
            { value : 9, label : '9분할'},
            { value : 10, label : '10분할'}
        ],
        proc        : [
            { value : '001', label : '나비주름' },
            { value : '002', label : '평주름' }
        ],
        use         : Array.from({ length: 21 }, (v, i) => ({ value: (1.0 + i * 0.1).toFixed(1), label : (1.0 + i * 0.1).toFixed(1)+'배' })),
        addColor    : [ 
            { value : 'O', label : '원톤' }, 
            { value : 'T', label : '투톤' }
        ],
        shape       : [ 
            { value : 'Y', label : '있음' }, 
            { value : 'N', label : '없음' } 
        ],
        split       : [ 
            { value : '001', label : '양개' }, 
            { value : '002', label : '편개' }
        ],
        payGb       : [
            { value : '001', label : '계좌' },
            { value : '002', label : '현금' },
            { value : '003', label : '카드' }
        ],
        inst        : [
            { value : '',      label : '업종_전체' },
            { value : 'IC001', label : '블라인드' },
            { value : 'IC002', label : '커튼' }
        ],
        shippingGb  : [
            { value : '001',    label : '화물' },
            { value : '002',    label : '택배' },
            { value : '003',    label : '직배' }
        ],
        fxGb        : [
            { value : '001',    label : '인건비' },
            { value : '002',    label : '차량관련' },
            { value : '003',    label : '임대료' },
            { value : '004',    label : '매장 운영비' },
            { value : '005',    label : '기타' }
        ]
    }
};

export default dataModule;