<template>
    <div class="fixed flex items-center bg-black/60 w-full h-screen top-0 left-0 right-0 mx-auto z-30">
        <div class="mx-auto rounded-none md:rounded overflow-hidden relative bg-white w-full min-w-80 max-w-[37.5rem] h-screen md:h-[90vh] xl:h-[85vh]">
            <div class="flex py-3.5 px-4 items-center justify-between relative">
                <img src="@/assets/images/btn-prev.svg" alt="이전" title="이전" @click="getClose"/>
                <h3 class="text-center w-full font-bold text-sm mt-0.5">{{ title }}</h3>
                <InlineButton blueButton :buttonText="blueButtonText" class="absolute right-4" v-if="showBlueButton" @click="getAnotherBtnClick"/>
            </div>
            <div class="section scroll-none pt-5 pb-16" :class="[{'height' : showFixedButton}, {'px-4': !noPadding}]">
                <slot></slot>
            </div>
            <div class="px-4 pt-2 pb-3.5 shadow-custom flex space-x-2" v-if="showFixedButton">
                <BaseButton :buttonText="buttonGreyText" v-if="showGreyButton" greyButton @click="getGreyButtonClick"/>
                <BaseButton :buttonText="buttonText" v-if="showButton" blueButton @click="getBlueButtonClick"/>
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from "@/components/button/BaseButton.vue";
import InlineButton from "@/components/button/InlineButton.vue";

export default {
    name      : "ModalBox",
    components: {InlineButton, BaseButton},
    props     : {
        title          : String,
        buttonText     : String,
        blueButtonText : String,
        buttonGreyText : String,
        showBlueButton : Boolean,
        showGreyButton : Boolean,
        showFixedButton: Boolean,
        showButton     : Boolean,
        noPadding      : Boolean,
    },
    methods   : {
        getAnotherBtnClick() {
            this.$emit("anotherBtnClick");
        },
        getClose() {
            this.$emit("close");
        },
        getGreyButtonClick() {
            this.$emit("greyBtnClick");
        },
        getBlueButtonClick() {
            this.$emit("blueBtnClick");
        }
    }
}
</script>

<style lang="scss" scoped>
.section {
    height: calc(100% - 50px);
    overflow: scroll;

    &.height {
        height: calc(100% - 112px);
    }
}
</style>