<template>
    <ModalBox title="발주서" showFixedButton 
        :showGreyButton="info['stCd'] !== '011' ? true : false" buttonGreyText="확인" @greyBtnClick="router.go(-1)"
        :showButton="info['stCd'] === '011' ? true : false" buttonText="최종 결제입력" @blueBtnClick="pay['popStat'] = true">
        <div class="space-y-5">
            <TableCard v-for="(table, index) in list" :key="index" :title="table.title" :cards="table.cardLists"
                :columns="table.columns" :rows="table.rows" :tags="table.tags" :showTag="table.showTag"
                :showButton="table.showButton" @getBtnClick="getBtnClick"/>
        </div>
        <GrayBar class="my-5"/>
        <CalculateCard title="제품 결제 내역" borderTop :calcs="payList" totalTitle="총 결제 금액" :totalAmt="totalAmt" />
        <div class="border-gray-200 border rounded p-4 mt-5">
            <div class="space-y-3.5">
                <dl class="flex items-center text-sm justify-between">
                    <dt class="text-zinc-500">견적일</dt>
                    <dd class="text-neutral-700">{{ getDate(info['estiDt']) }}</dd>
                </dl>
                <dl class="flex items-center text-sm justify-between">
                    <dt class="text-zinc-500">계약일</dt>
                    <dd class="text-neutral-700">{{ getDate(info['conDt']) }}</dd>
                </dl>
                <dl class="flex items-center text-sm justify-between">
                    <dt class="text-zinc-500">시공일</dt>
                    <dd class="text-neutral-700">{{ getDate(info['deliDt']) }}</dd>
                </dl>
                <dl class="flex items-center text-sm justify-between">
                    <dt class="text-zinc-500">설치 예상시간</dt>
                    <dd class="text-neutral-700">{{ info['insTime'] }}</dd>
                </dl>
                <dl class="flex items-center text-sm justify-between">
                    <dt class="text-zinc-500">시공 담당자</dt>
                    <dd class="text-neutral-700">{{ info['insUser'] }}</dd>
                </dl>
            </div>
        </div>
    </ModalBox>

    <SysOrderInfo :popupActive="sys['popStat']" />
    <OutOrderInfo :popupActive="out['popStat']" />
    <PaymentModal :popupActive="pay['popStat']"/>

    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp" @getCancle="getCancle"/>
</template>

<script setup>
import GrayBar from "@/components/common/GrayBar.vue";
import CalculateCard from "@/components/card/CalculateCard.vue";
import ModalBox from "@/components/modal/ModalBox.vue";
import TableCard from "@/components/card/TableCard.vue";
import SysOrderInfo from "@/views/customer/SysOrderInfo.vue";
import OutOrderInfo from "@/views/customer/OutOrderInfo.vue";
import PaymentModal from "@/views/customer/PaymentModal.vue";
import AlertBox from "@/components/modal/AlertBox.vue";

import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import { getAmt, getConvertDate } from '@/assets/js/function';

const store  = useStore();
const route  = useRoute();
const router = useRouter();

const list     = computed(() => store.state.order.list);
const payList  = computed(() => store.state.order.payList);
const info     = computed(() => store.state.order.info);
// const dcInfo   = computed(() => store.state.order.dcInfo);
// const addInfo  = computed(() => store.state.order.addInfo);
const sys      = computed(() => store.state.order.sysOrder);
const out      = computed(() => store.state.order.outOrder);
const pay      = computed(() => store.state.order.payOrder);
const totalAmt = computed(() => getAmt(payList.value, 'total'));

const resultYn  = ref('N');
const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
}

const getComp = () => {
    alertData.value['view'] = false;

    switch(resultYn.value)
    {
        case 'C':
            getSysOrderCancel();
        break;
    }
}

const getCancle = () => {
    alertData.value['view'] = false;
    resultYn.value = 'N';
};

const getList = async () => {
    const params = {
        emCd : route.query.emCd
    };

    await store.dispatch('order/getList', params);
}

const getDate = (date) => {
    return getConvertDate(new Date(date), 'yyyy-mm-dd hh:ii')
}


const getBtnClick = (edCd, ordGb, stCd) => {
    switch(stCd)
    {
        case '005': /** 발주대기 => 발주처리 로직 */
            if(ordGb === 'S')
            {
                sys.value['popStat'] = true;
                sys.value['edCd']    = edCd;
            }
            else
            {
                out.value['edCd']    = edCd;
                out.value['popStat'] = true;
            }
        break;
        case '006':
            if(ordGb === 'O')
            {
                router.push({ path : '/outOrderDoc', query : { cd : btoa(edCd) }});
            }
        break;
        case '009': /** 발주진행 => 발주취소 (공장 승인 전 상태) */
            getAlertDataSet(true, '발주 취소', '해당 발주서를 취소하시겠습니까?', true);
            resultYn.value = 'C';
        break;
        case '010': /** 발주승인 => 발주취소 요청(취소 요청을 보낸다) */
            console.log('발주승인');
        break;
    }
}

const getSysOrderCancel = async () => {
    getAlertDataSet(true, '준비 중', '해당 기능은 현재 준비중입니다.', false);
    resultYn.value = 'N';
    // await getAxiosData('https://data.planorder.kr/order/getSysOrderCancel', params).then((res) => {
    //     if(res['status'] === 200)
    //     {
    //         getList();
    //     }
    //     else
    //     {
    //         getAlertDataSet(true, '알림', '발주 처리에 실패하였습니다. 지속될 경우 관리자에게 문의하세요', false);
    //     }
    // });
}

onMounted(() => {
    getList();
});
</script>

<style scoped>

</style>