<template>
    <svg width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.0534 1.13542L16.5534 2.63542L11.4701 7.71875L8.04297 4.29167C7.63672 3.88542 6.98047 3.88542 6.57422 4.29167L0.324219 10.5521C-0.0820312 10.9583 -0.0820312 11.6146 0.324219 12.0208C0.730469 12.4271 1.38672 12.4271 1.79297 12.0208L7.30338 6.5L10.7305 9.92708C11.1367 10.3333 11.793 10.3333 12.1992 9.92708L18.0221 4.11458L19.5221 5.61458C19.8451 5.9375 20.4076 5.70833 20.4076 5.25V0.770833C20.418 0.479167 20.1888 0.25 19.8971 0.25H15.4284C14.9596 0.25 14.7305 0.8125 15.0534 1.13542V1.13542Z"/>
    </svg>

</template>

<script>
export default {
    name: "IconAverage"
}
</script>

<style scoped>

</style>