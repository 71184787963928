<template>
    <div class="py-4 px-3.5 bg-white rounded">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "WhiteBox"
}
</script>

<style scoped>

</style>