<template>
    <div class="relative w-full">
        <input type="file" :id="fileId" :name="name" placeholder="파일을 첨부해 주세요" class="hidden" :disabled="disabled" @change="handleFileChange"/>
        <label :for="id" class="absolute -top-2.5 left-1.5 text-xm text-gray-400 bg-white py-0.5 px-1 z-10">{{
                label
            }}<span class="text-red-500" v-if="important">*</span></label>
        <div class="relative w-full">
            <label :for="fileId" class="pt-3 pb-2.5 pl-3.5 pr-8 border block w-full rounded-sm text-xs duration-200 disabled:bg-zinc-100" :class="{'border-zinc-200': !error, 'border-red-600': error}">
                {{ fileName === '' ? '파일첨부' : fileName }}
            </label>
            <FontAwesomeIcon :icon="['fas', 'arrow-up-from-bracket']" class="vertical-h absolute right-2.5"/>
        </div>
        <AlertBox v-if="alertData['view']" 
            :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
            @getComp="getComp"/>
    </div>
</template>

<script setup>
import AlertBox from "@/components/modal/AlertBox.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { defineProps, defineEmits, ref } from 'vue';

defineProps({
    label       : String,
    type        : String,
    id          : String,
    fileId      : String,
    name        : String,
    fileName    : String,
    helperText  : String,
    disabled    : Boolean,
    error       : {
        type   : Boolean,
        default: false,
    },
    important   : Boolean,
});

const emit = defineEmits(['fileSelect']);

const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
};

const getComp = () => {
    alertData.value['view'] = false;
}

const handleFileChange = (event) => {
    const fileInput = event.target;
    const maxSize   = 20 * 1024 * 1024; // 20MB

    if(fileInput.files[0].size > maxSize)
    {
        getAlertDataSet(true, '확인', '파일 크기 확인', '파일 크기는 20MB를 넘을 수 없습니다.', false);
        return false;
    }

    if (fileInput.files.length > 0) 
    {
        const file = fileInput.files[0];
        emit('fileSelect', file);
    }
}
</script>

<style scoped>

</style>