<template>
    <ModalBox title="매장 회원가입 1/2">
        <div class="space-y-5">
            <LabelInput type="text" label="아이디 (이메일)" id="id" :value="login['id']" placeholder="이메일을 입력해주세요" @input="getInputValSet"/>
            <LabelInput type="password" label="비밀번호" id="pw" :value="login['pw']" placeholder="비밀번호를 입력해주세요" @input="getInputValSet"/>
            <LabelInput type="password" label="비밀번호 확인" id="pwChk" :value="login['pwChk']" placeholder="비밀번호를 다시 한 번 입력해주세요" @input="getInputValSet"/>
            <LabelFile id="einFile" fileId="upload" :fileName="login['einFile']['name']" label="사업자등록증 업로드" @fileSelect="getFile"/>
            <BaseButton buttonText="다음" greyButton @click="getJoinNext"/>
        </div>
    </ModalBox>
    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp"/>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox";
import LabelInput from "@/components/input/LabelInput";
import LabelFile from "@/components/input/LabelFile";
import BaseButton from "@/components/button/BaseButton";
import AlertBox from "@/components/modal/AlertBox.vue";

import { onMounted ,computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store  = useStore();
const router = useRouter();

const certified = computed(() => store.state.join.info.certified);
const login     = computed(() => store.state.join.info.login);

const focusId   = ref('');
const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
};

const getFile = (file) => {
    login.value['einFile']['file'] = file;
    login.value['einFile']['name'] = file.name;
};

const getComp = () => {
    alertData.value['view'] = false;
    document.getElementById(focusId.value).focus();
}

const getInputValSet = (event) => {
    const id    = event.target.id;
    const value = event.target.value;

    login.value[id] = value;
};

const getInputCheck = () => {
    let stat = true;

    stat = stat && getValueCheck('id');
    stat = stat && getValueCheck('pw');
    stat = stat && getValueCheck('pwChk');
    stat = stat && getIdCheck();
    stat = stat && getPwCheck();

    return stat;
}

const getValueCheck = (id) => {
    let text = '필수 정보가 입력되지 않았습니다.';

    if(login.value[id] === '')
    {
        getAlertDataSet(true, '확인', text, false);
        focusId.value = id;
        return false;
    }

    return true;
};

const getIdCheck = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const stat       = emailRegex.test(login.value['id']);

    if(!stat)
    {
        getAlertDataSet(true, '확인', '잘못된 이메일 형식입니다. 이메일에 맞춰 작성해주세요.', false);
        focusId.value = 'id';
    }

    return stat;
};

const getPwCheck = () => {
    if (login.value['pw'] !== login.value['pwChk']) 
    {
        getAlertDataSet(true, '확인', '비밀번호가 일치하지 않습니다.', false);
        focusId.value = 'pwChk';
        return false;
    }

    return true;
};

const getJoinNext = () => {
    const inputCheck = getInputCheck();

    if(!inputCheck)
    {
        return false;
    }

    router.push({ path : `/join/joinSecond` });
}

onMounted(() => {
    if(certified.value === 'N')
    {
        router.go(-1);
    }
});

</script>

<style scoped>

</style>