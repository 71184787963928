<template>
    <div>
        <label :for="id" class="relative w-10 block">
            <input type="checkbox" :id="id" :name="name" :checked="value === 'Y' ? true : false" class="peer/status hidden" @change="getChange"/>
            <div class="h-1 bg-gray-200 duration-200 peer-checked/status:bg-blue-400 rounded-2xl"></div>
            <span class="w-4 h-4 block absolute bg-gray-200 rounded-2xl vertical-h left-0 duration-200 peer-checked/status:bg-blue-500 peer-checked/status:left-2/3"></span>
        </label>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

defineProps({
    id      : String,
    name    : String,
    value   : String
});

const emit = defineEmits(['getCutResult']);

const getChange = () => {
    const value = event.target.checked;

    emit('getCutResult', value === true ? 'Y' : 'N');
}

</script>

<style scoped>

</style>