<template>
    <ModalBox title="외주 공장 이름 제품정보" @close="router.go(-1)">
        <div class="flex space-x-3">
            <SelectBox :options="inst" :value="search['instCd']" class="w-1/3" @change="getSelectChange"/>
            <SearchBox :value="search['itemNm']" placeholder="제품명 검색" class="w-2/3" @input="getInputValSet"/>
        </div>
        <div class="mt-2.5">
            <div class="flex items-center justify-between border-b py-3">
                <h4 class="text-brand font-bold text-xs">블라인드</h4>
                <InlineRoundButton buttonText="제품 추가" @click="router.push({ path : '/factory/outMng' , query : { fcCd : route.query.cd, type : 'I', instCd : 'IC001' }})"/>
            </div>
            <template v-for="(item, index) in list" :key="index">
                <MoreList :lists="item.list" :title="item['itemNm']" v-if="item['instCd'] === 'IC001'" @getListClick="router.push({ path : '/factory/outMng' , query : { itemCd : item.itemCd, type : 'M', instCd : 'IC001' }})"/>
            </template>
        </div>
        <div class="mt-2.5">
            <div class="flex items-center justify-between border-b py-3">
                <h4 class="text-brand font-bold text-xs">커튼</h4>
                <InlineRoundButton buttonText="제품 추가" @click="router.push({ path : '/factory/outMng' , query : { fcCd : route.query.cd, type : 'I', instCd : 'IC002' }})"/>
            </div>
            <template v-for="(item, index) in list" :key="index">
                <MoreList :lists="item.list" :title="item['itemNm']" v-if="item['instCd'] === 'IC002'" @getListClick="router.push({ path : '/factory/outMng' , query : { itemCd : item.itemCd, type : 'M', instCd : 'IC002' }})"/>
            </template>
        </div>
    </ModalBox>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox.vue";
import MoreList from "@/components/list/MoreList.vue";
import SelectBox from "@/components/input/SelectBox.vue";
import SearchBox from "@/components/input/SearchBox.vue";
import InlineRoundButton from "@/components/button/InlineRoundButton.vue";

import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

const store  = useStore();
const route  = useRoute();
const router = useRouter();

const inst   = computed(() => store.state.data.inst);
const search = computed(() => store.state.factory.out.itemSearch);
const list   = computed(() => store.state.factory.out.itemList);

const getSelectChange = () => {
    search.value['instCd'] = event.target.value;
    getList();
}

const getInputValSet = () => {
    search.value['itemNm'] = event.target.value;
    getList();
}

const getList = () => {
    const parmas = {
        fcCd    : route.query.cd,
        instCd  : search.value['instCd'],
        itemNm  : search.value['itemNm'],
        start   : search.value['start']
    };

    store.dispatch('factory/getOutFactoryItemList', parmas);
}

onMounted(() => {
    getList();
});
</script>

<style scoped>

</style>