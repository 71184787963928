<template>
    <ModalBox title="로그인">
        <div class="space-y-4">
            <LabelInput type="text" :id="'id'" :value="id" placeholder="이메일을 입력해주세요" label="이메일" @keyup.enter="getEnter" @input="getInputValSet"/>
            <LabelInput type="password" :id="'pw'" :value="pw" placeholder="비밀번호를 입력해주세요" label="비밀번호" @keyup.enter="getEnter" @input="getInputValSet"/>
            <div class="flex justify-between text-zinc-500">
                <ul class="w-2/3 flex flex-row-reverse items-center">
                    <li class="text-xs">
                        <router-link to="">비밀번호 찾기</router-link>
                    </li>
                    <li class="text-xs border-r mr-3 pr-3">
                        <router-link to="">아이디 찾기</router-link>
                    </li>
                </ul>
            </div>
            <BaseButton buttonText="이메일 로그인" greyButton @click="getLogin"/>
            <p class="text-xs text-zinc-500 text-center">
                계정이 없으신가요?
                <router-link to="/join/agree" class="ml-5">
                    <span class="text-brand font-bold">회원가입</span>
                </router-link>
            </p>
        </div>
    </ModalBox>
    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp"/>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox";
import LabelInput from "@/components/input/LabelInput";
import BaseButton from "@/components/button/BaseButton";
import AlertBox from "@/components/modal/AlertBox.vue";

import axios from 'axios';

import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store  = useStore();
const router = useRouter();

const id        = ref('');
const pw        = ref('');
const focusId   = ref('');

const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
};

const getComp = () => {
    alertData.value['view'] = false;
    
    if(focusId.value !== '')
    {
        document.getElementById(focusId.value).focus();
    }
};

const getEnter = () => {
    const id = event.target.id;

    if(id === 'id')
    {
        document.getElementById('pw').focus();
    }
    else
    {
        getLogin();
    }
}

const getInputValSet = () => {
    const targetId  = event.target.id;
    const val       = event.target.value;

    if(targetId === 'id') 
    {
        id.value = val;
    } 
    else 
    {
        pw.value = val;
    }
}

const getLogin = async () => {
    if(id.value === '')
    {
        getAlertDataSet(true, '확인', '이메일을 입력해주세요.', false);
        focusId.value = 'id';
        return;
    }

    if(pw.value === '')
    {
        getAlertDataSet(true, '확인', '비밀번호를 입력해주세요.', false);
        focusId.value = 'pw';
        return;
    }

    try
    {
        const res = await axios.post('https://data.planorder.kr/login/getLogin', JSON.stringify({ id : id.value, pw : pw.value }));

        const ceCd   = res['data']['data']['ceCd'];
        const token  = res['data']['data']['token'];
        const userCd = res['data']['data']['userCd'];

        if(ceCd !== null && ceCd !== undefined && ceCd !== '')
        {
            store.dispatch('login/login', { ceCd : ceCd, token : token, userCd : userCd });
            router.push({path : `/`});
        }
        else
        {
            getAlertDataSet(true, '확인', '이메일과 비밀번호가 일치하지 않습니다.', false);
        }
    }
    catch(e)
    {
        console.log(e);
        getAlertDataSet(true, '확인', '이메일과 비밀번호가 일치하지 않습니다.', false);
    }
}


</script>

<style scoped>

</style>