<template>
    <ModalBox title="월간 분석">
        <div class="flex justify-between items-center mb-3.5">
            <h3 class="text-lg font-bold">{{ year }}년</h3>
            <BasicSelect id="year" :options="yearList" :value="year" disabled noBorder @change="getSelect"/>
        </div>
        <AccInfo :saleAmt="month['amt']['saleAmt']" :purcAmt="month['amt']['purcAmt']" :margin="month['amt']['margin']"/>
        <GrayBar class="my-2.5"/>
        <div>
            <h2 class="py-2.5 text-sm text-neutral-500 border-b">
                {{ year }}
            </h2>
            <template v-for="(m, i) in mon" :key="i">
                <StatisticsList :day="m['label']" 
                    :margin="getData(m['value'], 'margin')" :rev="getData(m['value'], 'rev')" 
                    :waitAmt="getData(m['value'], 'waitAmt')" :totalAmt="getData(m['value'], 'saleAmt')" @getClick="getClick(m['value'])"/>
            </template>
        </div>
    </ModalBox>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox.vue";
import AccInfo from "@/views/acc/AccInfo.vue";
import BasicSelect from "@/components/input/BasicSelect.vue";
import GrayBar from "@/components/common/GrayBar.vue";
import StatisticsList from "@/views/acc/StatisticsList.vue";

import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store  = useStore();
const router = useRouter();

const yearList  = computed(() => store.state.data.year);
const year      = computed(() => store.state.acc.year);
const mon       = computed(() => store.state.data.mon);
const month     = computed(() => store.state.acc.month);

const getSelect = () => {
    store.commit('acc/setYear', event.target.value);
    getAccMonth();
}

const getData = (m, name) => {
    const data = month.value['list'].find(item => item.month === m);

    if (!data || !(name in data)) 
    {
        return '0';
    }

    return data[name];
}

const getClick = (m) => {
    const data = month.value['list'].find(item => item.month === m);

    if (!data) return;

    router.push({ path : '/acc/week', query : { year : year.value, mon : m } });
}

const getAccMonth = () => {
    const params = {
        ceCd : store.state.login.ceCd,
        year : year.value
    }

    store.dispatch('acc/getAccMonth', params);
}

onMounted(() => {
    getAccMonth();
});
</script>

<style scoped>

</style>