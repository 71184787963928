<template>
    <ToggleBox title="금액 조정" showFixedButton showBlueButton blueButtonText="결제완료" :popupActive="props.popupActive" @getBlueBtnClick="getPayCheck" @getClose="getClose">
        <div class="space-y-3">
            <InlineUnit id="saleCnt" name="saleCnt" :value="getAmtInfo('lastDcAmt')" label="할인 금액" unit="원" class="text-red-500" readonly @click="disCount = true"/>
            <InlineUnit id="plusCnt" name="plusCnt" :value="getAmtInfo('lastAddAmt')" label="추가 금액" unit="원" class="text-brand" readonly @click="addCount = true"/>
            <InlineUnit type="number" :value="totalAmt" label="최종 결제금액" unit="원" class="text-brand" readonly />
        </div>
        <h3 class="font-bold mt-6 mb-5">입금 정보</h3>
        <div class="space-y-3">
            <InlineDual label="잔금">
                <BasicSelect id="payGb" :options="payGb" :value="pay['payGb']" class="w-1/2" @change="getSelect"/>
                <BasicInput type="number" :value="totalAmt" unit="원" unitBlue showUnit class="w-1/2" readonly/>
            </InlineDual>
            <InlineInput id="memo" type="text" label="메모" placeholder="비고" @input="getInputValSet"/>
        </div>
    </ToggleBox>
    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp" @getCancle="getCancle"/>
    <SaleBox v-if="disCount" :gubun="'P'" @getResult="getDisResult" @getClose="disCount=false"/>
    <AddBox v-if="addCount" :gubun="'P'" @getResult="getAddResult" @getClose="addCount=false"/>
</template>

<script setup>
import ToggleBox from "@/components/modal/ToggleBox.vue";
import InlineUnit from "@/components/input/InlineUnit.vue";
import InlineDual from "@/components/input/InlineDual.vue";
import BasicSelect from "@/components/input/BasicSelect.vue";
import BasicInput from "@/components/input/BasicInput.vue";
import InlineInput from "@/components/input/InlineInput.vue";
import SaleBox from "@/components/modal/SaleBox.vue";
import AddBox from "@/components/modal/AddBox.vue";
import AlertBox from "@/components/modal/AlertBox.vue";

import { computed, defineProps, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import { getAmt, getAxiosData } from '@/assets/js/function';

const store  = useStore();
const route  = useRoute();
const router = useRouter();

const pay       = computed(() => store.state.order.payOrder);
const dcInfo    = computed(() => store.state.order.dcInfo);
const addInfo   = computed(() => store.state.order.addInfo);
const payList   = computed(() => store.state.order.payList);
const payGb     = computed(() => store.state.data.payGb);
const totalAmt  = computed(() => getAmt(payList.value, 'total'));

const disCount  = ref(false);
const addCount  = ref(false);

const props = defineProps({
    popupActive : Boolean
});

const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const resultYn = ref('N');

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
}

const getComp = () => {
    alertData.value['view'] = false;

    if(resultYn.value === 'Y')
    {
        getPayResult();
    }
}

const getCancle = () => {
    alertData.value['view'] = false;
};

const getClose = () => {
    pay.value['popStat'] = false;
}

const getDisResult = () => {
    disCount.value = false;

    if(dcInfo.value['unit'] === 'F')
    {
        dcInfo.value['amt'] = dcInfo.value['val'];

        store.commit('order/setPayAmt', { amtGb : '006', amt : -Number(dcInfo.value['val']) });
    }
    else
    {
        const amt    = getAmt(payList.value, 'lastDis');
        const dcAmt  = Math.round(amt/100 * dcInfo.value['val']);
        
        dcInfo.value['amt'] = -dcAmt;

        store.commit('order/setPayAmt', { amtGb : '006', amt : -dcAmt });
    }
}

const getAddResult = () => {
    addCount.value = false;

    addInfo.value['amt'] = addInfo.value['val'];
    store.commit('order/setPayAmt', { amtGb : '005', amt : Number(addInfo.value['val']) });

    /** 할인 단위가 %일 시 할인 금액 재적용 */
    if(dcInfo.value['unit'] === 'P')
    {
        const amt    = getAmt(payList.value, 'lastDis');
        const dcAmt  = Math.round(amt/100 * dcInfo.value['val']);

        dcInfo.value['amt'] = -dcAmt;
        store.commit('order/setPayAmt', { amtGb : '006', amt : -dcAmt });
    }
}

const getAmtInfo = (name) => {
    const info = payList.value.find(item => item.name === name);

    return info['amt'];
}

const getSelect = () => {
    const val = event.target.value;

    pay.value['payGb'] = val;
}

const getInputValSet = () => {
    pay.value[event.target.id] = event.target.value;
}

const getPayCheck = () => {
    resultYn.value = 'Y';
    getAlertDataSet(true, '결제 완료', '결제 완료 처리하시겠습니까?', true);
}

const getPayResult = () => {
    const params = {
        emCd     : route.query.emCd,
        userCd   : store.state.login.userCd,
        addInfo  : addInfo.value,
        dcInfo   : dcInfo.value,
        payGb    : pay.value['payGb'],
        memo     : pay.value['memo'],
        totalAmt : totalAmt.value
    };

    getAxiosData('https://data.planorder.kr/order/getPayResult', params).then((res)  => {
        resultYn.value = 'N';
        
        if(res['status'] === 200)
        {
            router.go(-1);
        }
        else
        {
            getAlertDataSet(true, '결제 완료', '결제 완료 처리에 실패하였습니다. 지속될 경우 관리자에게 문의하세요.', false);
        }
    });
}
</script>

<style scoped>

</style>