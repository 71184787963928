<template>
    <div class="bg-white rounded border border-gray-200 overflow-hidden">
        <h4 class="py-2 px-4 text-left bg-neutral-100 text-sm font-normal border-b">{{ title }}</h4>
        <div class="py-5 px-4 card space-y-5" v-for="(card, index) in cards" :key="index" @click="getListClick(card.edCd)">
            <div>
                <div class="flex items-center justify-between">
                    <h5 class="text-xs text-zinc-400">{{ card.productTitle }}</h5>
                    <DeleteButton v-if="card.showDelete" @click.stop="getDelete(card.edCd)"/>
                </div>
                <div class="flex items-center justify-between text-sm mt-3 mb-5">
                    <h3 class="font-bold" :class="{ 'text-red-500': card.isRed, 'text-blue-500': !card.isRed }">{{ card.colorTitle }}</h3>
                    <p class="font-medium">{{ getAmt(card.amt) }}원</p>
                </div>
            </div>
            <table class="table-fixed w-full text-center">
                <thead>
                    <tr>
                        <template v-for="col in card.columns" :key="col.key">
                            <template v-if="sizeYn === 'Y'">
                                <th class="text-xm text-zinc-400 pb-1" v-if="col.header !== '가로' && col.header !== '세로'">
                                    {{ col.header }}
                                </th>
                            </template>
                            <template v-else>
                                <th class="text-xm text-zinc-400 pb-1">
                                    {{ col.header }}
                                </th>
                            </template>
                        </template>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in card.rows" :key="index">
                        <template v-if="sizeYn === 'Y'">
                            <template v-for="col in card.columns" :key="col.key">
                                <td v-if="col.key !== 'width' && col.key !== 'height'" class="font-bold text-sm text-neutral-700 py-0.5">
                                    {{ row[col.key] }}
                                </td>
                            </template>
                        </template>
                        <template v-else>
                            <template v-for="col in card.columns" :key="col.key">
                                <td class="font-bold text-sm text-neutral-700 py-0.5">
                                    {{ row[col.key] }}
                                </td>
                            </template>
                        </template>
                    </tr>
                </tbody>
            </table>
            <div v-if="card.showTag">
                <div class="flex flex-wrap flex-row">
                    <SpanTag v-for="(tag, index) in card.tags" :key="index" :spanText="tag.spanText" class="mr-2 mb-1.5" isTeal />
                </div>
                <div class="mt-1.5">
                    <SpanTag :spanText="card.spanText" isYellow />
                </div>
            </div>
            <BaseButton v-if="card.showButton && index === cards.length -1" 
                :buttonText="card.buttonText" 
                :blueButton="card.blueButton" 
                :greyButton="card.greyButton"
                :cyanButton="card.cyanButton" 
                :softOrange="card.softOrange" 
                :vividOrange="card.vividOrange" 
                @getBtnClick="getBtnClick(card.edCd, card.ordGb, card.detailStCd)"
            />
        </div>
    </div>
</template>

<script setup>
import DeleteButton from "@/components/button/DeleteButton.vue";
import SpanTag from "@/components/common/SpanTag.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import { getCommas } from "@/assets/js/function";

import { defineProps, defineEmits } from 'vue';

defineProps({
    title   : String,
    cards   : String,
    columns : Array,
    rows    : Array,
    sizeYn  : String
});

const emit = defineEmits(['getDelete', 'getBtnClick']);

const getListClick = (edCd) => {
    emit("getListClick", edCd);
}

const getDelete = (edCd) => {
    emit("getDelete", edCd);
}

const getAmt = (amt) => {
    return getCommas(Number(amt));
};

const getBtnClick = (edCd, ordGb, stCd) => {
    emit("getBtnClick", edCd, ordGb, stCd);
}

</script>

<style lang="scss" scoped>
.card{
    & + &{
        border-top: 1px solid #e5e7eb;
    }
}
</style>