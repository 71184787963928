<template>
    <div class="hidden xl:flex flex-row-reverse items-center w-full py-2.5  px-12 border-b">
        <div class="w-8 h-8 rounded-2xl overflow-hidden cursor-pointer">
            <img src="@/assets/images/icon-profile.png" alt="profile" title="profile" />
        </div>
        <FontAwesomeIcon :icon="['far', 'bell']" class="text-2xl mr-7 cursor-pointer" />
    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "SideHeader",
    components: {FontAwesomeIcon}
}
</script>

<style scoped>

</style>