import { createStore } from 'vuex';
import loginModule from '@/modules/loginModule';
import dataModule from '@/modules/dataModule';
import joinModule from '@/modules/joinModule';
import clientModule from '@/modules/clientModule';
import productModule from '@/modules/productModule';
import estiModule from '@/modules/estiModule';
import estiMateModule from '@/modules/estiMateModule';
import conModule from '@/modules/conModule';
import conMateModule from '@/modules/conMateModule';
import orderModule from '@/modules/orderModule';
import outOrderDocModule from '@/modules/outOrderDocModule';
import mainModule from '@/modules/mainModule';
import factoryModule from '@/modules/factoryModule';
import accModule from '@/modules/accModule';
import averageModule from '@/modules/averageModule';
import kakaoModule from '@/modules/kakaoModule';
import msgModule from '@/modules/msgModule';
import timeSettingModule from '@/modules/timeSettingModule';
import staticPayModule from '@/modules/staticPayModule';

const store = createStore({
    modules: {
        login       : loginModule,
        data        : dataModule,
        main        : mainModule,
        join        : joinModule,
        client      : clientModule,
        product     : productModule,
        esti        : estiModule,
        estiMate    : estiMateModule,
        con         : conModule,
        conMate     : conMateModule,
        order       : orderModule,
        outOrderDoc : outOrderDocModule,
        factory     : factoryModule,
        acc         : accModule,
        average     : averageModule,
        kakao       : kakaoModule,
        msg         : msgModule,
        timeSetting : timeSettingModule,
        staticPay   : staticPayModule
    }
});

export default store;