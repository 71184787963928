<template>
    <h2 class="font-bold">{{ title }}</h2>
    <div class="w-full flex rounded-2xl overflow-hidden">
        <div v-for="(number, index) in numbers" :key="index"
             class="h-5 pr-2 text-right text-xs font-bold text-white leading-5"
             :class="['wc-' + number.title, number.color, { 'hidden': number.title === 0 }]">
            {{ number.title }}%
        </div>
    </div>
</template>

<script>
export default {
    name : "BarAverage",
    props: {
        title: String,
        numbers: Array,
    }
}
</script>

<style lang="scss" scoped>
@for $i from 1 through 100 {
    .wc-#{$i} {
        width: calc(#{$i} * 1%);
    }
}

.blue {
    background-color: #78BBFF;
}

.yellow {
    background-color: #FFCE6D;
}

.pink {
    background-color: #FF6C99;
}

.green {
    background-color: #7CE5AC;
}
</style>