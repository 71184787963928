<template>
    <BottomBox title="더보기" @getClose="getClose">
        <BaseButton buttonText="견적서 발송" blueButton @click="getEstiSend"/>
        <BaseButton buttonText="계약서 발송" blueButton @click="getConSend"/>
        <BaseButton buttonText="네비게이션" blueButton />
        <BaseButton buttonText="명세표 취소" blueButton @click="getCancle"/>
    </BottomBox>
</template>

<script setup>
import BottomBox from "@/components/modal/BottomBox.vue";
import BaseButton from "@/components/button/BaseButton";

import { defineEmits } from 'vue';

const emit = defineEmits(['getClose', 'getCancle']);

const getClose = () => {
    emit('getClose');
}

const getEstiSend = () => {
    emit('getEstiSend');
}

const getConSend = () => {
    emit('getConSend');
}

const getCancle = () => {
    emit('getCancle');
}
</script>

<style scoped>

</style>