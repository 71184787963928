<template>
    <div class="block w-full border-b py-4 flex justify-between items-center">
        <div class="w-full">
            <h5 class="text-zinc-500 font-bold text-sm flex">
                {{ title }}
                <img src="@/assets/images/icon-kakaologo.png" alt="kakao" title="kakao" class="ml-2"/>
            </h5>
            <p class="mt-2.5 text-xs text-zinc-400">{{ message }}</p>
        </div>
        <span class="bg-green-400 text-white rounded-xl py-1 px-1.5 font-bold text-xs">{{ useYn === 'Y' ? 'ON' : 'OFF' }}</span>
        <FontAwesomeIcon class="text-zinc-500 p-2.5 ml-2" :icon="['fas', 'caret-right']" @click="getDetail"/>
    </div>
</template>

<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { defineProps, defineEmits } from 'vue';

defineProps({
    title   : String,
    message : String,
    useYn   : String
});

const emit = defineEmits(['getDetail']);

const getDetail = () => {
    emit('getDetail');
}
</script>

<style scoped>

</style>