<template>
    <div class="space-y-3">
        <div class="flex justify-between items-center" v-for="(pay, index) in pays" :key="index">
            <div class="flex items-center">
                <p class="w-2.5 h-2.5 rounded-2xl" :class="pay.color"></p>
                <p class="ml-3.5 text-xs">{{ pay.title }}</p>
            </div>
            <h4 class="font-bold text-sm text-zinc-500">
                {{ pay.count }}원
            </h4>
        </div>
    </div>
</template>

<script>
export default {
    name: "ListAverage",
    props: {
        pays   : Array,
    }
}
</script>

<style scoped>
.blue {
    background-color: #78BBFF;
}

.yellow {
    background-color: #FFCE6D;
}

.pink {
    background-color: #FF6C99;
}

.green {
    background-color: #7CE5AC;
}
</style>