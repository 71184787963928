<template>
    <div class="w-full">
        <div>
            <h2 class="font-bold">{{ title }}</h2>
            <button>{{ buttonText }}</button>
        </div>

        <ul class="space-y-3">
            <li v-for="(info, index) in props.info" :key="index" class="flex items-center">
                <p class="w-1/4 text-xs text-zinc-500">{{ info.title }}</p>
                <p class="w-3/4 text-xs"><span :class="{'bg-brand text-white text-xm px-2 py-0.5 rounded-2xl': info.background === true}" @click="getNavi(info.name, info.content)">{{ info.content }}</span></p>
            </li>
        </ul>

        <GrayBar class="mt-6" v-if="!grayBar" />
    </div>
</template>

<script setup>
import GrayBar from "@/components/common/GrayBar.vue";
import { defineProps } from 'vue';

const props = defineProps({
    title       : String,
    buttonText  : String,
    info        : {
        type : Array
    },
    grayBar     : Boolean,
});

const getNavi = (name, val) => {
    if(name === 'addr')
    {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type : 'address' , value : val}));
    }
    else if(name === 'tel')
    {
        const phoneNumber   = val;
        const telHref       = 'tel:' + phoneNumber;

        window.location.href = telHref;
    }
}
</script>

<style lang="scss" scoped>
.text-xm{
    font-size: 0.625rem !important;
}
</style>