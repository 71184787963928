<template>
    <button class="text-xs p-1.5 pl-3 bg-blue-100 rounded-2xl font-bold text-brand flex justify-between items-center">
        {{ buttonText }}
        <FontAwesomeIcon class="text-white text-xm bg-brand py-1 px-[0.3rem] ml-2 rounded-[50%]" :icon="['fas', 'plus']"/>
    </button>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "InlineRoundButton",
    components: {FontAwesomeIcon},
    props: {
        buttonText: String,
    }
}
</script>

<style scoped>

</style>