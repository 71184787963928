<template>
    <table class="w-full">
        <colgroup>
            <col v-for="col in columns" :key="col.key" :class="col.class"/>
        </colgroup>
        <thead>
        <tr>
            <th v-for="col in columns" :key="col.key" class="text-sm h-12 border-b border-zinc-300 text-left px-4 sticky top-0 bg-white" :class="{'text-center' : col.key === 'status'}">
                {{ col.header }}
            </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in rows" :key="index">
            <td v-for="col in columns" :key="col.key" :class="col.class" class="h-11 px-4 text-sm text-gray-500 text-ellipsis overflow-hidden whitespace-nowrap border-b">
                <span v-if="col.key === 'status'" class="text-white px-4 pt-1 pb-0.5 bg-blue-500 text-sm font-bold rounded-2xl block mx-auto w-fit">
                    {{ row[col.key] }}
                </span>
                <span v-else>
                    {{ row[col.key] }}
                </span>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "StatusTable",
    props: {
        columns: Array,
        rows   : Array,
    }
}
</script>

<style lang="scss" scoped>
@for $i from 1 through 50 {
    .w#{$i} {
        width: calc(#{$i} * 1%);
        min-width: calc(#{$i} * 12.5px);
    }
}
</style>