import { getAxiosData, getAddDate, getConvertDate, getCardColumns } from '@/assets/js/function';
/**
 * @description 발주서 관련 vuex 모듈
 */
const orderModule = {
    namespaced : true, 
    state : {
        list        : [],
        payList     : getPayList(),
        dcInfo      : getAmtInfo(),
        addInfo     : getAmtInfo(),
        info        : getOrderInfo(),
        sysOrder    : getOrder(),
        outOrder    : getOrder(),
        payOrder    : getPay()
    },
    mutations : {
        setList(state, list) 
        {
            state.list = list;
        },
        setItemAmt(state, { name, amt })
        {
            const item = state.payList.find(item => item.name === name);
            
            if(item)
            {
                item.amt = Number(amt);
            }
        },
        setPayAmt(state, { amtGb, amt })
        {
            const item = state.payList.find(item => item.amtGb === amtGb);
            
            if(item)
            {
                item.amt = Number(amt);
            }
        },
        setInfo(state, info)
        {
            state.info = info;
        },
        setReset(state)
        {
            state.list    = [];
            state.payList = getPayList();
            state.dcInfo  = getAmtInfo();
            state.addInfo = getAmtInfo();
            state.info    = getOrderInfo();
        }
    },
    actions : {
        async getList(context, params)
        {
            context.commit('setReset');

            try
            {
                const res = await getAxiosData('https://data.planorder.kr/order/getList', params);

                console.log(res);

                const list = [];
                
                res.data['factory'].map(fa => {
                    list.push({
                        fcCd       : fa.fcCd,
                        title      : fa.faNm,
                        masterStCd : fa.masterStCd,
                        cardLists  : res.data['orderList'].map(order => {
                            let buttonText = '';

                            if(fa.fcCd === order.fcCd)
                            {
                                const rows = [];
                                const tags = [];

                                switch(order.unit)
                                {
                                    case '001':
                                        if(order.division === 'D')
                                        {
                                            rows.push(...order.detail.map(dItem => ({
                                                width   : dItem.width,
                                                height  : dItem.height,
                                                leftQty : dItem.handle === '좌' ? 1 : 0,
                                                rightQty: dItem.handle === '우' ? 1 : 0,
                                                size    : dItem.size + order.unitNm
                                            })));
                                        }
                                        else
                                        {
                                            rows.push({
                                                width   : order.width,
                                                height  : order.height,
                                                leftQty : order.leftCnt,
                                                rightQty: order.rightCnt,
                                                size    : order.totalUnit + order.unitNm
                                            });
                                        }
                                    break;
                                    case '002': case '003':
                                        rows.push({
                                            width   : order.width,
                                            height  : order.height,
                                            proc    : order.proc === '001' ? '나비주름' : '평주름',
                                            split   : order.split === '001' ? '양개' : '편개',
                                            size    : order.totalUnit + order.unitNm
                                        });
                                    break;
                                }

                                if(order.shape === 'Y')
                                {
                                    tags.push({ spanText: "형상옵션" });
                                }

                                if(order.ordGb === 'O')
                                {
                                    /** 외주 공장 카카오톡 발주 */
                                    if(order.detailStCd === '006')
                                    {
                                        buttonText = '발주 완료';
                                    }
                                    else
                                    {
                                        buttonText = '메세지 발주';
                                    }
                                }
                                else
                                {
                                    /** 시스템 공장 발주 버튼 처리 */
                                    switch(order.detailStCd)
                                    {
                                        case '005': /** 발주대기 */
                                            buttonText = '시스템 발주';
                                        break;
                                        case '006': /** 발주완료 */
                                            buttonText = '배송완료';
                                        break;
                                        // case '007': /** 발주취소 */
                                        // break;
                                        // case '008': /** 발주 승인취소 */
                                            
                                        // break;
                                        case '009': /** 발주진행 */
                                            buttonText = '발주 취소';
                                        break;
                                        case '010': /** 발주승인 */
                                            buttonText = '발주 취소 요청';
                                        break;
                                    }
                                }

                                return {
                                    edCd         : order.edCd,
                                    ordGb        : order.ordGb,
                                    detailStCd   : order.detailStCd,
                                    productTitle : order.colorTitle === '' ? '' : order.productTitle,
                                    colorTitle   : order.colorTitle === '' ? order.productTitle : '',
                                    showDelete   : false,
                                    amt          : Number(order.totalSaleAmt) + Number(order.totalSaleTax),
                                    isRed        : true,
                                    columns      : getCardColumns(order.unit),
                                    rows         : rows,
                                    showTag      : tags.length > 0 ? true : false,
                                    tags         : tags,
                                    spanText     : order.memo,
                                    showButton   : true,
                                    buttonText   : `(${fa.faNm})`+ buttonText,
                                    blueButton   : order.ordGb === 'S' && order.detailStCd === '005' ? true : false,
                                    greyButton   : order.detailStCd === '006' ? true : false,
                                    cyanButton   : order.ordGb === 'O' && order.detailStCd === '005' ? true : false,
                                    softOrange   : order.ordGb === 'S' && order.detailStCd === '009' ? true : false,
                                    vividOrange  : order.ordGb === 'S' && order.detailStCd === '010' ? true : false,
                                }
                            }
                        }).filter(Boolean)
                    })
                });

                context.commit('setList', list);
                context.commit('setItemAmt', { name : 'itemAmt',  amt : Number(res.data['itemAmt']) });
                context.commit('setItemAmt', { name : 'itemTax',  amt : Number(res.data['itemTax']) });

                res.data['amtList'].map((amt) => {
                    context.commit('setPayAmt', { amtGb : amt['amtGb'], amt : Number(amt['amt']) });
                });

                context.commit('setInfo', res.data['info']);

                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        }
    }
};

/**
 * @description 명세서 제품 결제 내역
 */
function getPayList()
{
    return [
        { name : 'itemAmt', title: '상품 금액', amt: 0 },
        { name : 'itemTax', title: '부가세', amt: 0 },
        { name : 'shapeAmt', title: '형상 금액', amt: 0 },
        { name : 'heightAmt', title: '세로길이 추가금액', amt: 0 },
        { name : 'addAmt', amtGb : '001', title: '추가', amt: 0, red: true },
        { name : 'dcAmt', amtGb : '002', title: '할인', amt: 0, red: true },
        { name : 'cutAmt', amtGb : '003', title: '절삭 할인', amt: 0, red: true },
        { name : 'conAmt', amtGb : '004', title: '계약 선금', amt: 0, blue: true },
        { name : 'lastAddAmt', amtGb : '005', title: '최종 추가', amt: 0, red: true },
        { name : 'lastDcAmt', amtGb : '006', title: '최종 할인', amt: 0, red: true },
        { name : 'payAmt', amtGb : '007', title: '결제 금액', amt: 0, blue: true }
    ]
}

function getAmtInfo()
{
    return {
        unit : 'F',
        val  : 0,
        amt  : 0,
        memo : ''
    }
}

function getOrderInfo()
{
    return {
        stCd    : '',
        estiDt  : '',
        conDt   : '',
        deliDt  : '',
        insTime : '',
        insUser : ''
    }
}

/**
 * @description 발주 처리 시 정보
 */
function getOrder()
{
    return {
        edCd        : '',
        popStat     : false,
        ordDt       : getConvertDate(new Date(), 'yyyy-MM-dd'),
        outDt       : getConvertDate(getAddDate(3), 'yyyy-MM-dd'),
        shippingGb  : '001',
        zip         : '',
        addr        : '',
        addrDetail  : '',
        memo        : ''
    }
}

/**
 * @description 최종 결제입력 정보
 */
function getPay()
{
    return {
        popStat     : false,
        totalPayAmt : 0,
        payGb       : '001',
        payAmt      : 0,
        memo        : ''
    }
}

export default orderModule;