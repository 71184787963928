<template>
    <div class="relative">
        <input :type="type" :id="id" :name="name" :value="value" :placeholder="placeholder"
               class="pt-3 pb-2.5 pl-3.5 pr-8 border rounded-sm w-full text-sm duration-200" :readonly="readonly"/>
        <FontAwesomeIcon :icon="['fas', 'location-dot']" class="absolute right-3 vertical-h text-neutral-500"/>
        <label :for="id" class="absolute -top-2.5 left-1.5 text-xm text-gray-400 bg-white py-0.5 px-1">{{ label }}<span
                class="text-red-500" v-if="important">*</span></label>
    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name      : "LabelAddress",
    components: {FontAwesomeIcon},
    props     : {
        label      : String,
        type       : String,
        id         : String,
        name       : String,
        value      : String,
        placeholder: String,
        helperText : String,
        readonly   : Boolean,
        important  : Boolean
    },
}
</script>

<style scoped>

</style>