<template>
    <ModalBox title="메시지">
        <div class="flex justify-between items-center mb-7">
            <div class="flex space-x-1.5">
                <button type="button" class="text-brand text-xm font-bold py-1.5 px-2.5 rounded-xl border flex items-center">
                    <FontAwesomeIcon :icon="['fas', 'plus']"/>
                    <span class="block -mb-0.5" @click="popStat=true">고객 선택</span>
                </button>
                <span class="rounded-xl flex items-center py-1 px-1.5 bg-[#FCF2E6] text-[#DBB071] font-bold text-xm" v-if="clientList.length > 0">
                    {{ clientList.length === 1 ? clientList[0]['clientNm'] : clientList[0]['clientNm']+' 외 '+(clientList.length - 1)+'명'  }}
                </span>
            </div>
            <div class="flex justify-between items-center flex-row-reverse">
                <InlineButton buttonText="메시지 저장" blueButton @click="getMsgSaveCheck"/>
                <InlineButton buttonText="메시지 삭제" pinkButton class="mr-2" v-if="type === 'M'" @click="getMsgDelCheck"/>
            </div>
        </div>
        <LabelInput label="문자 제목" id="title" :value="info['title']" type="text" @input="getInputValSet"/>
        <GrayBar class="my-5" />
        <LabelTextarea label="문자 내용" id="contents" :value="info['contents']" rowCount="15" @input="getInputValSet" />

        <div class="absolute bottom-0 left-0 right-0 mx-auto">
            <div class="flex space-x-2 px-4 my-2">
                <button type="button" class="rounded-xl py-1 px-1.5 bg-[#E5F7FB] text-[#6AB2BE] font-bold text-xm">업체명</button>
                <button type="button" class="rounded-xl py-1 px-1.5 bg-[#FCF2E6] text-[#DBB071] font-bold text-xm">담당자명</button>
                <button type="button" class="rounded-xl py-1 px-1.5 bg-[#EEECF9] text-[#9286C9] font-bold text-xm">고객명</button>
            </div>
            <div class="shadow-custom flex pt-2 pb-3.5 px-4 bg-white">
                <BaseButton buttonText="문자메시지 보내기" blueButton @click="getMsgSendCheck"/>
                <img src="@/assets/images/icon-right-top.svg" title="" alt="" class="ml-3.5" @click="getNavi"/>
            </div>
        </div>
    </ModalBox>
    <CustomerChoice v-if="popStat" @getClose="popStat=false"/>
    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp" @getCancle="getCancle"/>    
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox.vue";
import InlineButton from "@/components/button/InlineButton.vue";
import LabelInput from "@/components/input/LabelInput.vue";
import GrayBar from "@/components/common/GrayBar.vue";
import LabelTextarea from "@/components/input/LabelTextarea.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import CustomerChoice from "@/views/message/CustomerChoice.vue";
import AlertBox from "@/components/modal/AlertBox.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { onMounted, computed, ref } from "vue";
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { getAxiosData } from "@/assets/js/function";

const route   = useRoute();
const router  = useRouter();
const store   = useStore();

const info          = computed(() => store.state.msg.info);
const clientList    = computed(() => store.state.msg.clientList);
const type          = route.query.type;
const popStat       = ref(false);

const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const focusId  = ref('');
const resultYn = ref('N');

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
}

const getComp = () => {
    alertData.value['view'] = false;

    switch(resultYn.value)
    {
        case 'Y':
            getMsgSaveResult();
        break;
        case 'S':
            getMsgSendResult();
        break;
        case 'D':
            getMsgDelResult();
        break;
        default:
            if(focusId.value === '')
            {
                focusId.value = 'title';
            }

            document.getElementById(focusId.value).focus();
    }
}

const getCancle = () => {
    resultYn.value = 'N';
    alertData.value['view'] = false;
}

const getInputValSet = () => {
    const val = event.target.value;
    const id  = event.target.id;
    
    info.value[id] = val;
}

const getInputValCheck = () => {
    if(info.value['title'] === '')
    {
        getAlertDataSet(true, '확인', '문자 제목을 입력하세요.', false);
        focusId.value = 'title';
        return false;
    }

    if(info.value['contents'] === '')
    {
        getAlertDataSet(true, '확인', '문자 내용을 입력하세요.', false);
        focusId.value = 'contents';
        return false;
    }

    return true;
}

const getMsgSaveCheck = () => {
    if(!getInputValCheck())
    {
        return false;
    }

    resultYn.value = 'Y';
    getAlertDataSet(true, '확인', '메세지를 저장하시겠습니까?', true);
}

const getMsgSaveResult = async () => {
    const params = {
        type     : type,
        ceCd     : store.state.login.ceCd,
        userCd   : store.state.login.userCd,
        title    : info.value['title'],
        contents : info.value['contents']
    };

    if(type === 'M')
    {
        params['msCd'] = route.query.msCd;
    }

    await getAxiosData('https://data.planorder.kr/msg/getResult', params).then((res) => {
        resultYn.value = 'N';
        if(res['status'] === 200)
        {
            router.go(-1);
        }
        else
        {
            getAlertDataSet(true, '에러', '메세지 저장에 실패하였습니다. 지속될 경우 관리자에게 문의하세요.', false);
        }
    });
}

const getMsgDelCheck = () => {
    resultYn.value = 'D';
    getAlertDataSet(true, '확인', '해당 메세지를 삭제하시겠습니까?', true);
}

const getMsgDelResult = async () => {
    const params = {
        msCd : route.query.msCd
    };

    await getAxiosData('https://data.planorder.kr/msg/getDelete', params).then((res) => {
        resultYn.value = 'N';
        if(res['status'] === 200)
        {
            router.go(-1);
        }
        else
        {
            getAlertDataSet(true, '에러', '메시지 삭제에 실패하였습니다. 지속될 경우 관리자에게 문의하세요.', false);
        }
    });
}

const getMsgSendCheck = () => {
    if(clientList.value.length === 0)
    {
        getAlertDataSet(true, '확인', '전송할 고객을 선택해주세요.', false);
        return false;
    }

    resultYn.value = 'S';
    getAlertDataSet(true, '확인', '선택된 고객에게 문자를 전송하시겠습니까?', true);
}

const getMsgSendResult = async () => {
    const params = {
        ceCd        : store.state.login.ceCd,
        userCd      : store.state.login.userCd,
        title       : info.value['title'],
        contents    : info.value['contents'],
        clientList  : clientList.value
    };

    await getAxiosData('https://data.planorder.kr/msg/getSend', params).then((res) => {
        resultYn.value = 'N';
        if(res['status'] === 200)
        {
            getAlertDataSet(true, '완료', '문자 전송이 완료되었습니다.', false);
        }
        else
        {
            getAlertDataSet(true, '에러', '문자 전송에 실패하였습니다. 지속될 경우 관리자에게 문의하세요.', false);
        }
    });
}

const getNavi = () => {
    window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'share', value: info.value['contents'] }));
}

onMounted(() => {
    store.commit('msg/setReset');
    
    if(type === 'M')
    {
        store.dispatch('msg/getInfo', { msCd : route.query.msCd });
    }
})

</script>

<style scoped>

</style>