import { getAxiosData, getCardColumns } from '@/assets/js/function';
/**
 * @description 외주 발주서 관련 vuex
 */
const outOrderDocModule = {
    namespaced : true, 
    state : {
        ceNm : '',
        faNm : '',
        info : getInfo(),
        list : []
    },
    mutations : {
        setNm(state, { gb, name })
        {
            switch(gb)
            {
                case 'ceNm':
                    state.ceNm = name;
                break;
                case 'faNm':
                    state.faNm = name;
                break;
            }
        },
        setInfo(state, info)
        {
            state.info = info;
        },
        setList(state, list) 
        {
            state.list = list;
        }
    },
    actions : {
        async getOutOrderDoc(context, params)
        {
            try
            {
                const res = await getAxiosData('https://data.planorder.kr/order/getOutOrderDoc', params);
                console.log(res);

                context.commit('setNm', { gb : 'ceNm', name : res.data['info']['ceNm'] });
                context.commit('setNm', { gb : 'faNm', name : res.data['info']['faNm'] });

                const info = [
                    { title: "매장번호", content: res.data['info']['tel'] },
                    { title: "고객명", content: res.data['info']['clientNm'] },
                    { title: "주문일", content: res.data['info']['ordDt'] },
                    { title: "출고일", content: res.data['info']['outDt'] },
                    { title: "배송명", content: `(${res.data['info']['shippingGbNm']})${res.data['info']['addr']+' '+res.data['info']['addrDetail']}` },
                    { title: "요청사항", content: res.data['info']['memo'] }
                ];

                context.commit('setInfo', info);

                const list = [];
                
                res.data['location'].map(location => {
                    list.push({
                        title      : location.title,
                        cardLists  : res.data['outList'].map(order => {
                            if(location.title === order.title)
                            {
                                const rows = [];
                                const tags = [];

                                switch(order.unit)
                                {
                                    case '001':
                                        if(order.division === 'D')
                                        {
                                            rows.push(...order.detail.map(dItem => ({
                                                width   : dItem.width,
                                                height  : dItem.height,
                                                leftQty : dItem.handle === '좌' ? 1 : 0,
                                                rightQty: dItem.handle === '우' ? 1 : 0,
                                                size    : dItem.size + order.unitNm
                                            })));
                                        }
                                        else
                                        {
                                            rows.push({
                                                width   : order.width,
                                                height  : order.height,
                                                leftQty : order.leftCnt,
                                                rightQty: order.rightCnt,
                                                size    : order.totalUnit + order.unitNm
                                            });
                                        }
                                    break;
                                    case '002': case '003':
                                        rows.push({
                                            width   : order.width,
                                            height  : order.height,
                                            proc    : order.proc === '001' ? '나비주름' : '평주름',
                                            split   : order.split === '001' ? '양개' : '편개',
                                            size    : order.totalUnit + order.unitNm
                                        });
                                    break;
                                }

                                if(order.shape === 'Y')
                                {
                                    tags.push({ spanText: "형상옵션" });
                                }

                                return {
                                    productTitle : order.colorTitle === '' ? '' : order.productTitle,
                                    colorTitle   : order.colorTitle === '' ? order.productTitle : '',
                                    showDelete   : false,
                                    amt          : Number(order.totalSaleAmt) + Number(order.totalSaleTax),
                                    isRed        : false,
                                    columns      : getCardColumns(order.unit),
                                    rows         : rows,
                                    showTag      : tags.length > 0 ? true : false,
                                    tags         : tags,
                                    spanText     : order.memo
                                }
                            }
                        }).filter(Boolean)
                    })
                });

                context.commit('setList', list);

                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        }
    }
};

function getInfo()
{
    return [
        { title: "매장번호", content: "" },
        { title: "고객명", content: "" },
        { title: "주문일", content: "" },
        { title: "출고일", content: "" },
        { title: "배송명", content: "" },
        { title: "요청사항", content: "" }
    ]
}

export default outOrderDocModule;