<template>
    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.837343 2.73969L5.36401 7.00008L0.837344 11.2605C0.382344 11.6887 0.382344 12.3805 0.837344 12.8087C1.29234 13.2369 2.02734 13.2369 2.48234 12.8087L7.83734 7.76871C8.29234 7.34047 8.29234 6.64871 7.83734 6.22047L2.48234 1.18047C2.02734 0.752238 1.29234 0.752238 0.837343 1.18047C0.39401 1.60871 0.382343 2.31145 0.837343 2.73969Z" />
    </svg>

</template>

<script>
export default {
    name: "IconNext"
}
</script>

<style scoped>

</style>