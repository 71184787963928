<template>
    <div class="relative inline-block pt-3 pb-2.5 pl-3.5 border rounded-sm text-xs duration-200" :class="[{'border-zinc-200': !error, 'border-red-600': error}, {'pr-3.5': !showUnit, 'pr-8': showUnit}, {'bg-zinc-100': disabled}]">
        <input class="w-full" :type="type" :id="id" :name="name" :value="value" :placeholder="placeholder" :disabled="disabled" :readonly="readonly"/>
        <span v-if="showUnit" class="absolute right-3.5 vertical-h" :class="[{'text-brand': unitBlue, 'text-red-500': unitRed}]" >{{ unit }}</span>
    </div>
</template>

<script>
export default {
    name: "BasicInput",
    props: {
        id         : String,
        label      : String,
        name       : String,
        value      : String,
        type       : String,
        placeholder: String,
        error      : Boolean,
        disabled   : Boolean,
        readonly   : Boolean,
        unit       : String,
        showUnit   : Boolean,
        unitBlue   : Boolean,
        unitRed    : Boolean,
    }
}
</script>

<style scoped>

</style>