<template>
    <div class="space-y-2.5">
        <p @click="toggleShowMore" class="text-right text-xs text-zinc-400">
            {{ moreText }}
            <FontAwesomeIcon :icon="['fas', 'caret-down']" class="ml-2.5 text-zinc-500" />
        </p>

        <template v-if="showMore">
            <slot>
            </slot>
        </template>
    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "MoreCard",
    components: {FontAwesomeIcon},
    props: {
        moreText: String,
    },
    data() {
        return {
            showMore: false
        }
    },
    methods: {
        toggleShowMore(){
            this.showMore = !this.showMore
        }
    }
}
</script>

<style lang="scss" scoped>
</style>