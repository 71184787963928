<template>
    <div class="flex justify-between items-center py-4 border-b">
        <p class="text-sm pl-3.5">{{ title }}</p>
        <FontAwesomeIcon class="px-2.5 text-zinc-500 text-xm" :icon="['fas', 'play']" />
    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "SettingMoreList",
    components: {FontAwesomeIcon},
    props: {
        title: String,
    }
}
</script>

<style scoped>

</style>