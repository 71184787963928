<template>
    <div class="pt-3 pb-2 px-2 bg-neutral-100 rounded-sm text-xm">
        <FontAwesomeIcon :icon="['fas', 'magnifying-glass']"/>
        <input type="search" :id="id" :name="name" :value="value" :placeholder="placeholder" class="bg-inherit pl-2 w-11/12"/>
    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"

export default {
    name      : "SearchBox",
    components: {FontAwesomeIcon},
    props     : {
        id         : String,
        name       : String,
        value      : String,
        placeholder: String,
    }
}
</script>

<style scoped>

</style>