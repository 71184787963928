<template>
    <ModalBox title="매장 회원가입 2/2">
        <GrayBox>
            <p class="text-sm">
            플랜오더에 등록하실 운영 중인 매장시설 정보와 플랜 및 문자메시지 등 플랜오더 이용을 위한 결제수단을 입력해주세요.<br>
            <b>* 안심하세요! 결제수단을 등록해도, 2주 무료체험 기간 동안 절대 결제가 일어나지 않고 언제든 해지 가능해요.</b>
            </p>
            <span class="text-xm">무료체험 기간 동안 이용을 해지하지 않으시면 무료체험 종료일 오전 10시에 자동으로 결제되며, 매월 25일 정기결제가 이루어집니다.</span>
        </GrayBox>
        <div class="space-y-5">
            <LabelInput type="text" id="ceNm" :value="center['ceNm']" label="매장 이름" placeholder="매장이름을 입력해주세요" @input="getInputValSet"/>
            <LabelSelect id="area" name="area" :value="center['area']" :options="area" label="지역" @change="getSelectValSet"/>
            <LabelRadio id="gb" vModel="P" label="사업자 구분" :options="radio" @change="getRadioValSet"/>
            <LabelInput type="number" id="einNum" :value="center['einNum']" label="사업자등록번호" placeholder="-없이 입력해주세요" @input="getInputValSet"/>
            <LabelInput type="text" id="ceoNm" :value="center['ceoNm']" label="사업자등록증상 대표자 이름" placeholder="대표자 이름을 입력해주세요" @input="getInputValSet"/>
            <LabelInput type="text" id="addr" :value="center['addr']" label="주소" placeholder="주소를 검색해주세요" readonly @click="getOpenDaumPost"/>
            <LabelInput type="text" id="addrDetail" :value="center['addrDetail']" label="상세주소" placeholder="상세주소를 검색해주세요" @input="getInputValSet"/>
            <LabelInput type="number" id="ceoTel" :value="center['ceoTel']" label="매장 대표 연락처" placeholder="-없이 입력해주세요" @input="getInputValSet"/>
            <div id="layer" style="display:none;position:fixed;overflow:hidden;z-index:1;-webkit-overflow-scrolling:touch;">
                <img src="//t1.daumcdn.net/postcode/resource/images/close.png" id="btnCloseLayer" style="cursor:pointer;position:absolute;right:-3px;top:-3px;z-index:1" @click="getCloseDaumPost()" alt="닫기 버튼">
            </div>
        </div>
        <BaseButton buttonText="매장시설 정보 제출하기" greyButton @click="getCenterJoinCheck"/>
    </ModalBox>
    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp" @getCancle="getCancle"/>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox";
import GrayBox from "@/components/box/GrayBox";
import LabelInput from "@/components/input/LabelInput";
import LabelSelect from "@/components/input/LabelSelect";
import LabelRadio from "@/components/input/LabelRadio";
import BaseButton from "@/components/button/BaseButton";
import AlertBox from "@/components/modal/AlertBox.vue";

import axios from "axios";
import { onMounted ,computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { getDaumPopupPosition } from "@/assets/js/function";

const store  = useStore();
const router = useRouter();

const area = computed(() => store.state.data.area);

const certified = computed(() => store.state.join.info.certified);
const agree     = computed(() => store.state.join.info.agree);
const login     = computed(() => store.state.join.info.login);
const auth      = computed(() => store.state.join.info.auth);
const center    = computed(() => store.state.join.info.center);

const radio = [
    { id: '1', value: 'P', text: '개인' },
    { id: '2', value: 'L', text: '법인' }
];

const comYn     = ref('N'); /** Y => 회원가입 / N => 일반 / D => 아이디 중복 / S => 회원가입 완료 */
const focusId   = ref('');
const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
};

const getComp = () => {
    alertData.value['view'] = false;

    switch(comYn.value)
    {
        case 'Y':
            getCenterJoinResult();
        break;
        case 'D':
            router.push({ path : '/join/joinFirst', replace : true });
        break;
        case 'S':
            router.push({ path : '/login', replace : true });
        break;
        case 'N':
            if(focusId.value !== '')
            {
                document.getElementById(focusId.value).focus();
            }
        break;
    }
}

const getCancle = () => {
    alertData.value['view'] = false;
}

const getInputValSet = (event) => {
    const id    = event.target.id;
    const value = event.target.value;

    center.value[id] = value;
};

const getSelectValSet = (event) => {
    const id    = event.target.id;
    const value = event.target.value;

    center.value[id] = value;
};

const getRadioValSet = (event) => {
    const value = event.target.value;

    center.value['gb'] = value;
};

const getOpenDaumPost = () => {
    const layer = document.getElementById('layer');

    new window.daum.Postcode({
        oncomplete          : function(data) {
            let addr = '';

            if (data.userSelectedType === 'R') 
            { 
                // 사용자가 도로명 주소를 선택했을 경우
                addr = data.roadAddress;
            } 
            else 
            { 
                // 사용자가 지번 주소를 선택했을 경우(J)
                addr = data.jibunAddress;
            }

            center.value['zip']  = data.zonecode;
            center.value['addr'] = addr;
            document.getElementById("addrDetail").focus();

            // iframe을 넣은 element를 안보이게 한다.
            // (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
            layer.style.display = 'none';
        },
        width               : '100%',
        height              : '100%',
        maxSuggestItems     : 5
    }).embed(layer);

    getDaumPopupPosition(layer);
}

const getCloseDaumPost = () => {
    document.getElementById('layer').style.display = 'none';
}

const getInputCheck = () => {
    let stat = true;

    stat = stat && getValueCheck('ceNm');
    stat = stat && getValueCheck('einNum');
    stat = stat && getValueCheck('ceoNm');
    stat = stat && getValueCheck('addr');
    stat = stat && getValueCheck('ceoTel');

    return stat;
}

const getValueCheck = (id) => {
    let text = '필수 정보가 입력되지 않았습니다.';

    if(center.value[id] === '')
    {
        getAlertDataSet(true, '확인', text, false);
        focusId.value = id;
        return false;
    }

    return true;
};

const getCenterJoinCheck = () => {
    if(!getInputCheck())
    {
        return false;
    }

    getAlertDataSet(true, '회원가입', '입력하신 정보로 회원가입을 진행하시겠습니까?', true);
    comYn.value = 'Y';
};

const getCenterJoinResult = async () => {
    comYn.value = 'N';
    const formData = new FormData();

    const params = {
        id          : login.value['id'],
        pw          : login.value['pw'],
        tel         : auth.value['tel'],
        name        : auth.value['name'],
        ceNm        : center.value['ceNm'],
        area        : center.value['area'] === '' ? area.value[0]['value'] : center.value['area'],
        einGb       : center.value['gb'],
        einNum      : center.value['einNum'],
        ceoNm       : center.value['ceoNm'],
        zip         : center.value['zip'],
        addr        : center.value['addr'],
        addrDetail  : center.value['addrDetail'],
        ceoTel      : center.value['ceoTel'],
        agree1      : agree.value['age']['yn'],
        agree2      : agree.value['use']['yn'],
        agree3      : agree.value['info']['yn'],
        pushYn      : agree.value['choice']['yn'],
        emailYn     : agree.value['choice']['yn'],
        kakaoYn     : agree.value['choice']['yn'],
        smsYn       : agree.value['choice']['yn']
    };
    
    formData.append('request', new Blob([JSON.stringify(params)], {
        type: 'application/json'
    }));

    if(login.value['einFile']['file'] !== null)
    {
        formData.append('fileData', login.value['einFile']['file']);
    }

    try
    {
        await axios.put('https://po-data.plansys.kr/v1/center/join/dataProc', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });

        getAlertDataSet(true, '완료', '회원가입이 완료되었습니다.', false);
        comYn.value = 'S';
    }
    catch(e)
    {
        console.log(e);
        if(e.response.data.data === 'duplicate')
        {
            getAlertDataSet(true, '중복', '이미 가입된 이메일입니다. 이메일을 변경해주세요.', false);
            comYn.value = 'D';
        }
        else
        {
            getAlertDataSet(true, '실패', '회원가입에 실패하였습니다. 지속될 경우 관리자에게 문의하세요.', false);
        }
    }
}


onMounted(() => {
    comYn.value = 'N';
    if(certified.value === 'N')
    {
        router.push({ path : '/join/agree', replace : true });
    }
});
</script>

<style scoped>

</style>