import { getAxiosData, getCardColumns } from '@/assets/js/function';
/**
 * @description 견적 관련 vuex 모듈
 */
const estiModule = {
    namespaced : true, 
    state : {
        common      : getCommonInfo(),
        ea          : getEaInfo(),
        blind       : getBlindInfo(),
        curtain     : getCurtainInfo(),
        total       : getTotalInfo(),
        list        : [],
        payList     : getPayList(),
        dcInfo      : getAmtInfo(),
        addInfo     : getAmtInfo(),
        cutInfo     : {
            gubun : 'N',
            amt   : 0
        },
        conInfo     : getConInfo()  /** 계약서 정보 */
    },
    mutations : {
        setList(state, list) 
        {
            state.list = list;
        },
        setPayAmt(state, { name, amt })
        {
            const item = state.payList.find(item => item.name === name);
            
            if(item)
            {
                item.amt = Number(amt);
            }
        },
        setCommon(state, { name, value }) 
        {
            state.common[name] = value;
        },
        setBlind(state, { name, value}) 
        {
            state.blind[name] = value;
        },
        setCurtain(state, { name, value}) 
        {
            state.curtain[name] = value;
        },
        setAmtInfo(state, { name, info })
        {
            if(name === 'cutInfo')
            {
                state.cutInfo = {
                    gubun : Number(info['amt']) === 0 ? 'N' : 'Y',
                    amt   : Number(info['amt'])
                }
            }
            else
            {
                state[name] = info;
            }
        },
        setEstiIngReset(state)
        {
            state.common = getCommonInfo();
            state.ea     = getEaInfo();
            state.blind  = getBlindInfo();
            state.curtain= getCurtainInfo();
            state.total  = getTotalInfo();
        }
    },
    actions : {
        async getList(context, params)
        {
            try
            {
                const res = await getAxiosData('https://data.planorder.kr/esti/getList', params);

                console.log(res);

                const list = [];
                
                res.data['location'].map(location => {
                    list.push({
                        title     : location.title,
                        cardLists : res.data['estiList'].map(esti => {
                            if(location.title === esti.title)
                            {
                                const rows = [];
                                const tags = [];

                                switch(esti.unit)
                                {
                                    case '001':
                                        if(esti.division === 'D')
                                        {
                                            rows.push(...esti.detail.map(dItem => ({
                                                width   : dItem.width,
                                                height  : dItem.height,
                                                leftQty : dItem.handle === '좌' ? 1 : 0,
                                                rightQty: dItem.handle === '우' ? 1 : 0,
                                                size    : dItem.size + esti.unitNm
                                            })));
                                        }
                                        else
                                        {
                                            rows.push({
                                                width   : esti.width,
                                                height  : esti.height,
                                                leftQty : esti.leftCnt,
                                                rightQty: esti.rightCnt,
                                                size    : esti.totalUnit + esti.unitNm
                                            });
                                        }
                                    break;
                                    case '002': case '003':
                                        rows.push({
                                            width   : esti.width,
                                            height  : esti.height,
                                            proc    : esti.proc === '001' ? '나비주름' : '평주름',
                                            split   : esti.split === '001' ? '양개' : '편개',
                                            size    : esti.totalUnit + esti.unitNm
                                        });
                                    break;
                                }

                                if(esti.shape === 'Y')
                                {
                                    tags.push({ spanText: "형상옵션" });
                                }

                                return {
                                    edCd         : esti.edCd,
                                    productTitle : esti.productTitle,
                                    colorTitle   : esti.colorTitle,
                                    showDelete   : true,
                                    amt          : Number(esti.totalSaleAmt) + Number(esti.totalSaleTax),
                                    isRed        : esti.productTitle === '' ? true : false,
                                    columns      : getCardColumns(esti.unit),
                                    rows         : rows,
                                    showTag      : tags.length > 0 ? true : false,
                                    tags         : tags,
                                    spanText     : esti.memo
                                }
                            }
                        }).filter(Boolean)
                    })
                });

                context.commit('setList', list);
                context.commit('setPayAmt', { name : 'itemAmt',  amt : Number(res.data['itemAmt']) });
                context.commit('setPayAmt', { name : 'itemTax',  amt : Number(res.data['itemTax']) });

                /** 추가 금액 */
                if(res.data['addAmt'])
                {
                    context.commit('setPayAmt', { name : 'addAmt',  amt : Number(res.data['addAmt']['amt']) });
                    context.commit('setAmtInfo', { name : 'addInfo',  info : res.data['addAmt'] });
                }
                else
                {
                    context.commit('setPayAmt', { name : 'addAmt',  amt : 0 });
                    context.commit('setAmtInfo', { name : 'addInfo',  info : getAmtInfo() });
                }

                /** 할인 금액 */
                if(res.data['dcAmt'])
                {
                    context.commit('setPayAmt', { name : 'dcAmt',  amt : Number(res.data['dcAmt']['amt']) });
                    context.commit('setAmtInfo', { name : 'dcInfo',  info : res.data['dcAmt'] });
                }
                else
                {
                    context.commit('setPayAmt', { name : 'dcAmt',  amt : 0 });
                    context.commit('setAmtInfo', { name : 'dcInfo',  info : getAmtInfo() });
                }

                /** 절삭 할인 금액 */
                if(res.data['cutAmt'])
                {
                    context.commit('setPayAmt', { name : 'cutAmt',  amt : Number(res.data['cutAmt']['amt']) });
                    context.commit('setAmtInfo', { name : 'cutInfo',  info : res.data['cutAmt'] });
                }
                else
                {
                    context.commit('setPayAmt', { name : 'cutAmt',  amt : 0 });
                    context.commit('setAmtInfo', { name : 'cutInfo',  info : { gubun : 'N', amt : 0 } });
                }

                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        },
        async getInfo(context, params)
        {
            try
            {
                const res = await getAxiosData('https://data.planorder.kr/esti/getInfo', params);

                console.log(res);

                const common   = res.data['common'];
                const itemInfo = res.data['itemInfo'];

                for (let key in common) 
                {
                    context.commit('setCommon', { name: key, value: common[key] });
                }

                const unit = common['unit'];

                switch(unit)
                {
                    case '001':
                        for (let blind in itemInfo) 
                        {
                            context.commit('setBlind', { name: blind, value: itemInfo[blind] });
                        }
                    break;
                    case '002': case '003':
                        for (let curtain in itemInfo) 
                        {
                            context.commit('setCurtain', { name: curtain, value: itemInfo[curtain] });
                        }
                    break;
                    default:
                }

                if(res.status === 200)
                {
                    return { result: true, msg: 'success' };
                }
                else
                {
                    return { result: false, msg: 'fail' };
                }
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        },
        async getSave(context, params)
        {
            try
            {
                const res = await getAxiosData('https://data.planorder.kr/esti/getSave', params);

                console.log(res);
                if(res.status === 200)
                {
                    return { result: true, msg: 'success' };
                }
                else
                {
                    return { result: false, msg: 'fail' };
                }
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        },
        async getDelete(content, params)
        {
            try
            {
                const res = await getAxiosData('https://data.planorder.kr/esti/getDelete', params);

                console.log(res);
                if(res.status === 200)
                {
                    return { result: true, msg: 'success', cnt : res.data['cnt'] };
                }
                else
                {
                    return { result: false, msg: 'fail', cnt : 'N' };
                }
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail', cnt : 'N' };
            }
        }
    }
};

/**
 * @description 견적 시 공통 정보
 */
function getCommonInfo()
{
    return {
        fcCd     : null,    /** 공장코드 */
        ordGb    : 'S',     /** 주문구분 (S:시스템 공장 / O:외주공장) */
        itemCd   : '',      /** 제품코드 */
        itemNm   : '',      /** 제품명 */
        icCd     : '',      /** 제품 색상코드 */
        icNm     : '',      /** 제품 색상명칭 */
        unit     : '',      /** 제품 단위 코드 */
        unitNm   : '',      /** 제품 단위 명칭 */
        unitSize : '',      /** 제품 최소 사이즈 */
        location : '기타',  /** 위치 */
        width    : '',      /** 가로 */
        height   : '',      /** 세로 */
        saleUnit : 0,       /** 판매 단가 */
        purcUnit : 0,       /** 매입 단가 */
        dcUnit   : '',      /** 할인 구분 */
        dcAmt    : 0,       /** 할인 금액 */
        vat      : 'N',     /** 부가세 여부 */
        memo     : ''       /** 지시사항 */
    }
}

/**
 * @description EA 정보
 */
function getEaInfo()
{
    return {
        qty : '' /** 수량 */
    }
}

/**
 * @description 블라인드 정보
 */
function getBlindInfo()
{
    return {
        division : 1, /** 분할 수 */
        leftQty  : 0, /** 좌 수량 */
        rightQty : 0, /** 우 수량 */
        bQty     : 1, /** 단창일 시 수량 */
        divSpec  : [] /** { width(가로) , height(세로), handle(좌/우 구분), size(단위 크기) } */
    }
}

/**
 * @description 커튼 정보
 */
function getCurtainInfo()
{
    return {
        shapeSaleAmt    : 0,
        shapePurcAmt    : 0,
        proc            : '001', /** 가공방법(나비주름 / 평주름) */
        addColor        : 'O',   /** 색상 구분(원톤 / 투톤) */
        shape           : 'N',   /** 형상 선택(있음 / 없음) */
        split           : '001', /** 분할 구분(양개 / 편개) */
        use             : '2.0', /** 원단 사용량 */
        size            : 0,     /** 크기 */
        cQty            : 1,     /** 수량 */
        los             : 60,    /** 로스 길이 */
        pokSpec         : 150,   /** 폭 일시 스펙 */
        heightLen       : 0,     /** 세로 길이 제한 */
        addPrice        : 0      /** 추가 비율 */
    }
}

/**
 * @description 총 합계 정보
 */
function getTotalInfo()
{
    return {
        totalQty            : 0,    /** 총 수량 */
        totalUnitSize       : 0,    /** 총 단위 */

        totalItemSaleAmt    : 0,    /** 계산된 총 제품 매출 금액 */
        totalItemSaleTax    : 0,    /** 계산된 총 제품 매출 세액 */
        totalItemPurcAmt    : 0,    /** 계산된 총 제품 매입 금액 */
        totalItemPurcTax    : 0,    /** 계산된 총 제품 매입 세액 */

        /** 블라인드 분할 시 사용 */
        eachItemSaleAmt     : 0,    /** 각 제품별 매출 금액 */
        eachItemSaleTax     : 0,    /** 각 제품별 매출 세액 */
        eachItemPurcAmt     : 0,    /** 각 제품별 매입 금액 */
        eachItemPurcTax     : 0,    /** 각 제품별 매입 세액 */

        totalOptionSaleAmt  : 0,    /** 계산된 총 옵션 매출 금액 */
        totalOptionSaleTax  : 0,    /** 계산된 총 옵션 매출 세액 */
        totalOptionPurcAmt  : 0,    /** 계산된 총 옵션 매입 금액 */
        totalOptionPurcTax  : 0,    /** 계산된 총 옵션 매입 세액 */

        totalShapeSaleAmt   : 0,    /** 계산된 총 형상 매출 금액 */
        totalShapeSaleTax   : 0,    /** 계산된 총 형상 매출 세액 */
        totalShapePurcAmt   : 0,    /** 계산된 총 형상 매입 금액 */
        totalShapePurcTax   : 0,    /** 계산된 총 형상 매입 세액 */

        totalHeightSaleAmt  : 0,    /** 계산된 총 세로길이 추가 매출 금액 */
        totalHeightSaleTax  : 0,    /** 계산된 총 세로길이 추가 매출 세액 */
        totalHeightPurcAmt  : 0,    /** 계산된 총 세로길이 추가 매입 금액 */
        totalHeightPurcTax  : 0,    /** 계산된 총 세로길이 추가 매입 세액 */

        // totalDcCalcAmt      : 0,    /** 계산된 총 할인 금액 */
        totalSaleAmt        : 0,    /** 총 매출 금액 */
        totalSaleTax        : 0,    /** 총 매출 세액 */
        totalPurcAmt        : 0,    /** 총 매입 금액 */
        totalPurcTax        : 0     /** 총 매입 세액 */
    }
}

/**
 * @description 명세서 제품 결제 내역
 */
function getPayList()
{
    return [
        {name : 'itemAmt', title: '상품 금액', amt: 0},
        {name : 'itemTax', title: '부가세', amt: 0},
        {name : 'shapeAmt', title: '형상 금액', amt: 0},
        {name : 'heightAmt', title: '세로길이 추가금액', amt: 0},
        {name : 'addAmt', title: '추가', amt: 0, red: true},
        {name : 'dcAmt', title: '할인', amt: 0, red: true},
        {name : 'cutAmt', title: '절삭 할인', amt: 0, red: true},
        // {name : 'conAmt', title: '계약 선금', amt: 0, blue: true},
        // {name : 'lastAddAmt', title: '최종 추가', amt: 0, red: true},
        // {name : 'lastDcAmt', title: '최종 할인', amt: 0, red: true},
        // {name : 'payAmt', title: '결제 금액', amt: 0, blue: true}
    ]
}

/**
 * @description 계약서 정보
 */
function getConInfo()
{
    return {
        conDt   : new Date(),
        deliDt  : '',
        insTime : '',
        person  : '',
        payGb   : '',
        amt     : 0,
        memo    : ''
    }
}

function getAmtInfo()
{
    return {
        unit : 'F',
        val  : 0,
        amt  : 0,
        memo : ''
    }
}

export default estiModule;