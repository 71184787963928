<template>
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.2917 2.16634H16.25V1.12467C16.25 0.551758 15.7812 0.0830078 15.2083 0.0830078C14.6354 0.0830078 14.1667 0.551758 14.1667 1.12467V2.16634H5.83333V1.12467C5.83333 0.551758 5.36458 0.0830078 4.79167 0.0830078C4.21875 0.0830078 3.75 0.551758 3.75 1.12467V2.16634H2.70833C1.55208 2.16634 0.635417 3.10384 0.635417 4.24967L0.625 18.833C0.625 19.9788 1.55208 20.9163 2.70833 20.9163H17.2917C18.4375 20.9163 19.375 19.9788 19.375 18.833V4.24967C19.375 3.10384 18.4375 2.16634 17.2917 2.16634ZM17.2917 17.7913C17.2917 18.3643 16.8229 18.833 16.25 18.833H3.75C3.17708 18.833 2.70833 18.3643 2.70833 17.7913V7.37467H17.2917V17.7913ZM4.79167 9.45801H6.875V11.5413H4.79167V9.45801ZM8.95833 9.45801H11.0417V11.5413H8.95833V9.45801ZM13.125 9.45801H15.2083V11.5413H13.125V9.45801Z"/>
    </svg>
</template>

<script>
export default {
    name: "IconCalender"
}
</script>

<style scoped>

</style>