<template>
    <div class="flex items-center justify-between">
        <label :for="id" class="w-1/3 text-sm text-neutral-500">{{ label }}</label>
        <VueDatePicker v-model="date" :id="id" :name="name" :value="value" :placeholder="placeholder" :disabled="disabled" :format="foramt" :selectDate="true" auto-apply @update:model-value="getDateChange"></VueDatePicker>
    </div>
</template>

<script setup>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import { defineProps, defineEmits, computed, ref } from 'vue';
import { getConvertDate } from '@/assets/js/function';

const emit  = defineEmits(['getDate']);
const props = defineProps({
    id         : String,
    label      : String,
    name       : String,
    value      : String,
    placeholder: String,
    disabled   : Boolean,
    timePicker : Boolean
});

const date   = ref(props.value);
const foramt = computed(() => props.timePicker ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd');

const getDateChange = () => {
    const changeDate = date.value !== null ? getConvertDate(date.value, props.timePicker ? 'yyyy-mm-dd hh:ii' : 'yyyy-mm-dd') : '';

    emit("getDate", props.id, changeDate);
}

</script>

<style>
.dp__pointer{
    border-radius: 0.125rem;
}
</style>