import { getAxiosData } from '@/assets/js/function';
/**
 * @description 메인 화면 데이터 vuex 모듈
 */
const mainModule = {
    namespaced : true, 
    state : {
        clientCnt  : 0,
        stCnt      : getStCnt(),
        factoryCnt : 0,
        kakaoYn    : 'N',
        msgList    : []
    },
    mutations : {
        setClient(state, {clientCnt, stCnt})
        {
            state.stCnt     = getStCnt();
            state.clientCnt = clientCnt;

            const stateStCnt = state.stCnt;

            stateStCnt.forEach((item) => {
                let stItem = stCnt.find(stItem => stItem.stNm === item.label);
                
                if(stItem)
                {
                    item.count = stItem.count;
                }
            });
        },
        setFactory(state, cnt)
        {
            state.factoryCnt = cnt;
        },
        setKakao(state, yn)
        {
            state.kakaoYn = yn;
        },
        setMsgList(state, msgList)
        {
            state.msgList = msgList;
        }
    },
    actions : {
        async getData(context, params)
        {
            const res = await getAxiosData('https://data.planorder.kr/main/getData', params);

            console.log(res);

            context.commit('setClient', { clientCnt : res.data['clientCnt'],  stCnt : res.data['stCnt'] });
            context.commit('setFactory', res.data['factoryCnt']);
            context.commit('setKakao', res.data['kakaoYn']);
            context.commit('setMsgList', res.data['msgList']);
        }
    }
};

function getStCnt()
{
    return [
        { count: "0", label: "견적" },
        { count: "0", label: "발주" },
        { count: "0", label: "시공" },
        { count: "0", label: "결제" },
        { count: "0", label: "A/S" }
    ]
}

export default mainModule;