<template>
    <div class="fixed flex items-center bg-black/60 w-full h-svh top-0 left-0 right-0 mx-auto z-30">
        <div class="mx-auto bg-white w-full min-w-80 h-svh relative">
            <div class="flex py-3.5 px-4 items-center justify-between relative">
                <div class="absolute top-0 left-0 overflow-hidden rounded-b h-64 w-full">
                    <template v-if="props.imgUrl === ''">
                        <img src="@/assets/images/test.png" alt="" title="" class="w-full h-full" />
                    </template>
                    <template v-else>
                        <img :src="path+props.imgUrl" alt="" title="" class="w-full h-full" />
                    </template>
                </div>
                <img src="@/assets/images/btn-prev.svg" alt="이전" title="이전" @click="getClose" class="z-10 bg-white rounded-2xl p-1.5"/>
                <h3 class="text-center text-white w-full font-bold text-sm mt-0.5 z-10">{{ props.title }}</h3>
            </div>
            <div class="section scroll-none mt-52 px-4 pt-4 pb-36">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
    title   : String,
    imgUrl  : String
});

const path = 'https://elasticbeanstalk-ap-northeast-2-627549176645.s3.ap-northeast-2.amazonaws.com';
const emit = defineEmits(['close']);

const getClose = () => {
    emit('close');
}
</script>

<style lang="scss" scoped>
.section {
    height: calc(100vh - 320px);
    overflow: scroll;
}
</style>