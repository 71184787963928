/**
 * @description 로그인 vuex
 */
const loginModule = {
    namespaced : true, 
    state : {
        ceCd   : null,
        token  : null,
        userCd : null
    },
    mutations : {
        setLogin(state, { ceCd, token, userCd })
        {
            state.ceCd = ceCd;
            state.token = token;
            state.userCd = userCd;
        }
    },
    actions : {
        login({ commit }, {ceCd, token, userCd}) 
        {
            commit('setLogin', { ceCd : ceCd, token : token, userCd : userCd });
            localStorage.setItem('login', JSON.stringify({ ceCd : ceCd, token : token, userCd : userCd }));
        },
        logout({ commit })
        {
            commit('setLogin', { ceCd : null, token : null, userCd : null });
            localStorage.setItem('login', JSON.stringify({ ceCd : null, token : null, userCd : null }));
        },
        initializeState({ commit }) 
        {
            const storedLogin = localStorage.getItem('login');
            
            if (storedLogin) 
            {
                const data = JSON.parse(storedLogin);

                commit('setLogin', { ceCd : data['ceCd'], token : data['token'], userCd : data['userCd'] });
            }
        }
    }
};
  
export default loginModule;