<template>
    <div>
        <dl class="flex items-center">
            <dt class="text-sm text-zinc-400">매출</dt>
            <dd class="text-lg ml-3.5 font-bold">{{ getAmt(saleAmt) }}원</dd>
        </dl>
        <dl class="flex items-center">
            <dt class="text-sm text-zinc-400">매입</dt>
            <dd class="text-lg ml-3.5 font-bold">{{ getAmt(purcAmt) }}원</dd>
        </dl>
        <dl class="flex items-center">
            <dt class="text-sm text-zinc-400">마진</dt>
            <dd class="text-lg ml-3.5 font-bold text-green-500">{{ margin }}%</dd>
            <!-- text-green-500 / text-red-500 -->
        </dl>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { getCommas } from '@/assets/js/function'

defineProps({
    saleAmt : String,
    purcAmt : String,
    margin  : String
});

const getAmt = (amt) => {
    return getCommas(Number(amt));
}
</script>

<style scoped>

</style>