<template>
    <ModalBox :title="ceNm+' 발주서'">
        <InfoCard :title="'수신: '+faNm" :info="info" />
        <div class="space-y-5 mt-5">
            <TableCard v-for="(table, index) in list" :key="index" :title="table.title" :cards="table.cardLists"
                   :columns="table.columns" :rows="table.rows" :tags="table.tags" :showTag="table.showTag"
                   :showButton="table.showButton" />
        </div>
    </ModalBox>
    <div class="fixed left-0 right-0 mx-auto max-w-[37.5rem] bottom-0 z-30 bg-white p-4 shadow-[0px_-2px_4px_0px_rgba(0,0,0,0.08)]">
        <div class="space-y-4">
            <div class="flex flex-row-reverse items-center">
                <CheckBox id="" name="" label="엑셀 양식" :checked="true"/>
                <CheckBox id="" name="" label="일반 양식" />
            </div>
            <LabelInput :value="'https://center.planorder.kr/outOrderDoc?cd='+route.query.cd+''" label="발주서 링크" disabled/>
            <BaseButton buttonText="링크 발주" cyanButton />
        </div>
    </div>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox.vue";
import InfoCard from "@/components/card/InfoCard.vue";
import TableCard from "@/components/card/TableCard.vue";
import CheckBox from "@/components/input/CheckBox.vue";
import LabelInput from "@/components/input/LabelInput.vue";
import BaseButton from "@/components/button/BaseButton.vue";

import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const store  = useStore();
const route  = useRoute();

const ceNm   = computed(() => store.state.outOrderDoc.ceNm);
const faNm   = computed(() => store.state.outOrderDoc.faNm);
const info   = computed(() => store.state.outOrderDoc.info);
const list   = computed(() => store.state.outOrderDoc.list);

onMounted(() => {
    store.dispatch('outOrderDoc/getOutOrderDoc', { edCd : atob(route.query.cd) });
});
</script>

<style scoped>

</style>