<template>
    <ModalBox title="제품선택">
        <div class="flex">
            <SelectBox :options="option" :value="search['fcCd']" class="w-1/3 mr-3" @change="getSelect"/>
            <SearchBox :value="search['itemNm']" placeholder="제품명 검색" class="w-2/3" @input="getSearch" @keyup.enter="getList"/>
        </div>

        <div class="space-x-3.5 mt-5 mb-2">
            <InlineButton blueButton buttonText="실측 둘러보기"/>
            <InlineButton blueButton buttonText="커튼 실측" @click="getExample('curtain')"/>
            <InlineButton blueButton buttonText="블라인드 실측" @click="getExample('blind')"/>
        </div>

        <div>
            <ToggleCard v-for="(product, index) in list" :key="index" 
                :title="product.title" :product="product.product" :unit="product.unit" :price="product.price" :colors="product.colorLists" :noUsed="product.noUsed" 
                @show="showProductRegisterModal" @choice="getItemInfo(product.itemCd, $event)"/>
        </div>
    </ModalBox>
</template>

<script setup>
import SearchBox from "@/components/input/SearchBox.vue";
import SelectBox from "@/components/input/SelectBox.vue";
import ModalBox from "@/components/modal/ModalBox.vue";
import InlineButton from "@/components/button/InlineButton.vue";
import ToggleCard from "@/components/card/ToggleCard.vue";

import { onMounted, computed, ref, nextTick } from 'vue';
import { useStore } from 'vuex';

import { defineEmits } from 'vue';

const store  = useStore();

const option = computed(() => store.state.product.option);
const search = computed(() => store.state.product.search);
const list   = computed(() => store.state.product.list);
const info   = computed(() => store.state.product.info);
const exItem = computed(() => store.state.product.exItem);
const common = computed(() => store.state.esti.common);

const emit   = defineEmits(['getClose']);
const showProductRegister = ref(false);

const getSelect = () => {
    search.value['fcCd'] = event.target.value;
    getList();
};

const getSearch = (event) => {
    search.value['itemNm'] = event.target.value;
};

const getExample = (type) => {
    const item = exItem.value[type];

    for (const data in item) {
        common.value[data] = item[data];
    }

    emit("getClose", 'select');
}

const showProductRegisterModal = () => {
    showProductRegister.value = !showProductRegister.value;
}

const getItemInfo = async (itemCd, icCd) => {
    const params = {
        ceCd   : store.state.login.ceCd,
        fcCd   : search.value['fcCd'],
        itemCd : itemCd, 
        icCd   : icCd
    };

    await store.dispatch('product/getInfo', params);

    nextTick(() => {
        for (const data in info.value) {
            common.value[data] = info.value[data];
        }

        emit("getClose", 'select');
    });
}

const getList = () => {
    const params = {
        ceCd        : store.state.login.ceCd,
        fcCd        : search.value['fcCd'],
        itemNm      : search.value['itemNm'],
        start       : search.value['start']
    };

    store.dispatch('product/getList', params);
}

onMounted(() => {
    store.dispatch('product/getFactory', { ceCd : store.state.login.ceCd });
});

</script>

<style scoped>

</style>