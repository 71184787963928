<template>
    <ModalBox title="고객등록" showFixedButton showButton buttonText="명세서 이동" @close="getClose" @blueBtnClick="getClientCheck">
        <div class="space-y-6">
            <LabelDate id="estiDt" :value="info['estiDt']" :timePicker="true" label="일자" @getDate="getDate"/>
            <LabelInput id="clientNm" label="고객명" :value="info['clientNm']" placeholder="고객명" type="text" @input="getInputValSet" />
            <LabelInput id="tel" label="전화번호" :value="info['tel']" placeholder="010-0000-0000" type="text" @input="getInputValSet" />
            <LabelAddress id="addr" type="text" label="주소" :value="info['addr']" readonly @click="getOpenDaumPost"/>
            <LabelInput id="addrDetail" label="상세주소" :value="info['addrDetail']" placeholder="상세주소 입력" type="text" @input="getInputValSet" />
            <LabelSelect id="person" label="담당자" :options="person" @change="getSelect"/>
            <LabelSelect id="groupCd" label="그룹" :options="group" @change="getSelect"/>
            <LabelInput v-if="info['groupCd'] === ''" id="groupNm" label="그룹명" placeholder="그룹명 입력" type="text" @input="getInputValSet" />
            <div id="layer" style="display:none;position:fixed;overflow:hidden;z-index:1;-webkit-overflow-scrolling:touch;">
                <img src="//t1.daumcdn.net/postcode/resource/images/close.png" id="btnCloseLayer" style="cursor:pointer;position:absolute;right:-3px;top:-3px;z-index:1" @click="getCloseDaumPost()" alt="닫기 버튼">
            </div>
        </div>
    </ModalBox>
    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp" @getCancle="getCancle"/>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox.vue";
import LabelInput from "@/components/input/LabelInput.vue";
import LabelSelect from "@/components/input/LabelSelect.vue";
import LabelAddress from "@/components/input/LabelAddress.vue";
import LabelDate from "@/components/input/LabelDate.vue";
import AlertBox from "@/components/modal/AlertBox.vue";
import { getAxiosData, getDaumPopupPosition } from "@/assets/js/function";

import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

const store  = useStore();
const route  = useRoute();
const router = useRouter();

const info   = computed(() => store.state.client.info);
const person = computed(() => store.state.client.person);
const group  = computed(() => store.state.client.group);

const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const focusId  = ref('');
const resultYn = ref('N');

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
}

const getComp = () => {
    alertData.value['view'] = false;

    if(resultYn.value === 'Y')
    {
        getClientResult();
    }
    else
    {
        if(focusId.value === '')
        {
            focusId.value = 'clientNm';
        }

        document.getElementById(focusId.value).focus();
    }
}

const getCancle = () => {
    alertData.value['view'] = false;
}

const getDate = (id, date) => {
    info.value[id] = date;
}

const getInputValSet = (event) => {
    const id    = event.target.id;
    const value = event.target.value;

    info.value[id] = value;
}

const getSelect = (event) => {
    const id    = event.target.id;
    const value = event.target.value;

    info.value[id] = value;
}

const getOpenDaumPost = () => {
    const layer = document.getElementById('layer');

    new window.daum.Postcode({
        oncomplete          : function(data) {
            let addr = '';

            if (data.userSelectedType === 'R') 
            { 
                // 사용자가 도로명 주소를 선택했을 경우
                addr = data.roadAddress;
            } 
            else 
            { 
                // 사용자가 지번 주소를 선택했을 경우(J)
                addr = data.jibunAddress;
            }

            info.value['zip']  = data.zonecode;
            info.value['addr'] = addr;
            document.getElementById("addrDetail").focus();

            // iframe을 넣은 element를 안보이게 한다.
            // (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
            layer.style.display = 'none';
        },
        width               : '100%',
        height              : '100%',
        submitMode          : false, // 뒤로가기 히스토리 남기지 않도록 설정
        maxSuggestItems     : 5
    }).embed(layer);

    getDaumPopupPosition(layer);
}

const getCloseDaumPost = () => {
    document.getElementById('layer').style.display = 'none';
}

const getValCheck = () => {
    if(info.value['clientNm'] === '')
    {
        getAlertDataSet(true, '확인', '고객명을 입력해주세요.', false);
        focusId.value = 'clientNm';
        return false;
    }

    if(info.value['tel'] === '')
    {
        getAlertDataSet(true, '확인', '전화번호를 입력해주세요.', false);
        focusId.value = 'tel';
        return false;
    }

    if(info.value['addr'] === '')
    {
        getAlertDataSet(true, '확인', '주소를 검색하세요.', false);
        return false;
    }

    if(info.value['groupCd'] === '' && info.value['groupNm'] === '')
    {
        getAlertDataSet(true, '확인', '그룹명을 입력하세요.', false);
        focusId.value = 'groupNm';
        return false;
    }

    return true;
}

const getClientCheck = () => {
    if(getValCheck())
    {
        getAlertDataSet(true, '저장', '저장하시겠습니까?', true);
        resultYn.value = 'Y';
    }
}

const getClientResult = async () => {
    try
    {
        const params = {
            'type'          : route.query.type,
            'ceCd'          : store.state.login.ceCd,
            'userCd'        : store.state.login.userCd,
            'estiDt'        : info.value['estiDt'],
            'clientNm'      : info.value['clientNm'],
            'tel'           : info.value['tel'],
            'zip'           : info.value['zip'],
            'addr'          : info.value['addr'],
            'addrDetail'    : info.value['addrDetail'],
            'person'        : info.value['person'] === '' ? person.value[0]['value'] : info.value['person'],
            'groupCd'       : info.value['groupCd'],
            'groupNm'       : info.value['groupNm']
        };

        console.log(params);

        const res    = await getAxiosData('https://data.planorder.kr/client/getResult', params);

        if(res.status === 200)
        {
            if(route.query.type === 'I')
            {
                router.push({ path : '/customer/estiIng', query : { clientCd : res.data['clientCd'], emCd : res.data['emCd'], type : 'I' }, replace : true});
            }
            else
            {
                router.push({ path : '/customer/detail', query : { clientCd : res.data['clientCd'] }, replace : true});
            }
        }

        resultYn.value = 'N';
        return { result: true, msg: 'success' };
    }
    catch(e)
    {
        console.log(e);
        if(e.response.data.status === 409)
        {
            getAlertDataSet(true, '에러', e.response.data.data, false);
        }
        else
        {
            getAlertDataSet(true, '에러', '고객 저장 중 에러가 발생했습니다. 지속될 경우 관리자에게 문의하세요.', false);
        }

        resultYn.value = 'N';
        return { result: false, msg: 'error' };
    }
};

const getClose = () => {
    router.go(-1);
}

onMounted(() => {
    store.dispatch('client/getData', { ceCd : store.state.login.ceCd});
});

</script>

<style scoped>

</style>