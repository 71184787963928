import axios from "axios";
import store from '@/store';
import { getAxiosData, getCommas } from '@/assets/js/function';

/**
 * @description 제품 선택 vuex 모듈
 */
const productModule = {
    namespaced : true, 
    state : {
        option : [
            { label: "브랜드_검색", value: "" }
        ],
        search : {
            fcCd    : '',
            itemNm  : '',
            start   : 0
        },
        list   : [],
        info   : getItemInfo(),
        exItem : {
            blind   : getExBlindInfo(),
            curtain : getExCurtainInfo()
        }
    },
    mutations : {
        setAddOption(state, option)
        {
            option.forEach(item2 => {
                if (!state.option.some(item1 => item1.label === item2.label)) 
                {
                    state.option.push(item2);
                }
            });
        },
        setList(state, list)
        {
            state.list = list;
        },
        setInfo(state, info)
        {
            state.info = info;
        }
    },
    actions : {
        async getFactory(context, params)
        {
            try
            {
                const res = await getAxiosData('https://data.planorder.kr/esti/getFactory', params);
                context.commit('setAddOption', res.data);
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        },
        async getList(context, params)
        {
            try
            {
                const res      = await getAxiosData('https://data.planorder.kr/esti/getItemList', params);
                const itemList = [];
                console.log(res);

                if(res.data['fcGb'] === 'S')
                {
                    const token     = store.state.login.token;
                    const instance  = axios.create({
                        headers         : {
                            'Content-Type'  : 'application/json',
                            'Authorization' : 'Bearer ' + token
                        }
                    });

                    const apsParams = {
                        'ceCd'      :  res.data['ceCd'],
                        'faCd'      :  res.data['faCd']
                    };

                    const apsRes = await instance.post('https://po-data.plansys.kr/v1/center/biz/onlineFac/getItemList', JSON.stringify(apsParams));

                    apsRes.data['data'].map(item => {
                        const amt = res.data['amtList'].find(amt => amt.itemCd === item.itemCd);
                        const colorLists = [];
    
                        item.icList.map(ic => {
                            colorLists.push({
                                cd       : ic.icCd,
                                label    : ic.icNm,
                                name     : 'icCd'+item.itemCd,
                                disabled : ic.useYn === 'Y' ? false : true
                            })
                        })
    
                        itemList.push({
                            itemCd      : item.itemCd,
                            title       : item.itemNm,
                            unit        : item.unitNm,
                            price       : amt ? getCommas(Number(amt['saleAmt'])) : getCommas(Number(item.clientAmt)),
                            colorLists  : colorLists,
                            noUsed      : item.useYn === 'N' ? true : false
                        })
                    });

                    context.commit('setList', itemList);
                }
                else
                {
                    res.data['list'].map(item => {
                        const colorLists = [];
    
                        item.icList.map(ic => {
                            colorLists.push({
                                cd       : ic.icCd,
                                label    : ic.icNm,
                                name     : 'icCd'+item.itemCd,
                                disabled : ic.useYn === 'Y' ? false : true
                            })
                        })
    
                        itemList.push({
                            itemCd      : item.itemCd,
                            title       : item.itemNm,
                            unit        : item.unitNm,
                            price       : getCommas(Number(item.saleAmt)),
                            colorLists  : colorLists,
                            noUsed      : item.useYn === 'N' ? true : false
                        })
                    });
    
                    context.commit('setList', itemList);
                }
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        },
        async getInfo(context, params)
        {
            try
            {
                const res = await getAxiosData('https://data.planorder.kr/esti/getItemInfo', params);

                if(res.data['fcGb'] === 'S')
                {
                    const token     = store.state.login.token;
                    const instance  = axios.create({
                        headers         : {
                            'Content-Type'  : 'application/json',
                            'Authorization' : 'Bearer ' + token
                        }
                    });

                    const apsParams = {
                        'ceCd'      :  res.data['ceCd'],
                        'faCd'      :  res.data['faCd'],
                        'itemCd'    :  params['itemCd'],
                        'icCd'      :  params['icCd']
                    };

                    const apsRes = await instance.post('https://po-data.plansys.kr/v1/center/biz/onlineFac/getItemInfo', JSON.stringify(apsParams));

                    const info = {
                        fcCd        : params['fcCd'],
                        ordGb       : 'S',
                        itemCd      : apsRes.data['data']['itemCd'],
                        itemNm      : apsRes.data['data']['itemNm'],
                        icCd        : apsRes.data['data']['icCd'],
                        icNm        : apsRes.data['data']['icNm'],
                        unit        : apsRes.data['data']['unit'],
                        unitNm      : apsRes.data['data']['unitNm'],
                        unitSize    : apsRes.data['data']['unitSize'],
                        saleUnit    : Number(res.data['amt']) === 0 ? apsRes.data['data']['saleUnit'] : Number(res.data['amt']),
                        purcUnit    : apsRes.data['data']['purcUnit']
                    }

                    context.commit('setInfo', info);
                }
                else
                {
                    context.commit('setInfo', res.data['info']);
                }

                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        }
    }
};

/**
 * @description 제품 데이터
 */
function getItemInfo()
{
    return {
        itemCd      : '',
        itemNm      : '',
        icCd        : '',
        icNm        : '',
        unit        : '',
        unitNm      : '',
        unitSize    : 0,
        saleUnit    : 0,
        purcUnit    : 0
    }
}

/**
 * @description 커튼 실측 데이터
 */
function getExCurtainInfo()
{
    return {
        itemCd      : 'EX000001',
        itemNm      : '커튼 실측',
        icCd        : '',
        icNm        : '',
        unit        : '003',
        unitNm      : '폭',
        unitSize    : 0,
        saleUnit    : 0,
        purcUnit    : 0
    }
}

/**
 * @description 블라인드 실측 데이터
 */
function getExBlindInfo()
{
    return {
        itemCd      : 'EX000002',
        itemNm      : '블라인드 실측',
        icCd        : '',
        icNm        : '',
        unit        : '001',
        unitNm      : '회베',
        unitSize    : 0,
        saleUnit    : 0,
        purcUnit    : 0
    }
}

export default productModule;