import { getAxiosData, getCommas, getConvertDate } from '@/assets/js/function';
/**
 * @description 고객 관련 vuex 모듈
 */
const clientModule = {
    namespaced : true, 
    state : {
        search : {
            stCd     : '',
            text     : '',
            start    : 0
        },
        list   : [],
        info   : getClientInfo(),
        person : [],
        group  : [ 
            { label: '신규입력', value: '' }
        ],
        detailInfo : getClientDetailInfo()
    },
    mutations : {
        setList(state, list)
        {
            if(state.search.start > 0)
            {
                state.list.push(...list);
            }
            else
            {
                state.list = list;
            }
        },
        setData(state, data)
        {
            state.person = data.person;
            state.group.push(...data.group);
        },
        setDetailInfo(state, data)
        {
            state.detailInfo = data;
        },
        setReset(state)
        {
            state.info   = getClientInfo();
            state.person = [];
            state.group  = [ 
                { label: '신규입력', value: '' }
            ];
        }
    },
    actions : {
        async getList(context, data)
        {
            try
            {
                const list = [];
                const res  = await getAxiosData('https://data.planorder.kr/client/getList', data);

                res.data.map(item => {
                    let step = 1;

                    switch(item.stCd)
                    {
                        case '001':
                            step = 1;
                        break;
                        case '002':
                            step = 2;
                        break;
                        case '003':
                            step = 3;
                        break;
                        case '006':
                            step = 4;
                        break;
                        case '011':
                            step = 5;
                        break;
                        case 'N':
                            step = 6;
                        break;
                    }

                    list.push({
                        step     : step,
                        clientCd : item.clientCd,
                        clientNm : item.clientNm,
                        tel      : item.tel,
                        addr     : item.addr + ' '+ item.addrDetail,
                        date     : getConvertDate(new Date(item.regDt), 'yy.mm.dd'),
                        amt      : getCommas(Number(item.amt))
                    });
                })
    
                context.commit('setList', list);
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.log(e);
                return { result: false, msg: 'error' };
            }
        },
        async getData(context, data)
        {
            context.commit('setReset');

            try
            {
                const res = await getAxiosData('https://data.planorder.kr/client/getData', data);

                console.log(res);

                context.commit('setData', res.data);
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.log(e);
                return { result: false, msg: 'error' };
            }
        },
        async getDetail(context, data)
        {
            try
            {
                const res = await getAxiosData('https://data.planorder.kr/client/getDetail', data);

                const addr   = `(${res.data['client']['zip']}) `+ res.data['client']['addr'];
                const client = [
                    { title: "전화번호", name : 'tel',  content: res.data['client']['tel'], background: true },
                    { title: "주소", name : 'addr', content: addr, background: true },
                    { title: "등록일", name : 'regDt', content: res.data['client']['regDt'] },
                    { title: "상세주소", name : 'addrDetail', content: res.data['client']['addrDetail'] },
                    { title: "담당자", name : 'person', content: res.data['client']['person'] },
                    { title: "그룹명", name : 'groupNm', content: res.data['client']['groupNm'] },
                ];

                const cnt = [
                    { count: res.data['cnt']['ing'], label: "판매중", gubun : 'I' },
                    { count: res.data['cnt']['comp'], label: "판매완료", gubun : 'S' },
                    { count: res.data['cnt']['cancel'], label: "판매취소", gubun : 'C' }
                ];

                context.commit('setDetailInfo', { clientNm : res.data['client']['clientNm'], client: client, cnt: cnt, list: res.data['list'] });
            }
            catch(e)
            {
                console.log(e);
                return { result: false, msg: 'error' };
            }
        }
    }
};

function getClientInfo()
{
    return {
        estiDt      : getConvertDate(new Date(), 'yyyy-mm-dd hh:ii'),
        clientNm    : '',
        tel         : '',
        zip         : '',
        addr        : '',
        addrDetail  : '',
        person      : '',
        groupCd     : '',
        groupNm     : ''
    }
}

function getClientDetailInfo()
{
    return {
        clientNm : '',
        client   : [
            { title: "전화번호", content: "", background: true },
            { title: "주소", content: "", background: true },
            { title: "등록일", content: "" },
            { title: "상세주소", content: "" },
            { title: "담당자", content: "" },
            { title: "그룹명", content: "" },
        ],
        cnt      : [
            { count: 0, label: "판매중" },
            { count: 0, label: "판매완료" },
            { count: 0, label: "판매취소" }
        ],
        list : []
    }
}
  
export default clientModule;