import { getAxiosData, getCommas, getConvertDate } from '@/assets/js/function';
/**
 * @description 통계 관련 vuex
 */
const averageModule = {
    namespaced : true, 
    state : {
        searchDt    : getConvertDate(new Date(), 'yyyymm'),
        totalAmt    : 0,
        summary     : {
            percent : getPercent(),
            list    : [
                { title: "매출", count: 0, color: "blue" },
                { title: "매입", count: 0, color: "yellow" },
                { title: "비용", count: 0, color: "pink" }
            ]
        },
        clientRank  : [],
        payGb       : {
            percent : getPercent(),
            list    : [
                { title: "신용카드", count: 0, color: "blue" },
                { title: "계좌이체", count: 0, color: "yellow" },
                { title: "현금", count: 0, color: "green" }
            ]
        },
        keyword     : {
            chart : {
                labels: ['블라인드', '커튼'],
                datasets: [
                    {
                        backgroundColor: ['#78BBFF', '#7CE5AC'],
                        data: [50, 50]
                    }
                ]
            },
            list  : [
                { title: "블라인드 (0개)", count: 0, color: "blue" },
                { title: "커튼 (0개)", count: 0, color: "green" },
            ]
        }
    },
    mutations : {
        setAmt(state, amt)
        {
            state.totalAmt = amt;
        },
        setList(state, { gb, list })
        {
            state[gb].list = list;
        },
        setPercent(state, { gb, percent })
        {
            state[gb].percent = percent;
        },
        setClientRank(state, list)
        {
            state.clientRank = list;
        }
    },
    actions : {
        async getData(context, params)
        {
            try
            {
                const res  = await getAxiosData('https://data.planorder.kr/average/getData', params);

                console.log(res);

                context.commit('setAmt', getCommas(res.data['totalAmt']));

                const totalSummary   = Object.values(res.data['summary']).reduce((acc, curr) => Number(acc) + Number(curr));
                const summaryPercent = []; 

                let summaryPer       = 0;
                
                for (const [key, value] of Object.entries(res.data['summary'])) 
                {
                    const object = {};

                    summaryPer += totalSummary !== 0 ? Math.round((value / totalSummary) * 100) : 0;
                    object['title'] = totalSummary !== 0 ? Math.round((value / totalSummary) * 100) : 0;

                    switch(key)
                    {
                        case 'saleAmt':
                            object['color'] = 'blue';
                        break;
                        case 'purcAmt':
                            object['color'] = 'yellow';
                        break;
                        case 'fixedAmt':
                            object['color'] = 'pink';
                        break;
                    }

                    summaryPercent.push(object);
                }

                if(summaryPer !== 100) 
                {
                    const diff      = 100 - summaryPer;
                    const maxIndex  = summaryPercent.reduce((maxIndex, current, currentIndex, array) => {
                        return current.title > array[maxIndex].title ? currentIndex : maxIndex;
                    }, 0);

                    summaryPercent[maxIndex].title += diff;
                }

                context.commit('setPercent', { gb : 'summary', percent : summaryPercent});

                const summaryList = [
                    { title: "매출", count: getCommas(Number(res.data['summary']['saleAmt'])), color: "blue" },
                    { title: "매입", count: getCommas(Number(res.data['summary']['purcAmt'])), color: "yellow" },
                    { title: "비용", count: getCommas(Number(res.data['summary']['fixedAmt'])), color: "pink" }
                ];

                context.commit('setList', { gb : 'summary', list : summaryList});

                const clientRank = [];

                res.data['clientRank'].map(item => {
                    clientRank.push({
                        name  : item.clientNm,
                        count : getCommas(Number(item.amt))
                    });
                });

                context.commit('setClientRank', clientRank);

                const totalPayGbAmt = res.data['payGb'].reduce((acc, curr) => acc + Number(curr.amt), 0);
                const payGbPercent  = []; 
                
                let totalPayGbPer   = 0;
                
                for (const item of res.data['payGb']) 
                {
                    const object = {};
                    
                    const percentage = totalPayGbAmt !== 0 ? Math.round((Number(item.amt) / totalPayGbAmt) * 100) : 0;

                    totalPayGbPer += percentage;

                    object['title'] = totalPayGbAmt !== 0 ? Math.round((Number(item.amt) / totalPayGbAmt) * 100) : 0;

                    switch(item.codeSub) 
                    {
                        case '001':
                            object['color'] = 'blue';
                        break;
                        case '002':
                            object['color'] = 'yellow';
                        break;
                        case '003':
                            object['color'] = 'green';
                        break;
                    }
                
                    payGbPercent.push(object);
                }

                if(totalPayGbPer !== 100) 
                {
                    const diff      = 100 - totalPayGbPer;
                    const maxIndex  = payGbPercent.reduce((maxIndex, current, currentIndex, array) => {
                        return current.percentage > array[maxIndex].percentage ? currentIndex : maxIndex;
                    }, 0);

                    payGbPercent[maxIndex].percentage += diff;
                }

                context.commit('setPercent', { gb : 'payGb', percent : payGbPercent});

                const payGbList = [
                    { title: res.data['payGb'][0]['codeNm']+` ${res.data['payGb'][0]['cnt']}회`, count: getCommas(Number(res.data['payGb'][0]['amt'])), color: "blue" },
                    { title: res.data['payGb'][1]['codeNm']+` ${res.data['payGb'][1]['cnt']}회`, count: getCommas(Number(res.data['payGb'][1]['amt'])), color: "yellow" },
                    { title: res.data['payGb'][2]['codeNm']+` ${res.data['payGb'][2]['cnt']}회`, count: getCommas(Number(res.data['payGb'][2]['amt'])), color: "green" }
                ];

                context.commit('setList', { gb : 'payGb', list : payGbList});

                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.log(e);
                return { result: false, msg: 'error' };
            }
        },
    }
};

function getPercent()
{
    return [
        { title: 0, color: 'blue' },
        { title: 0, color: 'yellow' },
        { title: 0, color: 'pink' }
    ]
}

export default averageModule;