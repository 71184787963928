<template>
    <div class="relative block w-full">
        <label class="text-sm text-zinc-400">{{ label }}</label>
        <div class="rounded-lg bg-neutral-100 p-1 inline-block">
            <div v-for="(option, index) in options" :key="index" class="inline-block min-w-16">
                <input type="radio" :id="option.id" :name="name" :value="option.value" v-model="checkedValue" class="hidden peer/radio" />
                <label :for="option.id" class="text-xs font-semibold text-center py-1.5 px-3 rounded-lg block text-neutral-500 peer-checked/radio:bg-white peer-checked/radio:text-brand peer-checked/radio:shadow-md">
                    {{ option.text }}
                </label>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name      : "LabelRadio",
    props     : {
        id            : String,
        name          : String,
        label         : String,
        important     : Boolean,
        useTooltip    : Boolean,
        tooltipContent: String,
        vModel        : String,
        options       : Array,
    },
    data() {
        return {
            checkedValue: this.vModel
        }
    },
    watch: {
        vModel(newValue) {
            this.checkedValue = newValue;
        }
    }
}
</script>

<style scoped>

</style>