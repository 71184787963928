<template>
    <div class="relative flex text-center text-xs rounded-sm border"
         :class="{'border-zinc-200': !error, 'border-red-600': error}">
        <button @click="numberDecrease" class="w-1/4 border-r text-xs">
            <FontAwesomeIcon :icon="['fas', 'minus']"/>
        </button>
        <input type="number" :id="id" :name="name" :value="value" class="w-1/2 text-center font-bold text-sm"/>
        <button @click="numberIncrease" class="w-1/4 border-l text-xs">
            <FontAwesomeIcon :icon="['fas', 'plus']"/>
        </button>
        <label :for="id" class="absolute -top-2.5 left-1.5 text-xm text-gray-400 bg-white py-0.5 px-1">{{ label }}<span class="text-red-500" v-if="important">*</span></label>
    </div>
</template>

<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { defineProps, defineEmits } from 'vue';

defineProps({
    id       : String,
    name     : String,
    value    : Number,
    label    : String,
    error    : {
        type   : Boolean,
        default: false,
    },
    important: Boolean
});

const emit = defineEmits(['getComp', 'getCancle']);

const numberDecrease = () => {
    emit("getQtyMinus");
}
const numberIncrease = () => {
    emit("getQtyPlus");
}

</script>

<style lang="scss" scoped>

</style>