<template>
    <div class="block xl:hidden container mx-auto">
        <div class="px-4 w-full">
            <div class="flex py-2.5">
                <SelectBox :options="clientStat" class="w-1/3 mr-3" @change="getStChange"/>
                <SearchBox :id="'clientNm'" :value="client['search']['text']" placeholder="이름, 주소, 전화번호 검색" :class="'w-2/3'" @keyup.enter="getSearch"/>
            </div>
            <div>
                <ColorList :clients="client.list" />
            </div>
        </div>
        <FixedButton buttonText="신규 등록" @click="getCustomerRegister" />
    </div>
    <div class="hidden xl:block w-full">
        <div class="w-full border-b px-12 pt-10 pb-5 flex items-center justify-between">
            <div class="flex items-center space-x-5">
                <div class="relative">
                    <FontAwesomeIcon :icon="['fas', 'magnifying-glass']" class="absolute vertical-h left-4 text-base text-gray-400"/>
                    <input type="search" id="" name="" class="w-60 border border-neutral-200 bg-neutral-50 pl-12 pr-4 py-2 rounded text-xs" placeholder="고객명,주소,전화번호 검색" />
                </div>
                <select class="w-28 border border-neutral-200 bg-neutral-50 px-4 py-2 rounded text-xs">
                    <option>상태_전체</option>
                </select>
                <select class="w-28 border border-neutral-200 bg-neutral-50 px-4 py-2 rounded text-xs">
                    <option>그룹_전체</option>
                </select>
            </div>
            <button type="button" class="bg-blue-600 text-white rounded border border-blue-600 px-5 pt-2 pb-1.5 text-sm font-bold">고객 신규 등록</button>
        </div>
        <div class="w-full height overflow-y-scroll scroll-none">
            <StatusTable :columns="columns" :rows="rows" />
        </div>
    </div>
</template>

<script setup>
import {onMounted, computed} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import SelectBox from "@/components/input/SelectBox.vue";
import SearchBox from "@/components/input/SearchBox.vue";
import ColorList from "@/components/list/ColorList.vue";
import FixedButton from "@/components/button/FixedButton.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import StatusTable from "@/components/table/StatusTable.vue";

const store  = useStore();
const router = useRouter();

const clientStat = computed(() => store.state.data.clientStat);
const client     = computed(() => store.state.client);

const getStChange = (event) => {
    const value = event.target.value;

    client.value['search']['stCd'] = value;

    getList();
}

const getSearch = (event) => {
    const value = event.target.value;

    client.value['search']['text'] = value;

    getList();
}

const getList = () => {
    const params = {
        ceCd        : store.state.login.ceCd,
        stCd        : client.value['search']['stCd'],
        search      : client.value['search']['text'],
        start       : client.value['search']['start']
    };

    store.dispatch('client/getList', params);
};

const getScroll = () => {
    const scrollTop    = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight)
    {
        console.log("스크롤이 최하단에 도달했습니다.");
    }
}

onMounted(() => {
    client.value['search']['stCd']  = '';
    client.value['search']['start'] = 0;
    client.value['search']['text']  = '';

    getList();

    document.addEventListener('scroll', () => {
        document.addEventListener('scroll', getScroll);
    });
});

const getCustomerRegister = () => {
    router.push({ path: '/customer/register', query : { type : 'I' }});
};

const columns = [
    { header: "상태", key: "status", class: "w10" },
    { header: "고객명", key: "cusNm", class: "w9" },
    { header: "주소", key: "address", class: "w18" },
    { header: "상세 주소", key: "adDt", class: "w18" },
    { header: "전화번호", key: "tel", class: "w12" },
    { header: "등록일", key: "Dt", class: "w12" },
    { header: "그룹", key: "group", class: "w12" },
    { header: "총 매출액", key: "totalAMt", class: "w9" },
]

const rows = [
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
    { status: "견적", cusNm: "홍길동", address: "부산광역시 수영구 수영로 411-1", adDt: "광안자이 아파트 2단지 102동 1004호", tel: "010-3445-2105", Dt: "2022.07.14(목)", group: "공동구매", totalAMt: "99,999,999" },
]
</script>

<style lang="scss" scoped>
.height{
    height: calc(100vh - 150px);
}
</style>