<template>
    <ToggleBox title="발주 정보" showFixedButton blueButtonText="시스템 발주" showBlueButton :popupActive="props.popupActive" @getClose="getClose" @getBlueBtnClick="getSysOrderCheck">
        <div class="space-y-3">
            <InlineDate id="ordDt" :value="sys['ordDt']" label="주문일" @getDate="getDate"/>
            <InlineDate id="outDt" :value="sys['outDt']" label="출고일" @getDate="getDate"/>
            <InlineDual label="배송지">
                <BasicSelect id="shippingGb" :options="shippingGb" :value="sys['shippingGb']" class="w-1/3" @change="getSelect"/>
                <BasicInput id="addr" type="text" :value="sys['addr']" :disabled="sys['shippingGb'] === '001' ? true : false" class="w-2/3" @click="getOpenDaumPost"/>
            </InlineDual>
            <InlineInput type="text" id="addrDetail" :value="sys['addrDetail']" label="배송지 상세" placeholder="비고" @input="getInputValSet"/>
            <InlineInput type="text" id="memo" :value="sys['memo']" label="배송 요청사항" placeholder="비고" @input="getInputValSet"/>
            <div id="layer" style="display:none;position:fixed;overflow:hidden;z-index:1;-webkit-overflow-scrolling:touch;">
                <img src="//t1.daumcdn.net/postcode/resource/images/close.png" id="btnCloseLayer" style="cursor:pointer;position:absolute;right:-3px;top:-3px;z-index:1" @click="getCloseDaumPost()" alt="닫기 버튼">
            </div>
        </div>
    </ToggleBox>
    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp" @getCancle="getCancle"/>
</template>

<script setup>
import ToggleBox from "@/components/modal/ToggleBox.vue";
import InlineDate from "@/components/input/InlineDate.vue";
import InlineInput from "@/components/input/InlineInput.vue";
import InlineDual from "@/components/input/InlineDual.vue";
import BasicInput from "@/components/input/BasicInput.vue";
import BasicSelect from "@/components/input/BasicSelect.vue";
import AlertBox from "@/components/modal/AlertBox.vue";

import { computed, ref } from 'vue';
import { defineProps } from 'vue';
import { useStore } from 'vuex';
import { getAxiosData, getDaumPopupPosition } from "@/assets/js/function";

const store         = useStore();
const sys           = computed(() => store.state.order.sysOrder);
const shippingGb    = computed(() => store.state.data.shippingGb);
const props         = defineProps({
    popupActive : Boolean
});

const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const resultYn = ref('N');

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
}

const getComp = () => {
    alertData.value['view'] = false;

    if(resultYn.value === 'Y')
    {
        getSysOrderResult();
    }
}

const getCancle = () => {
    alertData.value['view'] = false;
};

const getClose = () => {
    sys.value['popStat'] = false;
}

const getDate = (id, date) => {
    sys.value[id] = date;
}

const getSelect = () => {
    if(sys.value['shippingGb'] === '001')
    {
        sys.value['addr'] = '';
    }

    sys.value[event.target.id] = event.target.value;
}

const getInputValSet = () => {
    sys.value[event.target.id] = event.target.value;
}

const getOpenDaumPost = () => {
    if(sys.value['shippingGb'] === '001') return;

    const layer = document.getElementById('layer');

    new window.daum.Postcode({
        oncomplete : function(data) {
            let addr = '';

            if (data.userSelectedType === 'R') 
            {
                addr = data.roadAddress;
            } 
            else 
            {
                addr = data.jibunAddress;
            }

            sys.value['zip']  = data.zonecode;
            sys.value['addr'] = addr;

            document.getElementById("addrDetail").focus();
            layer.style.display = 'none';
        },
        width               : '100%',
        height              : '100%',
        submitMode          : false, // 뒤로가기 히스토리 남기지 않도록 설정
        maxSuggestItems     : 5
    }).embed(layer);

    getDaumPopupPosition(layer);
}

const getCloseDaumPost = () => {
    document.getElementById('layer').style.display = 'none';
}

const getSysOrderCheck = () => {
    resultYn.value = 'Y';
    getAlertDataSet(true, '확인', '발주 처리하시겠습니까?', true);
}

const getSysOrderResult = async () => {
    const params = {
        ceCd        : store.state.login.ceCd,
        userCd      : store.state.login.userCd,
        edCd        : sys.value['edCd'],
        ordDt       : sys.value['ordDt'],
        outDt       : sys.value['outDt'],
        shippingGb  : sys.value['shippingGb'],
        addr        : sys.value['addr'],
        addrDetail  : sys.value['addrDetail'],
        memo        : sys.value['memo']
    };

    console.log(params);

    await getAxiosData('https://data.planorder.kr/order/getSysOrderResult', params).then((res) => {
        console.log(res);
        if(res['status'] === 200)
        {
            window.location.reload();
        }
        else
        {
            getAlertDataSet(true, '알림', '발주 처리에 실패하였습니다. 지속될 경우 관리자에게 문의하세요', false);
        }
    });
}
</script>

<style scoped>

</style>