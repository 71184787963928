<template>
    <div class="fixed flex items-center bg-black/60 w-full h-screen top-0 left-0 right-0 mx-auto z-40">
        <div class="mx-auto bg-white min-w-72 py-8 px-11 rounded text-center">
            <h2 class="font-medium" :class="{ 'text-red-500' : title === '삭제' }">{{ title }}</h2>
            <p class="mt-3 mb-6 text-xs">{{ description }}</p>
            <div class="flex">
                <BaseButton buttonText="확인" blueButton class="mr-2" @click="comp"/>
                <BaseButton buttonText="취소" greyButton v-if="showGreyButton" @click="cancle"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import BaseButton from "@/components/button/BaseButton.vue";

const emit = defineEmits(['getComp', 'getCancle']);

defineProps({
    title           : String,
    description     : String,
    showGreyButton  : Boolean
});

const comp = () => {
    emit("getComp");
}

const cancle = () => {
    emit("getCancle");
}

</script>

<style scoped>

</style>