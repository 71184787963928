<template>
    <div class="relative">
        <textarea :id="id" :name="name" :value="value" :placeholder="placeholder" :autocomplete="'off'"
                  class="pt-3 pb-2.5 pl-3.5 border w-full rounded-sm text-xs duration-200 disabled:bg-zinc-100"
                  :class="[{'border-zinc-200': !error, 'border-red-600': error}, {'pr-8': iconSearch, 'pr-3.5': !iconSearch}]"
                  :disabled="disabled" :rows="rowCount"></textarea>
        <label :for="id" class="absolute -top-2.5 left-1.5 text-xm text-gray-400 bg-white py-0.5 px-1">{{ label }}<span
                class="text-red-500" v-if="important">*</span></label>
    </div>
</template>

<script>
export default {
    name : "LabelTextarea",
    props: {
        label      : String,
        type       : String,
        id         : String,
        name       : String,
        value      : String,
        placeholder: String,
        helperText : String,
        disabled   : Boolean,
        iconSearch : {
            type   : Boolean,
            default: false
        },
        error      : {
            type   : Boolean,
            default: false,
        },
        important  : Boolean,
        rowCount   : String,
    },
}
</script>

<style scoped>

</style>