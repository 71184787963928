<template>
    <ImageModalBox :title="detail['faNm']" :imgUrl="detail['imgUrl']">
        <InfoCard title="공장 정보" :info="detail['infoList']" grayBar />
        <div class="py-5 rounded border my-6">
            <CountList :lists="detail['countList']" />
        </div>
        <GrayBar />
        <CalculateCard title="거래 원장" borderTop :calcs="detail['amtList']" totalTitle="총 결제 금액" :totalAmt="detail['totalAmt']" />

        <div class="p-4 rounded border mt-6 space-y-5">
            <div class="flex items-center justify-between">
                <h2 class="text-sm font-bold">공지사항</h2>
                <InlineButton grayButton buttonText="전체보기" />
            </div>
            <!-- <dl class="flex text-xs justify-between">
                <dt class="w-2/3 text-ellipsis overflow-hidden whitespace-nowrap">3월 1일 휴무안내 발주시 참고부탁드립니다</dt>
                <dd class="w-1/3 text-right text-zinc-500">22년 02월 27일</dd>
            </dl> -->
        </div>


        <div class="absolute bg-white w-full bottom-0 left-0 right-0 mx-auto shadow-custom p-4 pb-3.5">
            <div class="flex justify-between items-center font-bold mb-4">
                <h2>총 제품</h2>
                <h2 class="text-brand">{{ detail['itemCnt'] }}개</h2>
            </div>
            <BaseButton buttonText="제품 설정하기" blueButton @click="getItemSet"/>
        </div>
    </ImageModalBox>
</template>

<script setup>
import ImageModalBox from "@/components/modal/ImageModalBox.vue";
import InfoCard from "@/components/card/InfoCard.vue";
import CountList from "@/components/list/CountList.vue";
import GrayBar from "@/components/common/GrayBar.vue";
import CalculateCard from "@/components/card/CalculateCard.vue";
import InlineButton from "@/components/button/InlineButton.vue";
import BaseButton from "@/components/button/BaseButton.vue";

import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

const store  = useStore();
const router = useRouter();
const route  = useRoute();

const detail = computed(() => store.state.factory.sys.detail);

const getItemSet = () => {
    router.push({ path : '/factory/sysProduct', query : { cd : route.query.cd }});
}

onMounted(() => {
    const parmas = {
        ceCd  : store.state.login.ceCd,
        fcCd  : route.query.cd
    };

    store.dispatch('factory/getSysFactoryDetail', parmas);
})
</script>

<style scoped>

</style>