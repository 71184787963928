<template>
    <ul class="w-full flex text-center">
        <li v-for="(list, index) in lists" :key="index" class="w-full border-l border-neutral-100 text-sky-500 text-lg font-bold" @click="getCntClick(list.gubun)">
            {{ list.count }}
            <p class="font-normal text-xs text-zinc-500">
                {{ list.label }}
            </p>
        </li>
    </ul>
</template>

<script>
export default {
    name: "CountList",
    props: {
        lists: Array,
    },
    methods : {
        getCntClick(gubun){
            this.$emit('getCntClick', gubun);
        }
    }
}
</script>

<style lang="scss" scoped>
li{
    &:first-child{
        border-left: 0;
    }
}
</style>