<template>
    <div class="fixed flex items-center bg-black/60 w-full h-screen top-0 left-0 right-0 mx-auto z-40">
        <div class="mx-auto bg-white max-w-[380px] w-11/12 min-w-72 py-8 px-7 rounded text-center">
            <h2 class="font-medium mb-3.5">할인</h2>
            <div class="flex border rounded-sm overflow-hidden items-center h-[38px]">
                <div class="w-1/4 text-center">
                    <input type="radio" class="hidden peer/won" :checked="true" readonly/>
                    <label for="won" class="text-sm block bg-white pt-2.5 pb-2 peer-checked/won:bg-brand peer-checked/won:text-white">
                        정액
                    </label>
                </div>
                <label for="" class="pt-2.5 pb-2 pl-3 pr-8 w-2/4 relative">
                    <input type="text" v-model="addInfo['val']" class="text-right text-sm w-full" @input="getInputAmt" @click="getFocus"/>
                    <span class="text-sm absolute vertical-h right-3">원</span>
                </label>
            </div>
            <LabelInput type="text" :value="addInfo['memo']" placeholder="메모" class="mt-3" @input="getInputMemo"/>
            <div class="flex mt-6 space-x-2.5">
                <BaseButton greyButton buttonText="취소" @click="getClose"/>
                <BaseButton blueButton buttonText="확인" @click="getResult"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import LabelInput from "@/components/input/LabelInput.vue";
import BaseButton from "@/components/button/BaseButton.vue";

import { computed, defineProps, defineEmits } from 'vue';
import { useStore } from 'vuex';

const store  = useStore();
const props  = defineProps({
    gubun : String
});

const emit = defineEmits(['getResult', 'getClose']);

const addInfo = computed(() => props.gubun === 'F' ? store.state.esti.addInfo : store.state.order.addInfo);

const getFocus = () => {
    const value = Number(event.target.value);

    if(value === 0)
    {
        event.target.value = '';
    }
}

const getInputAmt = () => {
    const value = Number(event.target.value);

    addInfo.value['val'] = value;
};

const getInputMemo = () => {
    addInfo.value['memo'] = event.target.value;
};

const getResult = () => {
    emit('getResult');
};

const getClose = () => {
    emit('getClose');
};

</script>

<style scoped>

</style>