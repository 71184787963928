<template>
    <div class="flex items-center justify-between">
        <label :for="id" class="w-1/3 text-sm text-neutral-500">{{ label }}</label>
        <select :id="id" :name="name"
                class="pt-3 pb-2.5 px-2.5 border w-2/3 rounded-sm text-xs duration-200"
                :class="{'border-zinc-200': !error, 'border-red-600': error}" required>
            <option v-for="(option, index) in options" :key="index" :value="option.value" :selected="option.value === value">
                {{ option.label }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    name: "InlineSelect",
    props     : {
        label      : String,
        id         : String,
        name       : String,
        value      : String,
        error      : {
            type   : Boolean,
            default: false,
        },
        options    : Array,
    },
}
</script>

<style scoped>

</style>