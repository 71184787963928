<template>
    <div class="flex items-start justify-between w-full block py-5 hover:bg-zinc-100 cursor-pointer duration-200" @click="getDetail(url, cd, process)">
        <div class="overflow-hidden rounded-lg w-[4.875rem] h-[5.4rem]" v-if="showImage">
            <img :src="showImage ? (domain+imgUrl) : '/src/assets/images/test.png'" alt="" title="" class="w-full h-full"/>
        </div>
        <div :class="[{'w-2/3': showImage}, {'w-full': !showImage}]">
            <div class="flex justify-between items-start mb-2">
                <h2 class="font-bold text-sm">{{ title }}</h2>
                <h3 class="font-bold text-xs" :class="[{'text-brand': isBlue, 'text-yellow-400': isYellow}]">{{ process }}</h3>
            </div>
            <div class="space-y-1.5 text-xm">
                <p class="text-zinc-700">{{ tel }}</p>
                <p class="text-zinc-500">{{ address }}</p>
                <div class="space-x-1.5">
                    <SpanTag v-for="(tag, index) in tags" :key="index" :spanText="tag.title" isTeal/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import SpanTag from "@/components/common/SpanTag.vue";
import { defineProps } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const domain = 'https://elasticbeanstalk-ap-northeast-2-627549176645.s3.ap-northeast-2.amazonaws.com';
defineProps({
    title       : String,
    process     : String,
    tel         : String,
    address     : String,
    url         : String,
    cd          : String,
    isBlue      : Boolean,
    isYellow    : Boolean,
    tags        : Array,
    showImage   : Boolean,
    imgUrl      : String
});

const getDetail = (url, cd, process) => {
    if(process === '')
    {
        router.push({ path : url, query : { cd : cd }})
    }
}

</script>

<style scoped>

</style>