<template>
    <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.26953 18.875H1.6237C1.05078 18.875 0.582031 18.4062 0.582031 17.8333V7.41667C0.582031 6.84375 1.05078 6.375 1.6237 6.375H5.26953C5.84245 6.375 6.3112 6.84375 6.3112 7.41667V17.8333C6.3112 18.4062 5.84245 18.875 5.26953 18.875ZM12.8216 0.125H9.17578C8.60286 0.125 8.13411 0.59375 8.13411 1.16667V17.8333C8.13411 18.4062 8.60286 18.875 9.17578 18.875H12.8216C13.3945 18.875 13.8633 18.4062 13.8633 17.8333V1.16667C13.8633 0.59375 13.3945 0.125 12.8216 0.125ZM20.3737 8.45833H16.7279C16.1549 8.45833 15.6862 8.92708 15.6862 9.5V17.8333C15.6862 18.4062 16.1549 18.875 16.7279 18.875H20.3737C20.9466 18.875 21.4154 18.4062 21.4154 17.8333V9.5C21.4154 8.92708 20.9466 8.45833 20.3737 8.45833Z"/>
    </svg>

</template>

<script>
export default {
    name: "IconAcc"
}
</script>

<style scoped>

</style>