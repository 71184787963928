<template>
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.91573 16.7917V11.5833H12.0824V16.7917C12.0824 17.3646 12.5511 17.8333 13.1241 17.8333H16.2491C16.822 17.8333 17.2907 17.3646 17.2907 16.7917V9.5H19.0616C19.5407 9.5 19.7699 8.90625 19.4053 8.59375L10.697 0.75C10.3011 0.395833 9.69698 0.395833 9.30115 0.75L0.592815 8.59375C0.238648 8.90625 0.457398 9.5 0.936564 9.5H2.7074V16.7917C2.7074 17.3646 3.17615 17.8333 3.74906 17.8333H6.87406C7.44698 17.8333 7.91573 17.3646 7.91573 16.7917Z"/>
    </svg>
</template>

<script>
export default {
    name: "IconHome"
}
</script>

<style scoped>

</style>