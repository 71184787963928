<template>
    <div class="w-full p-4 rounded border-1.5 border-neutral-200">
        <div class="flex justify-between border-b border-neutral-100 pb-3.5 font-bold text-sm">
            <h2>{{ props.info['stNm'] }}</h2>
            <h3 class="text-right">{{ getAmt(props.info['totalSaleAmt']) }}원</h3>
        </div>
        <ul class="flex mt-6 text-center">
           <li class="w-1/4">
               <div class="bg-neutral-100 h-2 rounded-tl-2xl rounded-bl-2xl"></div>
               <!-- 완료되는 항목의 div에 text-gray-400이 text-zinc-700으로 변경 -->
               <div :class="'text-xm mt-3 '+(props.info['conDt'] === '' ? 'text-gray-400' : 'text-zinc-700')">
                   <p>계약 완료</p>
                   <p>{{ getDate(props.info['conDt']) }}</p>
               </div>
           </li>
           <li class="w-1/4">
               <div class="bg-neutral-100 h-2"></div>
               <div :class="'text-xm mt-3 '+(props.info['preDt'] === '' ? 'text-gray-400' : 'text-zinc-700')">
                   <p>발주 완료</p>
                   <p>{{ getDate(props.info['preDt']) }}</p>
               </div>
           </li>
           <li class="w-1/4">
               <div class="bg-neutral-100 h-2"></div>
               <div :class="'text-xm mt-3 '+(props.info['deliConDt'] === '' ? 'text-gray-400' : 'text-zinc-700')">
                   <p>시공 완료</p>
                   <p>{{ getDate(props.info['deliConDt']) }}</p>
               </div>
           </li>
           <li class="w-1/4">
               <div class="bg-neutral-100 h-2 rounded-tr-2xl rounded-br-2xl"></div>
               <div :class="'text-xm mt-3 '+(props.info['payDt'] === '' ? 'text-gray-400' : 'text-zinc-700')">
                   <p>결제 완료</p>
                   <p>{{ getDate(props.info['payDt']) }}</p>
               </div>
           </li>
        </ul>
        <div class="my-6 flex">
            <template v-if="props.info['stCd'] === '001' || props.info['stCd'] === '002'">
                <p class="text-center text-xm text-zinc-400 w-full">
                    견적일
                    <span class="block text-zinc-700">{{ getDateAndTime(props.info['estiDt']) }}</span>
                </p>
            </template>
            <template v-else>
                <p class="text-center text-xm text-zinc-400 w-full">
                    시공일
                    <span class="block text-zinc-700">{{ props.info['deliDt'] === '' ? getDateAndTime(props.info['deliConDt']) : getDateAndTime(props.info['deliDt']) }}</span>
                </p>
                <p class="text-center text-xm text-zinc-400 w-full border-l">
                    작업시간
                    <span class="block text-zinc-700">{{ props.info['insTime'] }}</span>
                </p>
            </template>
        </div>
        <div class="flex space-x-2">
            <template v-if="props.info['stCd'] === '001' && props.info['useYn'] === 'Y'">
                <BaseButton buttonText="제품추가" blueButton @click="getEstiIng"/>
            </template>
            <template v-else-if="props.info['stCd'] === '012'">
                <BaseButton buttonText="상세보기" greyButton @click="getSecondBtnClick"/>
            </template>
            <template v-else-if="props.info['useYn'] === 'N'">
                <BaseButton buttonText="견적서 복원" greyButton @click="getEstiRestoreCheck"/>
            </template>
            <template v-else>
                <BaseButton :buttonText="getFirstBtnText" greyButton @click="getFirstBtnClick"/>
                <BaseButton :buttonText="getSecondBtnText" blueButton @click="getSecondBtnClick"/>
            </template>
            <button type="button" class="text-center rounded-sm p-3 text-xs bg-gray-200" v-if="props.info['useYn'] !== 'N'" @click="getMoreClick(props.info['emCd'])">
                <FontAwesomeIcon :icon="['fas', 'ellipsis']" />
            </button>
        </div>
    </div>
    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp" @getCancle="getCancle"/>
</template>

<script setup>
import BaseButton from "@/components/button/BaseButton.vue";
import AlertBox from "@/components/modal/AlertBox.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { getCommas, getConvertDate } from "@/assets/js/function"

import { defineProps, defineEmits, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { getAxiosData } from "@/assets/js/function";
import store from "@/store";

const router = useRouter();
const props  = defineProps({
    info : Object
});

const emit   = defineEmits(['getMore']);

const resultYn  = ref('N');
const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
}

const getComp = () => {
    alertData.value['view'] = false;

    if(resultYn.value === 'Y')
    {
        getDeliResult();
    }
    else if(resultYn.value === 'R')
    {
        getEstiRestoreResult();
    }
}

const getCancle = () => {
    alertData.value['view'] = false;
    resultYn.value = 'N';
};

const getFirstBtnText = computed(() => {
    let text = '견적서 공유';

    switch (props.info['stCd']) {
        case '002': /** 견적 진행 */
            text = '견적서 공유';
            break;
        case '003': /** 계약 완료 */
            text = '계약서 공유';
            break;
        case '006': /** 발주 완료 */ 
        case '011': /** 시공 완료 */
            text = '상세보기';
            break;
    }

    return text;
});

const getSecondBtnText = computed(() => {
    let text = '';

    switch(props.info['stCd'])
    {
        case '002': /** 견적 진행 */
            text = '견적서 이동';
        break;
        case '003': /** 계약 완료 */
            text = '공장발주';
        break; 
        case '006': /** 발주 완료 */
            text = '시공완료';
        break;
        case '011': /** 시공 완료 */
            text = '결제입력';
        break;
    }

    return text;
});

const getAmt = (amt) => {
    return getCommas(Number(amt));
};

const getDate = (date) => {
    return date !== '' ? getConvertDate(new Date(date), 'mm%dd%w%') : '';
}

const getDateAndTime = (date) => {
    return getConvertDate(new Date(date), 'mm%dd%w% hh:ii');
}

const getEstiIng = () => {
    router.push({ path: '/customer/estiIng', query: { clientCd : props.info['clientCd'], emCd : props.info['emCd'], type : 'I' }});
}

const getFirstBtnClick = () => {
    switch(props.info['stCd'])
    {
        case '002':
            router.push({ path: '/customer/estiMate', query: { emCd : props.info['emCd'] }});
        break;
        case '003':
            router.push({ path: '/customer/conMate', query: { emCd : props.info['emCd'] }});
        break;
        case '006': case '012':
            router.push({ path: '/customer/orderList', query: { emCd : props.info['emCd'] }});
        break;
    }
}

const getSecondBtnClick = () => {
    switch(props.info['stCd'])
    {
        case '002':
            router.push({ path: '/customer/estiList', query: { clientCd : props.info['clientCd'], emCd : props.info['emCd'] }});
        break;
        case '003':
            router.push({ path: '/customer/orderList', query: { emCd : props.info['emCd'] }});
        break;
        case '006':
            getAlertDataSet(true, '시공완료', '시공완료 처리하시겠습니까?', true);
            resultYn.value = 'Y';
        break;
        case '011': case '012':
            router.push({ path: '/customer/orderList', query: { emCd : props.info['emCd'] }});
        break;
    }
}

const getEstiRestoreCheck = () => {
    getAlertDataSet(true, '견적 복원', '해당 견적서를 복원하시겠습니까?', true);
    resultYn.value = 'R';
}

const getMoreClick = (emCd) => {
    emit('getMore', emCd);
}

const getDeliResult = async () => {
    const params = {
        ceCd    : store.state.login.ceCd,
        userCd  : store.state.login.userCd,
        emCd    : props.info['emCd']
    };

    await getAxiosData('https://data.planorder.kr/esti/getDeilResult', params).then((res) => {
        resultYn.value = 'N';
        if(res['status'] === 200)
        {
            window.location.reload();
        }
        else
        {
            getAlertDataSet(true, '시공완료', '시공완료 처리에 실패하였습니다. 지속될 경우 관리자에게 문의하세요.', false);
        }
    });
}

const getEstiRestoreResult = async () => {
    const params = {
        ceCd    : store.state.login.ceCd,
        userCd  : store.state.login.userCd,
        emCd    : props.info['emCd']
    };

    await getAxiosData('https://data.planorder.kr/esti/getRestore', params).then((res) => {
        resultYn.value = 'N';
        if(res['status'] === 200)
        {
            window.location.reload();
        }
        else
        {
            getAlertDataSet(true, '견적복원', '견적복원 처리에 실패하였습니다. 지속될 경우 관리자에게 문의하세요.', false);
        }
    });
}

</script>

<style scoped>

</style>