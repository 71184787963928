<template>
    <BottomBox title="공장코드 추가" @getClose="getClose">
        <div class="space-y-5">
            <LabelInput iconSearch id="faCd" :value="info['faCd']" label="공장코드" type="text" @keyup.enter="getFactorySearch" @getClickIcon="getFactorySearch" @input="getInputValSet"/>
            <LabelInput id="faNm" :value="info['faNm']" label="공장이름" type="text" disabled />
            <LabelInput id="item" :value="info['item']" label="종목" type="text" disabled />
            <LabelInput id="person" :value="info['person']" label="담당자 성명" type="text" disabled />
            <LabelInput id="tel" :value="info['tel']" label="거래처 전화번호" type="text" disabled />
        </div>
        <div class="h-20"></div>
    </BottomBox>
    <div class="mx-auto bg-white w-11/12 p-4 pt-3 fixed left-0 right-0 bottom-0 z-50 shadow-custom">
        <BaseButton buttonText="거래 신청" blueButton @click="getFactoryRequestCheck"/>
    </div>
    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp" @getCancle="getCancle"/>
</template>

<script setup>
import BottomBox from "@/components/modal/BottomBox.vue";
import LabelInput from "@/components/input/LabelInput.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import AlertBox from "@/components/modal/AlertBox.vue";

import { computed, defineEmits, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const info = computed(() => store.state.factory.sys.info);

const emit = defineEmits(['getClose']);

const focusId   = ref('');
const resultYn  = ref('N');
const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
}

const getComp = () => {
    alertData.value['view'] = false;
    
    if(resultYn.value === 'N')
    {
        if(focusId.value !== '')
        {
            document.getElementById(focusId.value).focus();
        }
    }
    else
    {
        getFactoryRequest();
    }
}

const getCancle = () => {
    alertData.value['view'] = false;
    resultYn.value = 'N';
}

const getClose = () => {
    emit('getClose');
}

const getInputValSet = () => {
    const id    = event.target.id;
    const val   = event.target.value;

    info.value[id] = val;
}

const getFactorySearch = () => {
    if(info.value['faCd'] === '')
    {
        getAlertDataSet(true, '확인', '공장코드를 입력해주세요.', false);
        focusId.value = 'faCd';
        return;
    }

    const params = {
        faCd : info.value['faCd']
    };

    const factory = store.dispatch('factory/getSysFactorySearch', params);

    factory.then((res) => {
        if(!res['result'])
        {
            store.commit('factory/setReset', { gb : 'sys', data : 'info' });
            getAlertDataSet(true, '확인', '존재하지 않는 공장코드입니다. 공장코드를 확인해주세요.', false);
            focusId.value = 'faCd';
        }
    });
}

const getFactoryRequestCheck = () => {
    if(info.value['compCd'] === '')
    {
        getAlertDataSet(true, '확인', '공장을 조회해주세요.', false);
        focusId.value = 'faCd';
        return;
    }

    getAlertDataSet(true, '거래신청', '거래 신청하시겠습니까?.', true);
    resultYn.value = 'Y';
}

const getFactoryRequest = () => {
    resultYn.value = 'N';
    
    const params = {
        ceCd        : store.state.login.ceCd,
        token       : store.state.login.token,
        userCd      : store.state.login.userCd,
        faCd        : info.value['compCd'],
        faNm        : info.value['faNm'],
        item        : info.value['item'],
        person      : info.value['person'],
        tel         : info.value['tel'],
        imgUrl      : info.value['imgUrl'],
        zip         : info.value['zip'],
        addr        : info.value['addr'],
        addrDetail  : info.value['addrDetail']
    };

    const factory = store.dispatch('factory/getSysFactoryRequest', params);

    factory.then((res) => {
        if(res['result'])
        {
            getAlertDataSet(true, '확인', '거래 신청이 완료되었습니다.', false);
            store.commit('factory/setReset', { gb : 'sys', data : 'info' });
        }
        else
        {
            getAlertDataSet(true, '확인', '거래 신청에 실패하였습니다. 이미 신청한 공장인지 확인해주세요.', false);
        }
    });
}
</script>

<style scoped>

</style>