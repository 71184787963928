import { getAxiosData, getConvertDate } from '@/assets/js/function';
/**
 * @description 계약 관련 vuex 모듈
 */
const conModule = {
    namespaced  : true, 
    state       : {
        insTime  : {
            hours   : 0,
            minutes : 0,
            seconds : 0
        },
        person   : [],
        info     : getConInfo()
    },
    mutations : {
        setStat(state) 
        {
            state.info.popStat = !state.info.popStat;
        },
        setInsTime(state, time)
        {
            state.insTime = time
        },
        setPerson(state, person)
        {
            state.person = person;
        },
        setReset(state)
        {
            state.info = getConInfo();
        }
    },
    actions : {
        async getData(context, data)
        {
            context.commit('setReset');

            try
            {
                const res = await getAxiosData('https://data.planorder.kr/esti/getConData', data);

                context.commit('setInsTime', res.data['insTime']);
                context.commit('setPerson', res.data['person']);
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.log(e);
                return { result: false, msg: 'error' };
            }
        },
        async getContract(context, data)
        {
            try
            {
                await getAxiosData('https://data.planorder.kr/esti/getContract', data);
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.log(e);
                if(e.response.status === 409)
                {
                    return { result: false, msg: e.response.data.data };
                }
                else
                {
                    return { result: false, msg: 'error' };
                }
            }
        }
    }
};

/**
 * @description 계약서 정보
 */
function getConInfo()
{
    return {
        popStat : false,
        conDt   : getConvertDate(new Date(), 'yyyy-mm-dd hh:ii'),
        deliDt  : '',
        person  : '',
        payGb   : '',
        amt     : 0,
        memo    : ''
    }
}

export default conModule;