<template>
    <div class="flex items-center justify-between">
        <label :for="id" class="w-1/3 text-sm text-neutral-500">{{ label }}</label>
        <VueDatePicker v-model="localValue" :id="id" :name="name"
            :placeholder="placeholder" :disabled="disabled" :format="'HH시간 mm분'" time-picker auto-apply @update:model-value="getTimeChange"></VueDatePicker>
    </div>
</template>

<script setup>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import { defineProps, defineEmits, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore(); // Vuex store 사용
const emit  = defineEmits(['getTime']);
const props = defineProps({
    id         : String,
    label      : String,
    name       : String,
    value      : String,
    placeholder: String,
    disabled   : Boolean
});

const localValue = ref(props.value);

const getTimeChange = (newValue) => {
    localValue.value = newValue;

    emit('getTime', newValue);
}

store.watch(() => store.state.con.insTime, (newValue) => {
    localValue.value = newValue;
});

</script>

<style scoped>
.dp__pointer{
    border-radius: 0.125rem;
}
</style>