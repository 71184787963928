<template>
    <div class="relative">
        <select :id="id" :name="name"
                class="pt-3 pb-2.5 px-2.5 border w-full rounded-sm text-xs duration-200"
                :class="{'border-zinc-200': !error, 'border-red-600': error}" required>
            <option v-for="(option, index) in options" :key="index" :value="option.value" :selected="option.value === value">
                {{ option.label }}
            </option>
        </select>
        <label :for="id" class="absolute -top-2.5 left-1.5 text-xm text-gray-400 bg-white py-0.5 px-1">{{ label }}<span class="text-red-500" v-if="important">*</span></label>
        <p class="text-xm text-red-700 mt-1" v-if="error">{{ helperText }}</p>
    </div>
</template>

<script>
export default {
    name: "LabelSelect",
    props     : {
        label      : String,
        id         : String,
        name       : String,
        value      : String,
        helperText : String,
        error      : {
            type   : Boolean,
            default: false,
        },
        options    : Array,
        placeholder: String,
        important: Boolean
    },
}
</script>

<style scoped>

</style>