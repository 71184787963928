import { getAxiosData, getConvertDate } from '@/assets/js/function';
/**
 * @description 고정비용 관련 vuex 모듈
 */
const staticPayModule = {
    namespaced : true, 
    state : {
        start  : 0,
        header : {
            cnt : 0,
            amt : 0
        },
        list   : [],
        info   : getInfo()
    },
    mutations : {
        setHeader(state, header)
        {
            state.header = header;
        },
        setList(state, list)
        {
            state.list = list;
        },
        setInfo(state, info)
        {
            state.info = info;
        },
        setReset(state)
        {
            state.info = getInfo();
        }
    },
    actions : {
        async getData(context, params)
        {
            try
            {
                const res = await getAxiosData('https://data.planorder.kr/staticPay/getData', params);

                console.log(res);

                context.commit('setHeader', res.data['calc']);
                context.commit('setList', res.data['list']);
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        },
        async getInfo(context, params)
        {
            try
            {
                const res = await getAxiosData('https://data.planorder.kr/staticPay/getInfo', params);

                context.commit('setInfo', res.data);
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.error(e);
                return { result: false, msg: 'fail' };
            }
        }
    }
};

/**
 * @description 고정비용 정보
 */
function getInfo()
{
    return {
        title       : '',
        fxDt        : getConvertDate(new Date(), 'yyyy-mm-dd'),
        amt         : 0,
        fxGb        : '001',
        contents    : ''
    }
}

export default staticPayModule;