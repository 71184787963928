<template>
    <div class="space-y-1 py-3.5">
        <h5 class="font-bold text-sm">{{ day }}</h5>
        <div class="flex justify-between items-center">
            <div class="space-y-1">
                <dl class="flex items-center text-xs text-zinc-400">
                    <dt class="mr-4">평균 마진</dt>
                    <dd>{{ margin }}%</dd>
                </dl>
                <dl class="flex items-center text-xs text-zinc-400">
                    <dt class="mr-4">월간 수익</dt>
                    <dd>{{ getAmt(rev) }}원</dd>
                </dl>
                <dl class="flex items-center text-xs text-zinc-400">
                    <dt class="mr-4">결제 대기</dt>
                    <dd>{{ getAmt(waitAmt) }}원</dd>
                </dl>
            </div>
            <h5 class="text-green-500 text-base" @click="getClick">{{ getAmt(totalAmt) }}원</h5>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { getCommas } from '@/assets/js/function';

defineProps({
    day     : String,
    totalAmt: String,
    margin  : String,
    rev     : String,
    waitAmt : String
});

const emit = defineEmits(['getClick']);

const getClick = () => {
    emit('getClick');
}

const getAmt = (amt) => {
    return getCommas(Number(amt));
}
</script>

<style scoped>

</style>