<template>
    <div class="flex justify-between items-center" v-for="(radio, index) in radios" :key="index">
        <label :for="radio.id">{{ radio.label }}</label>
        <input type="radio" :id="radio.id" :name="name" class="hidden peer/check"/>
        <FontAwesomeIcon :icon="['fas', 'check']" class="text-brand peer-checked/check:block hidden"/>
    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name      : "CheckedRadio",
    components: {FontAwesomeIcon},
    props     : {
        radios: Array,
        name  : String,
    }
}
</script>

<style scoped>

</style>