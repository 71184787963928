<template>
    <select :id="id" :name="name"
            class="pt-3 pb-2.5 px-2.5 rounded-sm text-xs duration-200"
            :class="[{'border-zinc-200': !error, 'border-red-600': error}, {'bg-zinc-100': disabled}, {border: !noBorder}]" required>
        <option v-for="(option, index) in options" :key="index" :value="option.value" :selected="option.value === value">
            {{ option.label }}
        </option>
    </select>
</template>

<script>
export default {
    name: "BasicSelect",
    props     : {
        label      : String,
        id         : String,
        name       : String,
        value      : String,
        helperText : String,
        error      : {
            type   : Boolean,
            default: false,
        },
        options    : Array,
        placeholder: String,
        disabled   : Boolean,
        noBorder   : Boolean,
    },
}
</script>

<style scoped>

</style>