<template>
    <ImageModalBox title="견적서" :imgUrl="info['imgUrl']">
        <InfoCard :title="info['ceNm']" :info="info['headers']"/>
        <div class="space-y-5 mt-5">
            <TableCard v-for="(table, index) in info['list']" :key="index" :title="table.title" :cards="table.cardLists"
                       :columns="table.columns" :rows="table.rows" :tags="table.tags" :showTag="table.showTag"
                       :showButton="table.showButton" :sizeYn="info['sizeYn']"/>
            <GrayBar class="my-5"/>
            <CalculateCard title="합계 금액" borderTop :calcs="info['payList']" totalTitle="총 결제 금액" :totalAmt="totalAmt" />
        </div>
    </ImageModalBox>
    <div class="fixed left-0 right-0 mx-auto max-w-[37.5rem] bottom-0 z-30 bg-white p-4 shadow-[0px_-2px_4px_0px_rgba(0,0,0,0.08)]">
        <div class="space-y-4">
            <div class="flex justify-between items-center">
                <div class="flex items-center">
                    <CheckBox id="noFr" name="fr" label="일반 양식" checked/>
                    <CheckBox id="ExFr" name="fr" label="엑셀 양식"/>
                </div>
                <div class="flex items-center">
                    <label for="sizeHide" class="text-xs text-neutral-700 mr-2">사이즈 숨김</label>
                    <SliderCheckbox id="sizeHide" name="sizeHide" @change="getCheckbox"/>
                </div>
            </div>
            <LabelInput :value="''+domain+'/customer/estiDoc?cd='+emCd+''" label="견적서 링크" readonly @click="getEstiDoc"/>
            <BaseButton buttonText="견적서 링크 발송" blueButton/>
        </div>
    </div>
</template>

<script setup>
import ImageModalBox from "@/components/modal/ImageModalBox.vue";
import InfoCard from "@/components/card/InfoCard.vue";
import TableCard from "@/components/card/TableCard.vue";
import GrayBar from "@/components/common/GrayBar.vue";
import CalculateCard from "@/components/card/CalculateCard.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import CheckBox from "@/components/input/CheckBox.vue";
import LabelInput from "@/components/input/LabelInput.vue";
import SliderCheckbox from "@/components/input/SliderCheckbox.vue";

import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { getAmt } from '@/assets/js/function';

const store  = useStore();
const route  = useRoute();
const emCd   = btoa(route.query.emCd);
const domain = window.location.origin;

const info      = computed(() => store.state.estiMate);
const totalAmt  = computed(() => getAmt(info.value['payList'], 'total'));

const getCheckbox = () => {
    const checked = event.target.checked;
    store.commit('estiMate/setSizeYn', checked ? 'Y' : 'N');
}

const getEstiDoc = () => {
    const value = event.target.value;

    window.open(value, '_blank');
}

onMounted(() => {
    const params = {
        ceCd : store.state.login.ceCd,
        emCd : route.query.emCd
    };

    store.dispatch('estiMate/getInfo', params);
});

</script>

<style scoped>

</style>