<template>
    <div class="relative">
        <input :type="type" :id="id" :value="value" :name="name" :placeholder="placeholder"
               class="pt-2.5 pb-2 pl-3.5 pr-9 border w-full rounded-sm text-sm font-bold duration-200 text-right disabled:bg-zinc-100"
               :class="[{'border-zinc-200': !error, 'border-red-600': error}, {'text-brand': textBlue}]" :disabled="disabled" />
        <label :for="id" class="absolute -top-2.5 left-1.5 text-xm font-bold bg-white py-0.5 px-1">{{ label }}<span class="text-red-500" v-if="important">*</span></label>
        <span class="absolute bottom-2.5 right-3.5 text-xs">{{ unit }}</span>
        <p class="text-xm text-red-700 mt-1" v-if="error">{{ helperText }}</p>
    </div>
</template>

<script>

export default {
    name: "LabelUnit",
    props     : {
        label      : String,
        type       : String,
        id         : String,
        name       : String,
        value      : String,
        unit       : String,
        placeholder: String,
        helperText : String,
        textBlue   : Boolean,
        disabled   : Boolean,
        error      : {
            type   : Boolean,
            default: false,
        },
        important: Boolean
    },
}
</script>

<style scoped>

</style>