<template>
  <div class="relative w-full mx-auto block xl:flex">
    <HeaderVue />
    <div class="w-full xl:w-calc min-h-svh xl:min-h-screen">
      <SideHeader />
      <div class="w-full pt-12 xl:pt-0">
        <router-view>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import {fas} from "@fortawesome/free-solid-svg-icons"
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, far, fab);

import HeaderVue from "@/views/common/HeaderVue.vue";
import SideHeader from "@/views/common/SideHeader.vue";

export default {
  name: 'App',
  components: {SideHeader, HeaderVue}
}
</script>

<style>
@import "@/assets/scss/_globalStyle.scss";
</style>
