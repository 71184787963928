<template>
    <router-link :to="{ name : 'CustomerDetail', query : { clientCd : item.clientCd }}" class="flex w-full block justify-between items-center py-2.5" v-for="(item, index) in clients" :key="index" >
        <div class="flex width">
            <div :class="getClassByStep(item.step)" class="text-white text-xm font-bold rounded min-w-9 w-9 h-9 text-center flex items-center">
                <p class="w-full">{{ getTextByStep(item.step) }}</p>
            </div>
            <div class="w-2/3 ml-3">
                <h2 class="font-bold text-sm">{{ item.clientNm }}</h2>
                <p class="text-xm text-neutral-300 text-ellipsis overflow-hidden whitespace-nowrap">{{ item.addr }}</p>
            </div>
        </div>
        <div class="text-right w-20 min-w-20">
            <p class="text-xm text-neutral-300">{{ item.date }}</p>
            <h2 class="font-bold text-sm">{{ item.amt }}원</h2>
        </div>
    </router-link>
</template>

<script setup>
import { defineProps } from 'vue';

defineProps({
    clients : Array
});

const getClassByStep = (step) => {
    switch(step) {
        case 1:
            return "bg-sky-200";
        case 2:
            return "bg-blue-300";
        case 3:
            return "bg-blue-500";
        case 4:
            return "bg-blue-700";
        case 5:
            return "bg-blue-800";
        case 6:
            return "bg-red-600";
        default:
            return "";
    }
};

const getTextByStep = (step) => {
    switch (step) {
        case 1:
            return "대기";
        case 2:
            return "견적";
        case 3:
            return "발주";
        case 4:
            return "시공";
        case 5:
            return "결제";
        case 6:
            return "취소";
        default:
            return "";
    }
};
</script>

<style scoped>
.width{
    width: calc(100% - 80px);
}
</style>