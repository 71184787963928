<template>
    <div class="flex items-center justify-between">
        <label :for="id" class="w-1/3 text-sm text-neutral-500">{{ label }}</label>
        <div class="w-2/3 flex items-center justify-between space-x-2.5">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "InlineDual",
    props: {
        id         : String,
        label      : String,
        name       : String,
        type       : String,
        placeholder: String,
        error      : Boolean,
        disabled   : Boolean,
    }
}
</script>

<style scoped>

</style>