<template>
    <button type="button" class="text-red-500 bg-white rounded-sm border border-gray-200 py-1 px-4 text-xm" @click="getDelete">
        삭제
    </button>
</template>

<script setup>
import { defineEmits } from 'vue';

const emit = defineEmits(['getDelete']);

const getDelete = () => {
    emit('getDelete');
}
</script>

<style scoped>

</style>