<template>
    <div class="container mx-auto">
        <div class="w-11/12 mx-auto">
            <div class="flex justify-between items-start mt-5 mb-7">
                <div>
                    <h5 class="font-bold text-sm">{{ getDateText }} 총 손익</h5>
                    <h2 class="font-bold text-2xl text-green-500">{{ info['totalAmt'] }}원</h2>
                </div>
                <SelectBox id="searchDt" :value="info['searchDt']" :options="yearMonth" @change="getSelect"/>
            </div>
            <GrayBar class="mt-3.5 mb-7" />
            <div class="w-full space-y-6">
                <BarAverage title="요약" :numbers="info['summary']['percent']" />
                <ListAverage :pays="info['summary']['list']" />
            </div>
            <GrayBar class="my-7" />
            <div>
                <h2 class="font-bold">이달 고객매출 순위</h2>
                <p class="text-xs text-neutral-400">최고 10명</p>
                <ul class="mt-4">
                    <li v-for="(rank, index) in info['clientRank']" :key="index" class="flex justify-between items-center py-2.5">
                        <div class="flex items-center">
                            <p class="font-medium">{{ index + 1 }}</p>
                            <div class="w-7 h-7 rounded-2xl overflow-hidden ml-3 mr-1.5">
                                <img src="@/assets/images/icon-profile.png" alt="profile" title="profile" />
                            </div>
                            <p class="text-neutral-400 text-xs">{{ rank.name }}</p>
                        </div>
                        <p class="text-neutral-400 text-xm">{{ rank.count }}원</p>
                    </li>
                </ul>
            </div>
            <GrayBar class="my-7" />
            <div class="w-full space-y-6">
                <BarAverage title="결제 수단" :numbers="info['payGb']['percent']" />
                <ListAverage :pays="info['payGb']['list']" />
            </div>
            <GrayBar class="my-7" />
            <div>
                <h2 class="font-bold">키워드별 매출 기여도</h2>
                <div class="mx-auto w-40 mt-6 mb-9">
                    <Doughnut :options="chartOptions" :data="chartData" />
                </div>
                <ListAverage :pays="doughnutPd" />
            </div>
        </div>
    </div>
    <FooterVue/>
</template>

<script setup>
import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import SelectBox from "@/components/input/SelectBox.vue";
import GrayBar from "@/components/common/GrayBar.vue";
import FooterVue from "@/views/common/FooterVue.vue";
import BarAverage from "@/components/average/BarAverage.vue";
import ListAverage from "@/components/average/ListAverage.vue";

import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const yearMonth   = computed(() => store.state.data.yearMonth);
const info        = computed(() => store.state.average);
const getDateText = computed(() => {
    const date = yearMonth.value.find(item => item.value === info.value['searchDt']);

    return date['label'];
});

console.log(yearMonth.value);

ChartJS.register(ArcElement, Tooltip);

const chartData = {
    labels: ['블라인드', '커튼'],
    datasets: [
        {
            backgroundColor: ['#78BBFF', '#7CE5AC'],
            data: [87, 13]
        }
    ]
}; 
const chartOptions = {
    responsive: true,
};
// const numbers = [
//     { title: 29, color: 'blue' },
//     { title: 32, color: 'yellow' },
//     { title: 39, color: 'pink' },
// ];
// const pays = [
//     { title: "매출", count: "2,995,000", color: "blue" },
//     { title: "매입", count: "2,995,000", color: "yellow" },
//     { title: "비용", count: "2,995,000", color: "pink" },
// ];
// const paymentMethod = [
//     { title: 52, color: 'blue' },
//     { title: 26, color: 'yellow' },
//     { title: 24, color: 'green' },
// ];
// const paymentPd = [
//     { title: "신용카드 (2회)", count: "2,995,000", color: "blue" },
//     { title: "계좌이체 (8회)", count: "2,995,000", color: "yellow" },
//     { title: "현금 (12회)", count: "2,995,000", color: "green" },
// ];

const doughnutPd = [
    { title: "블라인드 (1개)", count: "2,995,000", color: "blue" },
    { title: "커튼 (2개)", count: "2,995,000", color: "green" },
];

// const ranks = [
//     { name: "홍길동", count: "240,000" },
//     { name: "홍길동", count: "240,000" },
//     { name: "홍길동", count: "240,000" },
// ];

const getSelect = () => {
    const value = event.target.value;

    info.value['searchDt'] = value;
    getData();
}

const getData = () => {
    const params = {
        ceCd    : store.state.login.ceCd,
        dt      : info.value['searchDt']
    };

    store.dispatch('average/getData', params)
}

onMounted(() => {
    getData();
})
</script>

<style lang="scss" scoped>
</style>