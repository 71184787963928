<template>
    <div class="flex items-center justify-between">
        <label :for="id" class="w-1/3 text-sm text-neutral-500">{{ label }}</label>
        <div class="relative w-2/3" :class="[{'border-zinc-200': !error, 'border-red-600': error}]">
            <input :type="type" :id="id" :name="name" :value="value" :placeholder="placeholder" :disabled="disabled" :readonly="readonly" class="w-full pt-2.5 pb-2 pl-3.5 pr-8 border rounded-sm duration-200 disabled:bg-zinc-100 text-right font-bold text-sm"/>
            <span class="absolute bottom-2.5 right-3.5 text-xs">{{ unit }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "InlineUnit",
    props: {
        id         : String,
        label      : String,
        name       : String,
        value      : String,
        readonly   : Boolean,
        type       : String,
        placeholder: String,
        error      : Boolean,
        disabled   : Boolean,
        unit       : String,
    }
}
</script>

<style scoped>

</style>