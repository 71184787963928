import { getAxiosData } from '@/assets/js/function';
/**
 * @description 카카오톡 관련 vuex 모듈
 */
const kakaoModule = {
    namespaced : true, 
    state : {
        useYn   : 'N',
        useCnt  : 0,
        point   : 0,
        sendCnt : 0,
        list    : [],
        info    : getKakaoInfo()
    },
    mutations : {
        setList(state, data)
        {
            state.list      = data['list'];
            state.useYn     = data['useYn'];
            state.useCnt    = data['useCnt'];
            state.point     = data['point'];
            state.sendCnt   = data['sendCnt'];
        },
        setInfo(state, info)
        {
            state.info = info;
        }
    },
    actions : {
        async getKakao(context, params)
        {
            try
            {
                const res  = await getAxiosData('https://data.planorder.kr/kakao/getKakao', params);
                
                context.commit('setList', res.data);
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.log(e);
                return { result: false, msg: 'error' };
            }
        },
        async getKakaoInfo(context, params)
        {
            try
            {
                const res  = await getAxiosData('https://data.planorder.kr/kakao/getKakaoInfo', params);
                
                context.commit('setInfo', res.data);
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.log(e);
                return { result: false, msg: 'error' };
            }
        },
        async getKakaoResList(context, params)
        {
            try
            {
                const res  = await getAxiosData('https://data.planorder.kr/kakao/getKakaoResList', params);

                console.log(res);
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.log(e);
                return { result: false, msg: 'error' };
            }
        },
        async getKakaoResult(context, params)
        {
            try
            {
                await getAxiosData('https://data.planorder.kr/kakao/getKakaoResult', params);
                return { result: true, msg: 'success' };
            }
            catch(e)
            {
                console.log(e);
                return { result: false, msg: 'error' };
            }
        }
    }
};

/**
 * @description 카카오톡 알림 상세 정보 데이터
 */
function getKakaoInfo()
{
    return {
        kdGb        : '001',
        kdNm        : '',
        descrip     : '',
        useYn       : 'N',
        client      : 'N',
        person      : 'N',
        shopAddr    : 'N',
        url         : 'N',
        park        : 'N',
        btnUrl      : ''
    }
}

export default kakaoModule;