<template>
    <button type='button' class="text-center w-full rounded-sm py-3 text-xs font-semibold"
            :class="{
                'text-white bg-brand' : props.blueButton
                , 'bg-gray-200' : props.greyButton
                , 'text-white bg-[#6AB2BE]': props.cyanButton
                , 'text-white bg-[#DBB071]': props.softOrange
                , 'text-white bg-[#FF9800]': props.vividOrange
                , 'bg-neutral-100': props.lightButton}" @click="getBtnClick">
        {{ buttonText }}
    </button>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
    buttonText : String,
    blueButton : Boolean,
    greyButton : Boolean,
    cyanButton : Boolean,
    vividOrange: Boolean,
    softOrange : Boolean,
    lightButton: Boolean,
});

const emit = defineEmits(['getBtnClick']);

const getBtnClick = () => {
    emit('getBtnClick');
}
</script>

<style scoped>

</style>