<template>
    <ModalBox title="고정비용">
        <h2 class="text-lg font-bold text-zinc-700">전체 <span class="text-brand">{{ header['cnt'] }}건</span></h2>
        <h5 class="text-zinc-700 font-bold mt-4 mb-6 text-sm">월간 고정 지출 <span class="text-lg">{{ getAmt(header['amt']) }}</span><span class="text-base">원</span></h5>
        <GrayBar class="mb-2.5" />
        <div class="flex justify-between items-center py-4 border-b">
            <h2 class="text-sm font-bold">고정비 리스트</h2>
            <InlineButton buttonText="지출 등록" blueButton @click="router.push({ path : '/setting/staticPayIng', query : { type : 'I' } })"/>
        </div>
        <div class="border-b py-4 flex justify-between items-center" v-for="(item, index) in list" :key="index" @click="router.push({ path : '/setting/staticPayIng', query : { type : 'M', cd : item.fxCd } })">
            <div>
                <h5 class="font-bold text-sm">{{ item['fxNm'] }}</h5>
                <p class="text-xs text-zinc-400">{{ item['title'] }}</p>
            </div>
            <div class="text-right">
                <p class="text-xs text-zinc-400">{{ item['fxDt'] }}</p>
                <h5 class="font-bold text-sm">{{ getAmt(item['amt']) }}원</h5>
            </div>
        </div>
    </ModalBox>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox.vue";
import GrayBar from "@/components/common/GrayBar.vue";
import InlineButton from "@/components/button/InlineButton.vue";

import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { getCommas } from "@/assets/js/function";

const store  = useStore();
const router = useRouter();

const start     = computed(() => store.state.staticPay.start);
const header    = computed(() => store.state.staticPay.header);
const list      = computed(() => store.state.staticPay.list);

const getList = () => {
    const params = {
        start : start.value,
        ceCd  : store.state.login.ceCd
    }

    store.dispatch('staticPay/getData', params);
}

const getAmt = (amt) => {
    return getCommas(Number(amt));
}

onMounted(() => {
    getList();
});
</script>

<style scoped>

</style>