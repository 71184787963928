<template>
    <ModalBox :title="info['ceNm']+' 계약서'">
        <InfoCard :title="info['ceNm']" :info="info['headers']" />
        <div class="space-y-5 mt-5">
            <TableCard v-for="(table, index) in info['list']" :key="index" :title="table.title" :cards="table.cardLists"
                    :columns="table.columns" :rows="table.rows" :tags="table.tags" :showTag="table.showTag"
                    :showButton="table.showButton"/>
            <GrayBar class="my-5"/>
            <CalculateCard title="합계 금액" borderTop :calcs="info['payList']" totalTitle="총 결제 금액" :totalAmt="totalAmt" />
        </div>
    </ModalBox>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox.vue";
import InfoCard from "@/components/card/InfoCard.vue";
import TableCard from "@/components/card/TableCard.vue";
import GrayBar from "@/components/common/GrayBar.vue";
import CalculateCard from "@/components/card/CalculateCard.vue";
import { getAmt } from '@/assets/js/function';

import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const store  = useStore();
const route  = useRoute();

const info      = computed(() => store.state.conMate);
const totalAmt  = computed(() => getAmt(info.value['payList'], 'total'));

onMounted(() => {
    const params = {
        emCd : atob(route.query.cd)
    };

    store.dispatch('conMate/getInfo', params);
});
</script>

<style scoped>

</style>