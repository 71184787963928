<template>
    <ModalBox title="고정비용 등록" showFixedButton showButton buttonText="저장" @close="router.go(-1)" @blueBtnClick="getStaticPayCheck">
        <button type="button" v-if="route.query.type === 'M'" class="text-red-500 bg-white rounded-sm border border-gray-200 py-1 px-4 text-xm absolute top-3 right-4">비용 삭제</button>
        <div class="space-y-5">
            <LabelInput type="text" id="title" :value="info['title']" label="고정비용 이름" @input="getInputValSet"/>
            <LabelDate id="fxDt" :value="info['fxDt']" label="일자" @getDate="getDate"/>
            <LabelInput type="number" id="amt" :value="info['amt']" label="금액" @input="getInputValSet"/>
            <LabelSelect id="fxGb" :options="fxGb" :value="info['fxGb']" label="분류 선택" @change="getSelect"/>
            <LabelTextarea rowCount="10" id="contents" :value="info['contents']" label="메모" @input="getInputValSet"/>
        </div>
    </ModalBox>
    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp" @getCancle="getCancle"/>
</template>

<script setup>
import ModalBox from "@/components/modal/ModalBox.vue";
import LabelInput from "@/components/input/LabelInput.vue";
import LabelSelect from "@/components/input/LabelSelect.vue";
import LabelTextarea from "@/components/input/LabelTextarea.vue";
import LabelDate from "@/components/input/LabelDate.vue";
import AlertBox from "@/components/modal/AlertBox.vue";

import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { getAxiosData } from "@/assets/js/function";

const store  = useStore();
const route  = useRoute();
const router = useRouter();

const fxGb   = computed(() => store.state.data.fxGb);
const info   = computed(() => store.state.staticPay.info);

const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const focusId  = ref('');
const resultYn = ref('N');

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
}

const getComp = () => {
    alertData.value['view'] = false;

    if(resultYn.value === 'Y')
    {
        getStaticPayResult();
    }
    else
    {
        if(focusId.value === '')
        {
            focusId.value = 'title';
        }

        document.getElementById(focusId.value).focus();
    }
}

const getCancle = () => {
    alertData.value['view'] = false;
}

const getInputValSet = () => {
    info.value[event.target.id] = event.target.value;
}

const getDate = (id, date) => {
    info.value[id] = date;
}

const getSelect = () => {
    info.value['fxGb'] = event.target.value;
}

const getValCheck = () => {
    if(info.value['title'] === '')
    {
        getAlertDataSet(true, '입력', '고정비용 이름을 입력해주세요.', false);
        focusId.value = 'title';
        return false;
    }

    if(Number(info.value['amt']) === 0)
    {
        getAlertDataSet(true, '입력', '금액을 입력해주세요.', false);
        focusId.value = 'amt';
        return false;
    }

    return true;
}

const getStaticPayCheck = () => {
    if(getValCheck())
    {
        getAlertDataSet(true, '저장', '저장하시겠습니까?', true);
        resultYn.value = 'Y';
    }
}

const getStaticPayResult = () => {
    const params = {
        type   : route.query.type,
        ceCd   : store.state.login.ceCd,
        userCd : store.state.login.userCd,
        ...info.value
    }

    if(route.query.type === 'M')
    {
        params['fxCd'] = route.query.cd;
    }

    getAxiosData('https://data.planorder.kr/staticPay/getResult', params).then((res) => {
        if(res['status'] === 200)
        {
            router.go(-1);
        }
        else
        {
            getAlertDataSet(true, '저장', '저장에 실패하였습니다. 지속될 경우 관리자에게 문의해주세요.', false);
            resultYn.value = 'N';
        }
    });
}

onMounted(() => {
    store.commit('staticPay/setReset');

    if(route.query.type === 'M')
    {
        const params = {
            fxCd : route.query.cd
        };

        store.dispatch('staticPay/getInfo', params);
    }
});
</script>

<style scoped>

</style>