<template>
    <ToggleBox title="계약정보" showFixedButton showBlueButton blueButtonText="계약서 이동" @getBlueBtnClick="getConCheck" :popupActive="info['popStat']" @getClose="getClose">
        <div class="space-y-3">
            <InlineDate id="conDt" :value="info['conDt']" label="계약일" :timePicker=true readonly @getDate="getDate"/>
            <InlineDate id="deliDt" :value="info['deliDt']" label="시공일" :timePicker=true @getDate="getDate"/>
            <LabelTime id="insTime" :value="insTime" label="설치시간" @getTime="getTime"/>
            <InlineSelect id="person" :options="person" label="시공 담당" @change="getSelect"/>
            <InlineDual label="선금">
                <BasicSelect id="payGb" :options="payGb" class="w-1/2" @change="getSelect"/>
                <BasicInput id="amt" type="number" :value="info['amt']" unit="원" showUnit unitBlue class="w-1/2" @input="getInputVal"/>
            </InlineDual>
            <InlineInput type="text" id="memo" label="메모" placeholder="비고" @input="getInputVal"/>
        </div>
    </ToggleBox>
    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp" @getCancle="getCancle"/>
</template>

<script setup>
import ToggleBox from "@/components/modal/ToggleBox.vue";
import InlineDate from "@/components/input/InlineDate.vue";
import LabelTime from "@/components/input/LabelTime.vue";
import InlineInput from "@/components/input/InlineInput.vue";
import InlineSelect from "@/components/input/InlineSelect.vue";
import InlineDual from "@/components/input/InlineDual.vue";
import BasicSelect from "@/components/input/BasicSelect.vue";
import BasicInput from "@/components/input/BasicInput.vue";
import AlertBox from "@/components/modal/AlertBox.vue";

import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

const store     = useStore();
const route     = useRoute();
const router    = useRouter();

const info      = computed(() => store.state.con.info);
const insTime   = computed(() => store.state.con.insTime);
const person    = computed(() => store.state.con.person);
const dcInfo    = computed(() => store.state.esti.dcInfo);
const addInfo   = computed(() => store.state.esti.addInfo);
const cutInfo   = computed(() => store.state.esti.cutInfo);
const payGb     = computed(() => store.state.data.payGb);

const resultYn = ref('N');

const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
}

const getComp = () => {
    alertData.value['view'] = false;

    if(resultYn.value === 'Y')
    {
        getConResult();
    }
}

const getCancle = () => {
    alertData.value['view'] = false;
};

const getClose = () => {
    store.commit('con/setStat');
}

const getDate = (id, date) => {
    info.value[id] = date;
}

const getTime = (time) => {
    insTime.value = time;
}

const getSelect = () => {
    const id    = event.target.id;
    const value = event.target.value;

    info.value[id] = value;
}

const getInputVal = () => {
    const id    = event.target.id;
    const value = event.target.value;

    info.value[id] = value;
}

const getConValCheck = () => {
    if(info.value['conDt'] === '')
    {
        getAlertDataSet(true, '계약일', '계약일을 입력해주세요.', false);
        return false;
    }

    if(info.value['deliDt'] === '')
    {
        getAlertDataSet(true, '시공일', '시공일을 입력해주세요.', false);
        return false;
    }

    if(insTime.value['hours'] === 0 && insTime.value['minutes'] === 0)
    {
        getAlertDataSet(true, '설치시간', '설치시간을 입력해주세요.', false);
        return false;
    }

    return true;
}

const getConCheck = () => {
    if(getConValCheck())
    {
        getAlertDataSet(true, '계약', '계약정보에 맞게 계약을 진행하시겠습니까?', true);
        resultYn.value = 'Y';
    }
}

const getConResult = () => {
    info.value['person'] =  info.value['person'] === '' ? person.value[0]['value'] : info.value['person'];
    info.value['payGb']  =  info.value['payGb'] === '' ? payGb.value[0]['value'] : info.value['payGb'];

    const params = {
        ceCd     : store.state.login.ceCd,
        userCd   : store.state.login.userCd,
        emCd     : route.query.emCd,
        clientCd : route.query.clientCd,
        addInfo  : addInfo.value,
        dcInfo   : dcInfo.value,
        cutInfo  : cutInfo.value,
        conInfo  : info.value
    }

    const contract = store.dispatch('con/getContract', params);

    contract.then((data) => {
        resultYn.value = 'N';

        if(data['result'])
        {
            router.push({ path : '/customer/orderList', query : { emCd : route.query.emCd }, replace : true});
        }
        else
        {
            getAlertDataSet(true, '확인', data['msg'], false);
        }
    })
}

onMounted(() => {
    const params = {
        ceCd : store.state.login.ceCd,
        emCd : route.query.emCd
    };

    store.dispatch('con/getData', params);
});
</script>

<style scoped>

</style>