<template>
    <div class="popup fixed flex items-end bg-black/60 w-full h-screen top-0 left-0 right-0 mx-auto z-40" :class="{ active : true }">
        <div class="popup-box mx-auto bg-white w-11/12 p-4 pb-6 rounded-tl rounded-tr" :class="{ active : true }">
            <div class="mb-4 flex justify-between items-center">
                <h3 class="text-sm">{{ title }}</h3>
                <FontAwesomeIcon :icon="['fas', 'x']" class="text-zinc-500 cursor-pointer" @click="getClose"/>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { defineProps, defineEmits } from 'vue';

defineProps({
    title: String,
});

const emit = defineEmits(['getClose']);

const getClose = () => {
    emit('getClose');
}
</script>

<style lang="scss" scoped>
.popup {
    display: none;

    &.active {
        display: flex;
    }
}

.popup-box {
    transform: translateY(100%);

    &.active {
        transform: translateY(0);
    }
}
</style>