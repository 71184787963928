<template>
    <ModalBox title="제품등록" 
        showBlueButton blueButtonText="제품 변경" @anotherBtnClick="getProductState" 
        showFixedButton showButton buttonText="선택 완료" @blueBtnClick="getEstiCheck">
        <div class="flex justify-between items-center">
            <SpanTag :spanText="common['itemNm']+' '+common['icNm']" class="blue" />
            <div class="text-sm font-medium text-neutral-700">
                ({{ common['unitSize']+common['unitNm'] }}) {{ common['saleUnit'] }}원
            </div>
        </div>
        <GrayBar class="my-4" />

        <div class="space-y-4">
            <!-- 폭, 야드 -->
            <template v-if="common['unit'] === '002' || common['unit'] === '003'">
                <div class="flex space-x-3">
                    <LabelInput class="w-1/2" id="location" :value="common['location']" label="설치위치" placeholder="기타" important @input="getComValSet" />
                    <LabelSelect class="w-1/2"  id="proc" :options="proc" :value="curtain['proc']" label="가공방법" placeholder="가공방법" @change="getCurtainValSet"/>
                </div>

                <div class="flex space-x-3">
                    <LabelSelect class="w-1/4" id="addColor" :options="addColor" :value="curtain['addColor']" label="색상 추가" placeholder="옵션명" @change="getCurtainValSet"/>
                    <LabelSelect class="w-1/4" id="shape" :options="shape" :value="curtain['shape']" label="형상 선택" placeholder="옵션명" @change="getCurtainValSet"/>
                    <LabelSelect class="w-1/4" id="split" :options="split" :value="curtain['split']" label="분할" placeholder="옵션명" @change="getCurtainValSet"/>
                    <NumberInput class="w-1/4" id="cQty" :value="curtain['cQty']" label="수량" important @input="getCurtainValSet"
                        @click="getQtyFocus('C')" @getQtyPlus="getQty('cQty', 'P', 'C')" @getQtyMinus="getQty('cQty', 'M', 'C')"/>
                </div>

                <div class="flex space-x-3">
                    <LabelInput class="w-1/4" type="number" id="cWidth" :value="common['width']" label="가로(CM)" important @input="getComValSet" />
                    <LabelInput class="w-1/4" type="number" id="cHeight" :value="common['height']" label="세로(CM)" important @input="getComValSet" />
                    <LabelSelect class="w-1/4" id="use" :options="use" :value="curtain['use']" label="원단 사용량" placeholder="옵션명" @change="getCurtainValSet"/>
                    <LabelUnit class="w-1/4" id="size" :value="curtain['size']" label="최종 계산" :unit="common['unitNm']" textBlue @change="getCurtainValSet"/>
                </div>

                <!-- 색상 추가 -->
                <template v-if="curtain['addColor'] === 'T'">
                    <div class="border-y border-dotted py-3.5 my-3.5">
                        <p class="text-brand text-xm mb-2.5">안쪽컬러를 선택해 주세요.</p>
                        <div class="flex space-x-3">
                            <LabelSelect class="w-3/4" id="inIcNm" :options="getInIcList" label="안쪽 컬러" placeholder="옵션명" />
                            <LabelUnit class="w-1/4" id="inSize" unit="폭" textBlue />
                        </div>
                        <div class="flex space-x-3">
                            <LabelInput class="w-3/4" type="text" :value="common['itemNm']+' / '+common['icNm']" label="기둥 컬러" important disabled />
                            <LabelUnit class="w-1/4" id="outSize" label="최종 계산" unit="폭" textBlue disabled />
                        </div>
                    </div>
                </template>
            </template>

            <!-- 회베 -->
            <template v-if="common['unit'] === '001'">
                <div class="flex space-x-3">
                    <LabelInput class="w-1/2" id="location" :value="common['location']" label="설치위치" placeholder="기타" important @input="getComValSet"/>
                    <LabelSelect class="w-1/2"  id="division" :options="division" :value="Number(blind['division'])" label="분할" placeholder="분할" @change="getDivision"/>
                </div>

                <div class="flex space-x-3">
                    <LabelInput class="w-1/4" type="number" id="bWidth" :value="common['width']" label="가로(CM)" important @input="getComValSet"/>
                    <LabelInput class="w-1/4" type="number" id="bHeight" :value="common['height']" label="세로(CM)" important @input="getComValSet"/>
                    <!-- 분할없음 -->
                    <template v-if="Number(blind['division']) === 1">
                        <NumberInput class="w-1/4" id="leftQty" :value="Number(blind['leftQty'])" label="수량(좌)" important @input="getBlindValSet" 
                            @click="getQtyFocus('B')" @getQtyPlus="getQty('leftQty', 'P', 'B')" @getQtyMinus="getQty('leftQty', 'M', 'B')"/>
                        <NumberInput class="w-1/4" id="rightQty" :value="Number(blind['rightQty'])" label="수량(우)" important @input="getBlindValSet" 
                            @click="getQtyFocus('B')" @getQtyPlus="getQty('rightQty', 'P', 'B')" @getQtyMinus="getQty('rightQty', 'M', 'B')"/>
                    </template>

                    <!-- 분할 -->
                    <template v-if="Number(blind['division']) > 1">
                        <LabelInput class="w-1/4" type="number" id="bQty" :value="blind['bQty']" label="수량" important @input="getBlindValSet"/>
                        <InlineButton blueButton class="1/4" buttonText="균등분할" @click="getEqual"/>
                    </template>
                </div>

                <!-- 회베 분할 -->
                <template v-if="Number(blind['division']) > 1">
                    <p class="text-brand text-xm mb-2.5">분할{{ Number(blind['division']) }}창 (아래값만 입력해주세요.)</p>
                    <template v-for="(item, index) in blind['divSpec']" :key="index">
                        <div class="flex space-x-3">
                            <LabelInput class="w-1/4" type="number" :id="'bWidth'+index" :value="item['width']" @input="getDivBlindValSet(index)"/>
                            <LabelInput class="w-1/4" type="number" :value="common['height']" readonly/>
                            <LabelSelect class="w-1/4" :options="handle" :value="item['handle']" placeholder="손잡이" @change="getHandleChange(index)"/>
                            <LabelUnit class="w-1/4" :unit="item['size']+'회베'" disabled />
                        </div>
                    </template>
                </template>
            </template>

            <!-- EA -->
            <template>
                <div class="flex space-x-3">
                    <LabelInput class="w-3/4" type="text" id="location" :value="common['location']" label="설치위치" important placeholder="기타" @input="getComValSet"/>
                    <NumberInput class="w-1/4" id="" name="" label="수량" important />
                </div>
            </template>
        </div>

        <MoreCard moreText="옵션 더보기">
            <div class="flex">
                <LabelSelect  id="" name="" :options="''" placeholder="옵션명" class="mr-3" />
                <LabelSelect  id="" name="" :options="''" placeholder="옵션 세부명" />
            </div>
            <LabelInput id="memo" type="text" placeholder="지시사항" @input="getComValSet" />
        </MoreCard>

        <GrayBar class="my-4" />

        <CalculateCard title="총 단위" :unit="total['totalUnitSize']+common['unitNm']" :calcs="calcList" totalTitle="총 금액" :totalAmt="total['totalSaleAmt']" borderTop showUnit />
        <ProductChoice v-if="productState" @getClose="getProductState"/>
    </ModalBox>
    <AlertBox v-if="alertData['view']" 
        :type="alertData['type']" :title="alertData['title']" :description="alertData['description']" :showGreyButton="alertData['state']" 
        @getComp="getComp" @getCancle="getCancle"/>
</template>

<script setup>
import SpanTag from "@/components/common/SpanTag.vue";
import ModalBox from "@/components/modal/ModalBox.vue";
import GrayBar from "@/components/common/GrayBar.vue";
import LabelInput from "@/components/input/LabelInput.vue";
import NumberInput from "@/components/input/NumberInput.vue";
import MoreCard from "@/components/card/MoreCard.vue";
import LabelSelect from "@/components/input/LabelSelect.vue";
import CalculateCard from "@/components/card/CalculateCard.vue";
import LabelUnit from "@/components/input/LabelUnit.vue";
import InlineButton from "@/components/button/InlineButton.vue";
import ProductChoice from "@/views/customer/ProductChoice.vue";
import AlertBox from "@/components/modal/AlertBox.vue";

import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import { getAxiosData } from '@/assets/js/function'
import { getHebe, getPok, getYard } from '@/assets/js/order';
import { getHebeCalc, getPokCalc, getYardCalc, getBlindParams, getCurtainParams } from '@/assets/js/calcAndProcess';

const store   = useStore();
const route   = useRoute();
const router  = useRouter();

const division  = computed(() => store.state.data.division);
const common    = computed(() => store.state.esti.common);

const curtain   = computed(() => store.state.esti.curtain);
const blind     = computed(() => store.state.esti.blind);
const handle    = [{ value : '좌', label : '좌' }, { value : '우', label : '우' }];

const proc      = computed(() => store.state.data.proc);
const use       = computed(() => store.state.data.use);
const addColor  = computed(() => store.state.data.addColor);
const shape     = computed(() => store.state.data.shape);
const split     = computed(() => store.state.data.split);

const total     = computed(() => store.state.esti.total);

const alertData = ref({
    view          : false,
    title         : '',
    description   : '',
    state         : false
});

const focusId  = ref('');
const resultYn = ref('N');

const getAlertDataSet = (view, title, dec, state) => {
    alertData.value['view']        = view;
    alertData.value['title']       = title;
    alertData.value['description'] = dec;
    alertData.value['state']       = state;
}

const getComp = () => {
    alertData.value['view'] = false;

    if(resultYn.value === 'N')
    {
        if(focusId.value !== '')
        {
            document.getElementById(focusId.value).focus();
        }
    }
    else
    {
        getEstiResult();
    }
}

const getCancle = () => {
    alertData.value['view'] = false;
}

const productState = ref(true);
const calcList     = computed(() => [
    { title: '제품 금액', amt: total.value['totalItemSaleAmt'] },
    { title: '옵션 금액', amt: total.value['totalOptionSaleAmt'] },
    { title: '형상 금액', amt: total.value['totalShapeSaleAmt'] },
    { title: '세로길이 추가 금액', amt: total.value['totalHeightSaleAmt'] },
    // { title: '할인 금액', amt: total.value['totalDcCalcAmt'] }
]);

const getInIcList  = computed(() => {
    console.log(store.state.product.list);
    // const itemList = store.state.product.list.find(item => item['itemCd'] === common.value['itemCd']);
    // const icList   = itemList.find(item => item['icCd'] === common.value['icCd']);

    return [];
});

const getProductState = (gubun = '') => {
    productState.value = !productState.value;

    if(gubun === 'select')
    {
        getUnitCalc();
    }
};

const getComValSet = () => {
    const id    = event.target.id;
    const value = event.target.value;

    if(id === 'bWidth' || id === 'cWidth')
    {
        common.value['width'] = value;
    }
    else if(id === 'bHeight' || id === 'cHeight')
    {
        common.value['height'] = value
    }
    else
    {
        common.value[id] = value
    }

    if(id !== 'location')
    {
        getUnitCalc();
    }
};

const getQtyFocus = (gubun) => {
    const target = gubun === 'B' ? blind.value : curtain.value;
    const id     = event.target.id;

    target[id] = target[id] === 0 ? '' : target[id];
};

const getQty = (id, type, gubun) => {
    const target    = gubun === 'B' ? blind.value : curtain.value;
    const increment = type === 'M' ? -1 : 1;

    target[id] = Math.max(Number(target[id]) + increment, 0);

    getUnitCalc();
}

const getCurtainValSet = () => {
    const id    = event.target.id;
    const value = event.target.value;

    curtain.value[id] = value;

    if(id !== 'proc' || id !== 'addColor' || id !== 'split')
    {
        getUnitCalc();
    }
}

const getBlindValSet = () => {
    const id    = event.target.id;
    const value = event.target.value;

    blind.value[id] = value;
    getUnitCalc();
};

/** 블라인드 관련 함수 */
const getDivision = (event) => {
    blind.value['division'] = Number(event.target.value);

    getDivisionSet();
};

const getDivisionSet = () => {
    const division  = Number(blind.value['division']);

    const specArr   = [];
    const specInfo  = blind.value['divSpec'];

    for(let i=0; i<division; i++)
    {
        let spec = {
            width  : specInfo[i] !== undefined ? specInfo[i]['width'] : '',
            height : common.value['height'],
            handle : i === 0 ? '좌' : '우',
            size   : getHebe({
                width  : specInfo[i] !== undefined ? specInfo[i]['width'] : '',
                height : Number(common.value['height']),
                size   : Number(common.value['unitSize'])
            })
        }

        specArr.push(spec);
    }

    blind.value['divSpec'] = specArr;
    getUnitCalc();
}

const getDivBlindValSet = (index) => {
    const value = Number(event.target.value);

    blind.value['divSpec'][index]['width'] = value;
    blind.value['divSpec'][index]['size']  = getHebe({
        width  : value,
        height : Number(common.value['height']),
        size   : 0
    });

    const total = blind.value['divSpec'].reduce((acc, val) => acc + Number(val['width']), 0);

    common.value['width'] = Math.round(Number(total) * 10) / 10;
    getUnitCalc();
}

const getHandleChange = (index) => {
    const value = event.target.value;

    blind.value['divSpec'][index]['handle'] = value;
}

const getEqual = () => {
    const width    = Number(common.value['width']);
    const division = Number(blind.value['division']);

    let divisionWidth, nam, lastWidth;

    if(width > 0)
    {
        divisionWidth   = Number(Math.floor((width / division) * 10) / 10); /** 분할길이 */
        nam             = (divisionWidth * (division - 1)).toFixed(1);      /** 나머지 값 */
        lastWidth       = Number((width - nam).toFixed(1));                 /** 나머지 길이 */

        for(let i=0; i<division; i++)
        {
            blind.value['divSpec'][i]['width'] = (i === (division-1) ? lastWidth : divisionWidth);
            blind.value['divSpec'][i]['size']  = getHebe({
                width  : i === (division-1) ? lastWidth : divisionWidth,
                height : common.value['height'],
                size   : 0
            })
        }
    }

    getUnitCalc();
}

/**
 * @description 금액 계산 처리
 */
const getUnitCalc = () => {
    let info;

    switch(common.value['unit'])
    {
        case '001': /** 회배 */
            info = getHebeCalc(common.value, blind.value);

            total.value['totalQty']         = Number(blind.value['division']) === 1 ? (Number(blind.value['leftQty']) + Number(blind.value['rightQty'])) : Number(blind.value['bQty']);
            total.value['totalUnitSize']    = info['hebe'];

            if(Number(blind.value['division']) > 1)
            {
                total.value['eachItemSaleAmt'] = info['eachItemSaleAmt'];
                total.value['eachItemSaleTax'] = info['eachItemSaleTax'];
                total.value['eachItemPurcAmt'] = info['eachItemPurcAmt'];
                total.value['eachItemPurcTax'] = info['eachItemPurcTax'];
            }

            total.value['totalShapeSaleAmt']   = 0;
            total.value['totalHeightSaleAmt']  = 0;
            break;
        case '002': /** 야드 */
            curtain.value['size'] = getYard({
                width   : Number(common.value['width']),
                usage   : Number(curtain.value['use']),
                size    : Number(common.value['unitSize']),
                los     : curtain.value['los']
            });

            info = getYardCalc(common.value, curtain.value);

            total.value['totalQty']          = Number(curtain.value['cQty']);
            total.value['totalUnitSize']     = info['yard'];
            
            total.value['totalShapeSaleAmt']    = Number(info['shapeSaleAmt']);
            total.value['totalShapeSaleTax']    = Number(info['shapeSaleTax']);
            total.value['totalShapePurcAmt']    = Number(info['shapePurcAmt']);
            total.value['totalShapePurcTax']    = Number(info['shapePurcTax']);
            break;
        case '003': /** 폭 */
            curtain.value['size'] = getPok({
                width   : Number(common.value['width']),
                usage   : Number(curtain.value['use']),
                size    : Number(common.value['unitSize']),
                pokSpec : curtain.value['pokSpec'],
                los     : curtain.value['los']
            });
            
            info = getPokCalc(common.value, curtain.value);

            total.value['totalQty']             = Number(curtain.value['cQty']);
            total.value['totalUnitSize']        = info['pok'];

            total.value['totalHeightSaleAmt']   = Number(info['heightSaleAmt']);
            total.value['totalHeightSaleTax']   = Number(info['heightSaleTax']);
            total.value['totalHeightPurcAmt']   = Number(info['heightPurcAmt']);
            total.value['totalHeightPurcTax']   = Number(info['heightPurcTax']);

            total.value['totalShapeSaleAmt']    = Number(info['shapeSaleAmt']);
            total.value['totalShapeSaleTax']    = Number(info['shapeSaleTax']);
            total.value['totalShapePurcAmt']    = Number(info['shapePurcAmt']);
            total.value['totalShapePurcTax']    = Number(info['shapePurcTax']);
            break;
        default:    /** 기타(EA) */
            
            break;
    }

    total.value['totalItemSaleAmt']   = info['itemSaleAmt'];
    total.value['totalItemSaleTax']   = info['itemSaleTax'];
    total.value['totalItemPurcAmt']   = info['itemPurcAmt'];
    total.value['totalItemPurcTax']   = info['itemPurcTax'];
    /** 옵션 추가 필요 */
    total.value['totalSaleAmt']       = info['totalSaleAmt'];
    total.value['totalSaleTax']       = info['totalSaleTax'];
    total.value['totalPurcAmt']       = info['totalPurcAmt'];
    total.value['totalPurcTax']       = info['totalPurcTax'];
    // total.value['totalDcCalcAmt']     = info['dcAmt'];
}

const getValCheck = () => {
    switch(common.value['unit'])
    {
        case '001':
            if(Number(common.value['width']) === 0 || Number(common.value['height']) === 0)
            {
                getAlertDataSet(true, '확인', '가로 / 세로 값을 입력하세요.', false);
                focusId.value = 'bHeight';
                return false;
            }

            /** 분할 구분 */
            if(Number(blind.value['division']) === 1)
            {
                if(Number(blind.value['leftQty']) === 0 && Number(blind.value['rightQty']) === 0)
                {
                    getAlertDataSet(true, '확인', '좌 / 우 수량 중 하나는 입력이 필요합니다.', false);
                    focusId.value = 'leftQty';
                    return false;
                }
            }
            else
            {
                if(Number(blind.value['bQty']) === 0)
                {
                    getAlertDataSet(true, '확인', '수량을 입력하세요.', false);
                    focusId.value = 'bQty';
                    return false;
                }

                let divWidthSum = 0;
                let divTarget   = 0;
                let divStat     = true;

                blind.value['divSpec'].forEach((item, index) => {
                    divWidthSum += Number(item['width']);

                    if(Number(item['width']) === 0)
                    {
                        divTarget = index;
                        divStat   = false;
                    }
                });

                if(!divStat)
                {
                    focusId.value = 'bWidth'+divTarget;
                    getAlertDataSet(true, '확인', '분할 길이를 입력하세요.', false);
                    return false;
                }

                if(divWidthSum !== Number(common.value['width']))
                {
                    getAlertDataSet(true, '확인', '분할 길이의 합이 가로길이와 일치하지 않습니다.', false);
                    return false;
                }
            }
        break;
        case '002': case '003':
            if(Number(common.value['width']) === 0 || Number(common.value['height']) === 0)
            {
                getAlertDataSet(true, '확인', '가로 / 세로 값을 입력하세요.', false);
                focusId.value = 'cHeight';
                return false;
            }

            if(Number(curtain.value['cQty']) === 0)
            {
                getAlertDataSet(true, '확인', '수량을 입력하세요.', false);
                focusId.value = 'cQty';
                return false;
            }

            if(Number(curtain.value['size']) === 0)
            {
                getAlertDataSet(true, '확인', '최종 계산 값이 비었습니다.', false);
                focusId.value = 'size';
                return false;
            }
        break;
        default:
    }

    return true;
};

const getEstiCheck = () => {
    if(getValCheck())
    {
        getAlertDataSet(true, '저장', '견적 저장하시겠습니까?.', true);
        resultYn.value = 'Y';
    }
}

const getEstiResult = async () => {
    let params;

    switch(common.value['unit'])
    {
        case '001':
            params = getBlindParams(common.value, blind.value);

            params['eachItemSaleAmt']   = total.value['eachItemSaleAmt'];
            params['eachItemSaleTax']   = total.value['eachItemSaleTax'];
            params['eachItemPurcAmt']   = total.value['eachItemPurcAmt'];
            params['eachItemPurcTax']   = total.value['eachItemPurcTax'];
        break;
        case '002': case '003':
            params = getCurtainParams(common.value, curtain.value);

            params['totalHeightSaleAmt']   = total.value['totalHeightSaleAmt'];
            params['totalHeightSaleTax']   = total.value['totalHeightSaleTax'];
            params['totalHeightPurcAmt']   = total.value['totalHeightPurcAmt'];
            params['totalHeightPurcTax']   = total.value['totalHeightPurcTax'];

            params['totalShapeSaleAmt']    = total.value['totalShapeSaleAmt'];
            params['totalShapeSaleTax']    = total.value['totalShapeSaleTax'];
            params['totalShapePurcAmt']    = total.value['totalShapePurcAmt'];
            params['totalShapePurcTax']    = total.value['totalShapePurcTax'];
        break;
    }

    params['type']              = route.query.type;
    params['ceCd']              = store.state.login.ceCd;
    params['emCd']              = route.query.emCd;
    params['clientCd']          = route.query.clientCd;
    params['edCd']              = route.query.type === 'M' ? route.query.edCd : ''; /** 수정모드 경우 추가 */

    params['totalUnit']         = total.value['totalUnitSize'];

    params['pdSaleAmt']         = Number(total.value['totalItemSaleAmt']);
    params['pdSaleTax']         = Number(total.value['totalItemSaleTax']);
    params['pdPurcAmt']         = Number(total.value['totalItemPurcAmt']);
    params['pdPurcTax']         = Number(total.value['totalItemPurcTax']);

    params['totalSaleAmt']      = total.value['totalSaleAmt'];
    params['totalSaleTax']      = total.value['totalSaleTax'];
    params['totalPurcAmt']      = total.value['totalPurcAmt'];
    params['totalPurcTax']      = total.value['totalPurcTax'];

    params['itemCnt']           = total.value['totalQty'];

    try
    {
        const res = await getAxiosData('https://data.planorder.kr/esti/getResult', params);

        console.log(res);

        if(res.data['result'])
        {
            switch(route.query.type)
            {
                case 'M':
                    router.go(-1);
                break;
                case 'I':
                    router.push({ path: '/customer/estiList', query: { clientCd : route.query.clientCd, emCd : route.query.emCd }, replace : true});
                break;
                case 'N':
                    router.push({ path: '/customer/estiList', query: { clientCd : route.query.clientCd, emCd : res.data['emCd'] }, replace : true});
                break;
            }
        }
        else
        {
            getAlertDataSet(true, '저장실패', '견적서 저장에 실패하였습니다. 지속될 경우 관리자에게 문의하세요.', false);
        }

        resultYn.value = 'N';
    }
    catch(e)
    {
        console.log(e);
        resultYn.value = 'N';
        getAlertDataSet(true, '저장실패', '견적서 저장에 실패하였습니다. 지속될 경우 관리자에게 문의하세요.', false);
    }
}

onMounted(() => {
    switch(route.query.type)
    {
        case 'N': case 'I': /** 추가 등록 */
            store.commit('esti/setEstiIngReset');
        break;
        case 'M': /** 수정 */
            productState.value = false;
            var info = store.dispatch('esti/getInfo', { edCd : route.query.edCd });

            info.then(() => {
                getUnitCalc();
            }).catch(() => {
                getAlertDataSet(true, '확인', '견적 조회에 실패하였습니다. 지속될 경우 관리자에게 문의하세요.', false);
            });
        break;
    }
});

</script>

<style scoped>

</style>