import axios from "axios";

/**
 * @description 숫자 콤마 처리
 */
export function getCommas(value) 
{
    if(typeof value !== 'number')
    {
        return value;
    }
    
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * @description 오늘 날짜 형식별로 불러오기
 */
// export function getDate(type)
// {
//     let date = '';

//     const year  = currentDate.getFullYear();
//     const month = String(currentDate.getMonth() + 1).padStart(2, '0');
//     const day   = String(currentDate.getDate()).padStart(2, '0');
//     const hour  = String(currentDate.getHours()).padStart(2, '0'); // 시 수정
//     const min   = String(currentDate.getMinutes()).padStart(2, '0'); // 분 수정

//     switch(type)
//     {
//         case 'Y-m-d':
//             date = `${year}-${month}-${day}`;
//         break;
//         case 'Y-m-d H:i':
//             date = `${year}-${month}-${day} ${hour}:${min}`;
//         break;
//     }

//     return date;
// }

/**
 * @description 날짜 추가
 */
export function getAddDate(days)
{
	const result = new Date();
	result.setDate(result.getDate() + days);

	return result;
}

/**
 * @description 날짜 변환 처리
 */
export function getConvertDate(date, type) 
{
	let y = date.getFullYear();
	let m = (date.getMonth() + 1) < 10 ? '0'+(date.getMonth() + 1) : (date.getMonth() + 1);
	let d = date.getDate() < 10 ? '0'+date.getDate() : date.getDate();
	let h = date.getHours() < 10 ? '0'+date.getHours() : date.getHours();
	let i = date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes();
	let s = date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds();

	let weekStr = ['일', '월', '화', '수', '목', '금', '토'];
	let w       = weekStr[date.getDay()];
	let yy      = y.toString().substring(2,4);

	let result;

	switch (type) 
    {
		case "yyyymm":
			result = y+''+m;
		break;
		case "yyyy-mm-dd" :
			result = y+'-'+m+'-'+d;
			break;
		case "yy-mm-dd" :
			result = yy +'-'+m+'-'+d;
			break;
		case "yy.mm.dd" :
			result = yy +'.'+m+'.'+d;
			break;
		case "yy.mm.dd.w" :
			result = yy +'.'+m+'.'+d+'('+w+')';
			break;
		case "yyyy-mm" :
			result = y+'-'+m;
			break;
		case "yy-mm" :
			result = yy +'-'+m;
			break;
		case "yy.mm" :
			result = yy +'.'+m;
			break;
		case "yyyy-mm-dd hh:ii" :
			result = y+'-'+m+'-'+d+' '+h+':'+i;
			break;
		case "hh:ii" :
			result = h+':'+i;
			break;
		case "mm%dd%w% hh:ii" :
			result = m+'.'+d+'('+w+') '+h+':'+i;
			break;
		case "mm%dd%w%" :
			result = m+'.'+d+'('+w+')';
			break;
		case "mm%dd%w%2" :
			result = m+'월'+d+'일 '+w+'요일';
			break;
		case "mm%dd%" :
			result = m+'월'+d+'일';
			break;
		case "yyyy" :
			result = y;
			break;
		case "mm" :
			result = m;
			break;
		case "ymdhis" :
			result = String(y)+String(m)+String(d)+String(h)+String(i)+String(s)
			break;
		case "mm-dd":
			result = m+'-'+d;
			break;
		default :
			result = y+'-'+m+'-'+d;
			break;
	}

	return result;
}

/**
 * @description 오늘일자(Y-m-d H:i)
 */
export function getTodayTimeDate()
{
    const currentDate = new Date();

    const year  = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day   = String(currentDate.getDate()).padStart(2, '0');
    const hour  = String(currentDate.getHours()).padStart(2, '0'); // 시 수정
    const min   = String(currentDate.getMinutes()).padStart(2, '0'); // 분 수정

    return `${year}-${month}-${day} ${hour}:${min}`;
}

export function getCardColumns(unit)
{
	let data = [];

	switch(unit)
	{
		case '001': /** 블라인드 */
			data.push(
				{ key: 'width', header: '가로' },
				{ key: 'height', header: '세로' },
				{ key: 'leftQty', header: '좌' },
				{ key: 'rightQty', header: '우' },
				{ key: 'size', header: '규격' }
			)
		break;
		case '002': case '003': /** 커튼 */
			data.push(
				{ key: 'width', header: '가로' },
				{ key: 'height', header: '세로' },
				{ key: 'proc', header: '가공' },
				{ key: 'split', header: '분할' },
				{ key: 'size', header: '규격' }
			)
		break;
		default: /** 기타(EA) */
	}

	return data;
}

/**
 * @description 상황별 할인 및 결제 처리할 금액 추출
 */
export function getAmt(data, type)
{
	const arr = ['itemAmt', 'itemTax', 'shapeAmt', 'heightAmt', 'addAmt'];
	let amt   = 0;

	switch(type)
	{
		case 'firstDis': /** 계약 시 할인 적용할 총 금액 */
			amt = data.filter(item => arr.includes(item.name)).reduce((total, item) => total + item.amt, 0);
		break;
		case 'cutDis': /** 천원 단위 절삭 할인 시 */
			arr.push('dcAmt');
			amt = data.filter(item => arr.includes(item.name)).reduce((total, item) => total + item.amt, 0);
		break;
		case 'lastDis': /** 최종 결제 시 할인 적용할 총 금액 */
			arr.push('dcAmt', 'addAmt', 'cutAmt', 'conAmt', 'lastAddAmt');
			amt = data.filter(item => arr.includes(item.name)).reduce((total, item) => total + item.amt, 0);
		break;
		case 'total': /** 모든 총 금액 */
			amt = data.reduce((total, item) => total + item.amt, 0);
		break;
	}

	return amt;
}

/**
 * @description axios 통신
 */
export async function getAxiosData(url, params)
{
    // const token = store.state.login.loginInfo.token;
    
    const instance = axios.create({
        withCredentials : true,
        // headers         : {
        //     'Authorization' : 'Bearer ' + token
        // }
    });

    const res = await instance.post(url, JSON.stringify(params));

    return res.data;
}

/**
 * @description 다음 주소 API 팝업 위치 설정
 */
export function getDaumPopupPosition(layer)
{
    layer.style.display = 'block';

    // iframe을 넣은 element의 위치를 화면의 가운데로 이동시킨다.
    var width 		= 300; //우편번호서비스가 들어갈 element의 width
    var height 		= 400; //우편번호서비스가 들어갈 element의 height
    var borderWidth = 5; //샘플에서 사용하는 border의 두께

    // 위에서 선언한 값들을 실제 element에 넣는다.
    layer.style.width 	= width + 'px';
    layer.style.height 	= height + 'px';
    layer.style.border 	= borderWidth + 'px solid';

    // 실행되는 순간의 화면 너비와 높이 값을 가져와서 중앙에 뜰 수 있도록 위치를 계산한다.
    layer.style.left 	= (((window.innerWidth || document.documentElement.clientWidth) - width)/2 - borderWidth) + 'px';
    layer.style.top 	= (((window.innerHeight || document.documentElement.clientHeight) - height)/2 - borderWidth) + 'px';
}