<template>
    <div class="container mx-auto">
        <div class="w-11/12 mx-auto">
            <div class="flex items-baseline space-y-3.5 mt-3.5 mb-5 justify-between">
                <AccInfo :saleAmt="allAcc['amt']['saleAmt']" :purcAmt="allAcc['amt']['purcAmt']" :margin="allAcc['amt']['margin']"/>
                <InlineButton buttonText="전체 기록 보기" grayButton @click="router.push({ path : '/acc/month' })"/>
            </div>
            <GrayBar class="my-2.5"/>
            <ul class="flex flex-row-reverse">
                <li class="text-sm duration-200 hover:font-bold hover:border-brand hover:text-zinc-700 py-2 border-b-2" @click="getChoice('P')"
                    :class="[{'text-zinc-700 font-bold border-brand': choice === 'P' ? true : false}, {'text-zinc-400 border-white': choice === 'P' ? false : true}]">결제 {{ allAcc['cnt']['payCnt'] }}건</li>
                <li class="text-sm duration-200 hover:font-bold hover:border-brand hover:text-zinc-700 py-2 mr-6 border-b-2" @click="getChoice('C')"
                    :class="[{'text-zinc-700 font-bold border-brand': choice === 'C' ? true : false}, {'text-zinc-400 border-white': !choice === 'C' ? false : true}]">계약 {{ allAcc['cnt']['conCnt'] }}건</li>
            </ul>
            <template v-if="choice === 'C'">
                <div v-for="(item, index) in allAcc['list']['conDtList']" :key="index">
                    <LineLeftTitle :title="getDate(item['stDt'])" />
                    <template v-for="(aItem, aIndex) in allAcc['list']['conList']" :key="aIndex">
                        <AccountList v-if="aItem['stDt'] === item['stDt']" 
                            :name="aItem['clientNm']" process="계약" :address="aItem['addr']+' '+aItem['addrDetail']"
                            :saleAmt="aItem['totalSaleAmt']" :purcAmt="aItem['totalPurcAmt']" :rev="aItem['rev']" />
                    </template>
                </div>
            </template>

            <template v-if="choice === 'P'">
                <div v-for="(item, index) in allAcc['list']['payDtList']" :key="index">
                    <LineLeftTitle :title="getDate(item['stDt'])" />
                    <template v-for="(aItem, aIndex) in allAcc['list']['payList']" :key="aIndex">
                        <AccountList v-if="aItem['stDt'] === item['stDt']" 
                            :name="aItem['clientNm']" process="결제" :address="aItem['addr']+' '+aItem['addrDetail']"
                            :saleAmt="aItem['totalSaleAmt']" :purcAmt="aItem['totalPurcAmt']" :rev="aItem['rev']" />
                    </template>
                </div>
            </template>
        </div>
    </div>
    <FooterVue />
</template>

<script setup>
import InlineButton from "@/components/button/InlineButton.vue";
import GrayBar from "@/components/common/GrayBar.vue";
import LineLeftTitle from "@/components/common/LineLeftTitle.vue";
import AccountList from "@/views/acc/AccountList.vue";
import FooterVue from "@/views/common/FooterVue.vue";
import AccInfo from "@/views/acc/AccInfo.vue";

import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { getConvertDate } from '@/assets/js/function';

const store  = useStore();
const router = useRouter();

const choice = computed(() => store.state.acc.all.choice);
const allAcc = computed(() => store.state.acc.all);

const getChoice = (val) => {
    store.commit('acc/setChoice', val);
}

const getList = () => {
    const params = {
        ceCd    : store.state.login.ceCd,
        start   : allAcc.value['start']
    }

    store.dispatch('acc/getAccAll', params);
};

const getDate = (date) => {
    return getConvertDate(new Date(date), 'mm%dd%w%2');
}

const getScroll = () => {
    const scrollTop    = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight) 
    {
        console.log("스크롤이 최하단에 도달했습니다.");
    }
}

onMounted(() => {
    getList();
    document.addEventListener('scroll', () => {
        document.addEventListener('scroll', getScroll);
    });
});
</script>

<style scoped>

</style>