<template>
    <div class="container mx-auto bg-neutral-100 space-y-3.5 pb-32">
        <img src="@/assets/images/img-banner.png" alt="배너" title="배너" class="mx-auto w-full"/>

        <WhiteBox class="mx-auto w-11/12">
            <div class="flex items-center justify-between mb-4 font-bold">
                <h3>전체고객</h3>
                <div class="flex items-center">
                    <h2 class="text-xl pr-3.5 border-r mr-3.5">{{ data['clientCnt'] }}명</h2>
                    <router-link to="/customer/list" class="rounded-2xl bg-brand w-5 h-5 flex items-center">
                        <img src="@/assets/images/btn-plus.svg" alt="고객추가" title="고객추가" class="w-2/3 mx-auto"/>
                    </router-link>
                </div>
            </div>
            <CountList :lists="data['stCnt']"/>
        </WhiteBox>

        <div class="flex mx-auto w-11/12 justify-between">
            <router-link to="/factory/list" class="w-9/10">
                <WhiteBox>
                    <h3 class="font-bold text-sm">
                        <img src="@/assets/images/img-factory.svg" alt="공장" title="공장" class="inline mr-0.5"/>
                        공장
                    </h3>
                    <p class="text-xm text-zinc-300 mt-1">공장 전체</p>
                    <div class="flex justify-between items-center">
                        <h2 class="font-bold text-xl text-sky-300">{{ data['factoryCnt'] }}</h2>
                        <IconNext class="fill-neutral-300"/>
                    </div>
                </WhiteBox>
            </router-link>
            <router-link to="" class="w-9/10">
                <WhiteBox>
                    <h3 class="font-bold text-sm">
                        <img src="@/assets/images/img-truck.svg" alt="배송 대기" title="배송 대기" class="inline mr-0.5"/>
                        배송 대기
                    </h3>
                    <p class="text-xm text-zinc-300 mt-1">배송 전체</p>
                    <div class="flex justify-between items-center">
                        <h2 class="font-bold text-xl text-yellow-300">0</h2>
                        <IconNext class="fill-neutral-300"/>
                    </div>
                </WhiteBox>
            </router-link>
        </div>

        <WhiteBox class="w-11/12 mx-auto">
            <div class="flex">
                <div class="bg-yellow-300 rounded-2xl p-2 w-8 h-8">
                    <img src="@/assets/images/icon-kakao.svg" alt="카카오톡" title="카카오톡" class="mx-auto"/>
                </div>
                <router-link to="/plantalk" class="ml-2">
                    <h3 class="font-bold text-xs">
                        플랜톡
                        <span :class="{ 'text-gray-400' : data['kakaoYn'] === 'N' ? true : false, 'text-sky-500' : data['kakaoYn'] === 'Y' ? true : false }">{{
                                data['kakaoYn'] === 'Y' ? 'ON' : 'OFF'
                            }}</span>
                    </h3>
                    <p class="text-xm text-gray-400">
                        0개 사용 중 | 잔여 포인트 50P
                    </p>
                </router-link>
            </div>


            <div class="w-full mt-3">
                <!-- OFF일 경우 -->
                <template v-if="data['kakaoYn'] === 'Y' ? false : true">
                    <p class="bg-yellow-100 rounded py-2 px-2.5 text-xm">
                        일일이 세팅하지 말고, 꼭 필요한 예약 메시지를 자동으로 고객에게 전달해 보세요!
                    </p>
                    <router-link to="/plantalk"
                                 class="mt-3.5 text-center w-full rounded-sm py-3 text-xs font-semibold text-white bg-brand block">
                        플랜톡 사용하기
                    </router-link>
                </template>

                <!-- ON일 경우 -->
                <template v-if="data['kakaoYn'] === 'Y' ? true : false">
                    <div class="flex justify-between">
                        <router-link to="" class="w-9/10 text-xm">
                            <GrayBox>
                                <h5 class="font-bold">예약된 알림</h5>
                                <div class="flex justify-between items-center">
                                    <p class="text-zinc-400">상세보기</p>
                                    <IconNext class="fill-neutral-300"/>
                                </div>
                            </GrayBox>
                        </router-link>
                        <router-link to="" class="w-9/10 text-xm">
                            <GrayBox>
                                <h5 class="font-bold">보낸 알림</h5>
                                <div class="flex justify-between items-center">
                                    <p class="text-zinc-400">
                                        오늘
                                        <span class="text-red-500">0건</span>
                                    </p>
                                    <IconNext class="fill-neutral-300"/>
                                </div>
                            </GrayBox>
                        </router-link>
                    </div>
                </template>
            </div>
        </WhiteBox>

        <WhiteBox class="w-11/12 mx-auto">
            <div class="flex justify-between items-center mb-7">
                <h2 class="font-bold text-lg">매장전용 메시지</h2>
                <router-link :to="{ path: '/msg', query: { type : 'I' } }" >
                    <InlineButton blueButton buttonText="메시지 추가"/>
                </router-link>
            </div>
            <NumberList :lists="data['msgList']"/>
        </WhiteBox>

        <WhiteBox class="w-11/12 mx-auto text-center font-bold text-xs">
            메시지 샘플 보기
        </WhiteBox>
    </div>
    <FooterVue/>
</template>

<script setup>

import WhiteBox from "@/components/box/WhiteBox.vue";
import CountList from "@/components/list/CountList.vue";
import IconNext from "@/assets/svgs/IconNext.vue";
import GrayBox from "@/components/box/GrayBox.vue";
import InlineButton from "@/components/button/InlineButton.vue";
import NumberList from "@/components/list/NumberList.vue";
import FooterVue from "@/views/common/FooterVue.vue";

import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const data  = computed(() => store.state.main);

const getData = () => {
    store.dispatch('main/getData', { ceCd : store.state.login.ceCd });
}

onMounted(() => {
    getData();
});

</script>

<style scoped>

</style>