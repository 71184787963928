<template>
    <div class="flex items-center justify-between">
        <label :for="id" class="w-1/3 text-sm text-neutral-500">{{ label }}</label>
        <input :type="type" :id="id" :name="name" :value="value" :placeholder="placeholder" class="w-2/3 pt-3 pb-2.5 pl-3.5 border rounded-sm text-xs duration-200 disabled:bg-zinc-100" :class="[{'border-zinc-200': !error, 'border-red-600': error}]" :disabled="disabled"/>
    </div>
</template>

<script>
export default {
    name : "InlineInput",
    props: {
        id         : String,
        label      : String,
        name       : String,
        value      : String,
        type       : String,
        placeholder: String,
        error      : Boolean,
        disabled   : Boolean,
    }
}
</script>

<style scoped>

</style>